import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_AUTHORITY_URL, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_REDIRECT_URL,
    interactionType: InteractionType.Popup,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "Contacts.Read", "openid", "profile", "email"],
  prompt: "select_account",
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeContactsEndpoint: process.env.NEXT_PUBLIC_MICROSOFT_CONTACTS_ENDPOINT_URL,
  totalContactsEndpoint: "https://graph.microsoft.com/v1.0/me/contacts/$count",
};

export const msalInstance = new PublicClientApplication(msalConfig);
