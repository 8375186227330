import React from "react";
import { Provider } from "react-redux";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import PropTypes from "prop-types";
import { appWithTranslation } from "next-i18next";
import { NextAdapter } from "next-query-params";
import { QueryParamProvider } from "use-query-params";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { MsalProvider } from "@azure/msal-react";
import { reactPlugin } from "../components/application_insights/application_insights";
import { initializeStore } from "../redux/store";
import { pagePropsShape } from "../utils/propTypeShapes";
import "../styles/globals.sass";
import Layout from "../components/layout/wrapper";
import { NotificationBus } from "../components/notification/notification_bus";
import { SlideOver } from "../components/slide_over/slide_over";
import { Modal } from "../components/modals/modal";
import { msalInstance } from "../scripts/azure/graph_config";

const store = initializeStore();

function App({ Component, pageProps }) {
  const { user } = pageProps;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <UserProvider user={user}>
          <Provider store={store}>
            <QueryParamProvider adapter={NextAdapter}>
              <NotificationBus>
                <Layout>
                  <Component {...pageProps} />
                  <SlideOver>
                    <Modal />
                  </SlideOver>
                </Layout>
              </NotificationBus>
            </QueryParamProvider>
          </Provider>
        </UserProvider>
      </MsalProvider>
    </AppInsightsContext.Provider>
  );
}

export default appWithTranslation(App);

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: pagePropsShape,
};
