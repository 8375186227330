import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import {
  RECEIVE_CURRENT_USER,
  RECEIVE_UPDATE_CURRENT_USER,
  REQUEST_CURRENT_USER,
  REQUEST_UPDATE_CURRENT_USER,
  REQUEST_SELECT_COMPANY,
  RECEIVE_UPDATE_CURRENT_USER_ERROR,
  REQUEST_MOBILE_VERIFICATION_CODE,
  RECEIVE_MOBILE_VERIFICATION_CODE,
  RECEIVE_MOBILE_VERIFICATION_CODE_ERROR,
  REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE,
  RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE,
  RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR,
  REQUEST_EMAIL_VERIFICATION,
  RECEIVE_EMAIL_VERIFICATION_ERROR,
  RECEIVE_EMAIL_VERIFICATION,
  RECEIVE_CURRENT_USER_ERROR,
  REQUEST_UPDATE_DARK_MODE,
  RECEIVE_UPDATE_DARK_MODE,
  RECEIVE_UPDATE_DARK_MODE_ERROR,
} from "../constants/current_user";
import * as currenUserApi from "../../api/current_user";
import { showNotification } from "./notification";
import { DARK_MODE_COOKIE_NAME, MODAL, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { requestOpenModal } from "./modal";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

const notificationPayload = {
  message: {
    title: "Saved",
    description: "",
    options: {
      variant: NOTIFICATION_VARIANTS.SUCCESS,
    },
  },
};

function requestSelectCompany(companyId) {
  return {
    type: REQUEST_SELECT_COMPANY,
    companyId,
  };
}

export function selectCompany(companyId) {
  return async function (dispatch) {
    dispatch(requestSelectCompany(companyId));
  };
}

function requestCurrentUser() {
  return {
    type: REQUEST_CURRENT_USER,
  };
}

function receiveCurrentUser(currentUser) {
  return {
    type: RECEIVE_CURRENT_USER,
    userConnected: currentUser,
  };
}

function receiveCurrentUserError(error) {
  return {
    type: RECEIVE_CURRENT_USER_ERROR,
    error,
  };
}

export function fetchCurrentUser() {
  return async function (dispatch) {
    dispatch(requestCurrentUser());
    const response = await currenUserApi.fetchCurrentUser();
    if (!response.ok) {
      dispatch(receiveCurrentUserError(response.data));
    } else {
      dispatch(receiveCurrentUser(response.data));
    }
  };
}

function requestUpdateCurrentUser() {
  return {
    type: REQUEST_UPDATE_CURRENT_USER,
  };
}

function receiveUpdateCurrentUser(userUpdated) {
  return {
    type: RECEIVE_UPDATE_CURRENT_USER,
    userConnected: userUpdated,
  };
}

function receiveUpdateCurrentUserError(error) {
  return {
    type: RECEIVE_UPDATE_CURRENT_USER_ERROR,
    error,
  };
}

export function updateCurrentUser(opts) {
  return async function (dispatch, getState) {
    dispatch(requestUpdateCurrentUser());

    const { userConnected } = getState().currentUser;
    const response = await currenUserApi.updateCurrentUser(opts);

    if (response.ok) {
      sendAnalytics(ANALYTIC_EVENTS.PROFILE_UPDATED, { ...response.data, userConnected });
      dispatch(receiveUpdateCurrentUser(response.data));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            ...notificationPayload.message,
            description: "User was updated successfully",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
            },
          },
        }),
      );
    } else {
      dispatch(receiveUpdateCurrentUserError(response.userDescription));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}

function requestUpdateDarkMode() {
  return {
    type: REQUEST_UPDATE_DARK_MODE,
  };
}

function receiveUpdateDarkMode(darkMode) {
  return {
    type: RECEIVE_UPDATE_DARK_MODE,
    darkMode,
  };
}

function receiveUpdateDarkModeError(error) {
  return {
    type: RECEIVE_UPDATE_DARK_MODE_ERROR,
    error,
  };
}

export function updateDarkMode(value) {
  return async function (dispatch, getState) {
    let payload = {};
    dispatch(requestUpdateDarkMode());

    const { userConnected } = getState().currentUser;

    if (!isEmpty(userConnected)) {
      payload = {
        ...userConnected,
        darkMode: value,
      };
    } else {
      Cookies.set(DARK_MODE_COOKIE_NAME, JSON.stringify(value), {
        secure: true,
        sameSite: "Lax",
      });
      dispatch(receiveUpdateDarkMode(value));
      return;
    }
    dispatch(receiveUpdateDarkMode(value));

    const response = await currenUserApi.updateDarkMode(payload);

    if (response.ok) {
      Cookies.set(DARK_MODE_COOKIE_NAME, JSON.stringify(value), {
        secure: true,
        sameSite: "Lax",
      });
    } else {
      dispatch(receiveUpdateDarkModeError(response.userDescription));
      dispatch(receiveUpdateDarkMode(!value));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}

function requestMobileVerificationCode() {
  return {
    type: REQUEST_MOBILE_VERIFICATION_CODE,
  };
}

function receiveMobileVerificationCode() {
  return {
    type: RECEIVE_MOBILE_VERIFICATION_CODE,
  };
}

function receiveMobileVerificationCodeError(error) {
  return {
    type: RECEIVE_MOBILE_VERIFICATION_CODE_ERROR,
    error,
  };
}

export function getMobileVerificationCode() {
  return async function (dispatch) {
    dispatch(requestMobileVerificationCode());
    const response = await currenUserApi.getVerificationCode();
    if (response.ok) {
      dispatch(receiveMobileVerificationCode());
      dispatch(requestOpenModal(MODAL.VALIDATE_VERIFICATION_CODE));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            ...notificationPayload.message,
            description: "Verification Code was sent successfully.",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
            },
          },
        }),
      );
    } else {
      dispatch(receiveMobileVerificationCodeError(response.userDescription));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}

function requestValidateMobileVerificationCode() {
  return {
    type: REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE,
  };
}

function receiveValidateMobileVerificationCode() {
  return {
    type: RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE,
  };
}

function receiveValidateMobileVerificationCodeError(error) {
  return {
    type: RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR,
    error,
  };
}

export function validateMobileVerificationCode(opts, callback) {
  return async function (dispatch) {
    dispatch(requestValidateMobileVerificationCode());
    const response = await currenUserApi.validateVerificationCode(opts);
    if (response.ok) {
      dispatch(receiveValidateMobileVerificationCode());
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            ...notificationPayload.message,
            description: "Mobile Number was verified successfully.",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
            },
          },
        }),
      );
      if (callback) callback();
    } else {
      dispatch(receiveValidateMobileVerificationCodeError(response.userDescription));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            title: "Error",
            description: "The code provided is not valid.",
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}

function requestEmailVerification() {
  return {
    type: REQUEST_EMAIL_VERIFICATION,
  };
}

function receiveEmailVerification() {
  return {
    type: RECEIVE_EMAIL_VERIFICATION,
  };
}

function receiveMEmailVerificationError(error) {
  return {
    type: RECEIVE_EMAIL_VERIFICATION_ERROR,
    error,
  };
}

export function sendEmailVerification() {
  return async function (dispatch) {
    dispatch(requestEmailVerification());
    const response = await currenUserApi.sendEmailVerification();
    if (response.ok) {
      dispatch(receiveEmailVerification());
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            ...notificationPayload.message,
            description: "Email verification was sent successfully.",
            options: {
              variant: NOTIFICATION_VARIANTS.SUCCESS,
            },
          },
        }),
      );
    } else {
      dispatch(receiveMEmailVerificationError(response.userDescription));
      dispatch(
        showNotification({
          ...notificationPayload,
          message: {
            title: "Error",
            description: response.data.userDescription,
            options: {
              variant: NOTIFICATION_VARIANTS.ERROR,
            },
          },
        }),
      );
    }
  };
}
