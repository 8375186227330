import React, { useCallback, useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { DocumentTextIcon, UsersIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import useGlobalSearch from "../../hooks/useGlobalSearch";
import { currentCompanySelector } from "../../redux/selectors/currentUser";
import { MODAL, SLIDE_OVER } from "../../utils/applicationConstants";
import { requestOpenSlideOver } from "../../redux/actions/slide_over";
import { EmptyState } from "../empty_state/empty_state";
import { Loading } from "../loading/loading";
import { requestOpenModal } from "../../redux/actions/modal";
import { FundviewBadge } from "../badge/fundview_badge";

const formatEntity = (record, index) => {
  switch (index) {
    case 0:
      return {
        id: record.loanId,
        companyId: record.companyId,
        name: record.name,
        description: `Status ${record.loanStatusName}`,
        icon: DocumentTextIcon,
        entity: SLIDE_OVER.LOAN,
      };
    case 1:
      return {
        id: record.contactId,
        companyId: record.companyId,
        name: `${record.firstName} ${record.lastName}`,
        description: record.email,
        icon: UsersIcon,
        entity: SLIDE_OVER.USER,
      };
    case 2:
      return {
        id: record.lendingCompanyId,
        companyId: record.companyId,
        name: record.name,
        description: `LTV ${record.maxLtv}%`,
        icon: BuildingOfficeIcon,
        entity: SLIDE_OVER.LENDING_COMPANY,
      };
    default:
      return {};
  }
};

export default function GlobalSearchBox() {
  const { push } = useRouter();
  const currentCompany = useSelector(currentCompanySelector);
  const dispatch = useDispatch();
  const [query, setQuery] = useState(null);
  const { data, isLoading, isError } = useGlobalSearch(query, currentCompany);

  const debouncedSearch = React.useMemo(
    () =>
      debounce(val => {
        setQuery(val);
      }, 300),
    [setQuery],
  );

  const handleInputChange = useCallback(
    event => {
      debouncedSearch(event.target.value);
    },
    [debouncedSearch],
  );

  const handleChange = useCallback(
    item => {
      switch (item.entity) {
        case SLIDE_OVER.LOAN: {
          push(`/loans/${item.id}/detail`);
          break;
        }
        case SLIDE_OVER.USER: {
          if (item.companyId) {
            dispatch(requestOpenSlideOver(SLIDE_OVER.USER, { contactId: item.id }));
          } else {
            dispatch(requestOpenModal(MODAL.USER_OVERVIEW, { contactId: item.id }));
          }
          break;
        }
        case SLIDE_OVER.LENDING_COMPANY: {
          if (item.companyId) {
            dispatch(requestOpenSlideOver(SLIDE_OVER.LENDING_COMPANY, { lendingCompanyId: item.id }));
          } else {
            dispatch(requestOpenModal(MODAL.LENDING_COMPANY_OVERVIEW, { lendingCompanyId: item.id }));
          }
          break;
        }
        default:
      }
    },
    [dispatch, push],
  );

  const records = useMemo(() => {
    const entities = [];
    if (!data) return entities;
    data.forEach((entity, index) => {
      if (!entity?.items) return;
      entities.push(...entity.items.map(item => formatEntity(item, index)));
    });

    return entities;
  }, [data]);

  return (
    <Combobox
      as="div"
      className="relative flex basis-9/12 bg-white dark:bg-transparent"
      value={query}
      onChange={handleChange}
    >
      <div className="w-full">
        {isLoading ? (
          <div className="pointer-events-none absolute top-3.5 left-4 h-5 w-5">
            <Loading isFullHeight={false} spinnerSize={4} pathSize={2} padding={0} />
          </div>
        ) : (
          <MagnifyingGlassIcon
            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400 dark:text-slate-400"
            aria-hidden="true"
          />
        )}
        <Combobox.Input
          className="h-12 w-full border-0 pl-11 pr-4 bg-inherit text-gray-800 placeholder-gray-400 dark:text-slate-400 dark:placeholder-slate-400 focus:ring-0 sm:text-sm"
          placeholder="Search loans, contacts, lending companies..."
          onChange={handleInputChange}
        />
      </div>

      <Combobox.Options className="bg-white dark:bg-slate-800 absolute mt-16 z-10 overflow-auto origin-bottom max-h-96 w-full text-sm shadow-md">
        {records &&
          records.length > 0 &&
          records.map(item => (
            <Combobox.Option
              key={item.id}
              value={item}
              className={({ active }) =>
                classNames("flex cursor-default select-none p-3", active && "bg-gray-100 dark:bg-slate-700")
              }
            >
              {({ active }) => (
                <>
                  <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-600">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div className="ml-4 flex-auto truncate">
                    <p
                      className={classNames(
                        "text-sm font-medium truncate flex items-center",
                        active ? "text-gray-900 dark:text-slate-100" : "text-gray-700 dark:text-slate-300",
                      )}
                    >
                      <span className="pr-1">{item.name}</span>
                      {!item.companyId && <FundviewBadge title="Fundview Preferred Lender" />}
                    </p>
                    <p
                      className={classNames(
                        "text-sm truncate",
                        active ? "text-gray-700 dark:text-slate-300" : "text-gray-500 dark:text-slate-400",
                      )}
                    >
                      {item.description}
                    </p>
                  </div>
                </>
              )}
            </Combobox.Option>
          ))}
        {!isLoading && !isError && !records.length && (
          <Combobox.Option key="empty" value="null">
            <div className="bg-white dark:bg-slate-800 absolute max-h-96 w-full py-14 px-6 text-center text-sm sm:px-14">
              <EmptyState title="No results found" subtitle={`We could not found any matches for "${query}"`} />
            </div>
          </Combobox.Option>
        )}
      </Combobox.Options>
    </Combobox>
  );
}
