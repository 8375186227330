import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector, useStore } from "react-redux";
import classNames from "classnames";
import { useRouter } from "next/router";
import isEmpty from "lodash/isEmpty";
import { SideBar } from "../sidebar/sidebar";
import TopBar from "../topbar/topbar";
import { fetchCurrentUser } from "../../redux/actions/current_user";
import { Loading } from "../loading/loading";
import { initializeUser } from "../../utils/analytics";
import { EMPTY_GUID } from "../../utils/applicationConstants";
import { loadConfiguration } from "../../utils/loadConfiguration";

export default function Dashboard({ children }) {
  const store = useStore();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isFetching, userConnected, currentCompany } = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const { pathname } = useRouter();

  useEffect(() => {
    async function handleLoadConfiguration() {
      await loadConfiguration(store);
    }
    handleLoadConfiguration().catch(() => {
      // eslint-disable-next-line no-console
      console.error("Error loading configuration");
    });
  }, [store]);

  useEffect(() => {
    if (isEmpty(userConnected)) dispatch(fetchCurrentUser());

    if (userConnected?.employeeId !== EMPTY_GUID && !isEmpty(currentCompany))
      initializeUser(userConnected?.userId, { ...userConnected, ...currentCompany });
  }, [dispatch, userConnected, currentCompany]);

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname, setSidebarOpen]);

  if (isFetching || !userConnected) {
    return <Loading transparent={false} />;
  }

  return (
    <div className={classNames("relative h-screen flex overflow-hidden bg-gray-200 dark:bg-slate-900 border-none")}>
      <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      {/* Main column */}
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        {/* SideBar Mobile */}
        <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 p-8 relative z-0 overflow-y-auto overflow-x-hidden focus:outline-none bg-inherit">
          {children}
        </main>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  children: PropTypes.array.isRequired,
};
