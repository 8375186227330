export const REQUEST_CREATE_LENDER_CALL_LOG = 'REQUEST_CREATE_LENDER_CALL_LOG';
export const RECEIVE_CREATE_LENDER_CALL_LOG = 'RECEIVE_CREATE_LENDER_CALL_LOG';
export const RECEIVE_CREATE_LENDER_CALL_LOG_ERROR = 'RECEIVE_CREATE_LENDER_CALL_LOG_ERROR';

export const REQUEST_UPDATE_LENDER_CALL_LOG = 'REQUEST_UPDATE_LENDER_CALL_LOG';
export const RECEIVE_UPDATE_LENDER_CALL_LOG = 'RECEIVE_UPDATE_LENDER_CALL_LOG';
export const RECEIVE_UPDATE_LENDER_CALL_LOG_ERROR = 'RECEIVE_UPDATE_LENDER_CALL_LOG_ERROR';

export const REQUEST_GET_LENDER_CALL_LOG = 'REQUEST_GET_LENDER_CALL_LOG';
export const RECEIVE_GET_LENDER_CALL_LOG = 'RECEIVE_GET_LENDER_CALL_LOG';
export const RECEIVE_GET_LENDER_CALL_LOG_ERROR = 'RECEIVE_GET_LENDER_CALL_LOG_ERROR';

export const REQUEST_GET_LENDER_CALL_LOGS = 'REQUEST_GET_LENDER_CALL_LOGS';
export const RECEIVE_GET_LENDER_CALL_LOGS = 'RECEIVE_GET_LENDER_CALL_LOGS';
export const RECEIVE_GET_LENDER_CALL_LOGS_ERROR = 'RECEIVE_GET_LENDER_CALL_LOGS_ERROR';

export const REQUEST_DELETE_LENDER_CALL_LOG = 'REQUEST_DELETE_LENDER_CALL_LOG';
export const RECEIVE_DELETE_LENDER_CALL_LOG = 'RECEIVE_DELETE_LENDER_CALL_LOG';
export const RECEIVE_DELETE_LENDER_CALL_LOG_ERROR = 'RECEIVE_DELETE_LENDER_CALL_LOG_ERROR';

export const REQUEST_REFRESH_LENDER_CALL_LOGS = 'REQUEST_REFRESH_LENDER_CALL_LOGS';
export const RECEIVE_REFRESH_LENDER_CALL_LOGS = 'RECEIVE_REFRESH_LENDER_CALL_LOGS';
export const RECEIVE_REFRESH_LENDER_CALL_LOGS_ERROR = 'RECEIVE_REFRESH_LENDER_CALL_LOGS_ERROR';

export const REQUEST_CLEAR_LENDER_CALL_LOG = 'REQUEST_CLEAR_LENDER_CALL_LOG';
export const REQUEST_CLEAR_LENDER_CALL_LOGS = 'REQUEST_CLEAR_LENDER_CALL_LOGS';
