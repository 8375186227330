import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const lendingCompanyLoanProgramService = createApi({
  reducerPath: "lendingCompanyProgramTypeApi",
  tagTypes: ["LENDING_COMPANY_LOAN_PROGRAM"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLendingCompanyLoanPrograms: builder.query({
      query: ({ lendingCompanyId, ...params }) =>
        `lending-companies/${lendingCompanyId}/loan-programs?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ loanProgramId }) => ({ type: "LENDING_COMPANY_LOAN_PROGRAM", loanProgramId })),
              { type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" },
            ]
          : [{ type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" }],
    }),
    addLendingCompanyLoanProgram: builder.mutation({
      query: ({ lendingCompanyId, loanProgramId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-programs/${loanProgramId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_PROGRAM_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.loanProgramName} has been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    addLendingCompanyLoanPrograms: builder.mutation({
      query: ({ lendingCompanyId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-programs`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_PROGRAMS_CREATED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `All loan programs have been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyLoanProgram: builder.mutation({
      query: ({ lendingCompanyId, loanProgramId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-programs/${loanProgramId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_PROGRAM_DELETED, { ...data });
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.loanProgramName} has been removed from the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyLoanPrograms: builder.mutation({
      query: ({ lendingCompanyId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-programs`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_PROGRAM", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_PROGRAMS_DELETED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All loan programs have been removed from the lending company!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLendingCompanyLoanProgramsQuery,
  useAddLendingCompanyLoanProgramMutation,
  useAddLendingCompanyLoanProgramsMutation,
  useDeleteLendingCompanyLoanProgramMutation,
  useDeleteLendingCompanyLoanProgramsMutation,
} = lendingCompanyLoanProgramService;
