import { RECEIVE_GET_GUARANTEES } from '../constants/parameters';

const defaultState = {
  guarantees: [],
  isFetching: false,
};

export const guarantees = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_GET_GUARANTEES:
      return {
        ...state,
        guarantees: action.guarantees,
        isFetching: false,
      };
    default:
      return state;
  }
};
