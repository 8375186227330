import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { closeNotification, removeNotification } from '../redux/actions/notification';

let displayed = [];

const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notifications || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useDeepCompareEffect(() => {
    notifications.forEach(({
      key,
      message,
      options = {},
      dismissed = false,
    }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        autoHideDuration: message.options.autoHideDuration || options.autoHideDuration,
        persist: message.options.persist || options.persist,
        onClose: (event, reason, myKey) => {
          dispatch(closeNotification(myKey));
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
