import { useMemo } from "react";
import { useGetLoansQuery } from "../redux/services/loanService";
import { useGetLendingCompaniesQuery } from "../redux/services/lendingCompanyService";
import { useGetContactsQuery } from "../redux/services/contactService";

const useGlobalSearch = term => {
  const { data: loans, isLoading: isLoadingLoans } = useGetLoansQuery(
    { searchTerm: term, showActive: true },
    { skip: !term },
  );
  const { data: contacts, isLoading: isLoadingContacts } = useGetContactsQuery({ searchTerm: term }, { skip: !term });
  const { data: lendingCompanies, isLoading: isLoadingLendingCompanies } = useGetLendingCompaniesQuery(
    { searchTerm: term },
    { skip: !term },
  );

  const isLoading = isLoadingLoans || isLoadingLendingCompanies || isLoadingContacts;

  const entities = useMemo(() => {
    const result = [];

    if (isLoading) return result;

    result.push(loans);
    result.push(contacts);
    result.push(lendingCompanies);

    return result;
  }, [contacts, isLoading, lendingCompanies, loans]);

  return {
    data: entities,
    isLoading,
  };
};

export default useGlobalSearch;
