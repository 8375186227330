import {
  REQUEST_GET_TIME_ZONES,
  RECEIVE_GET_TIME_ZONES,
  RECEIVE_GET_TIME_ZONES_ERROR,
  REQUEST_SET_TIME_ZONES,
} from '../constants/parameters';

const defaultState = {
  timeZones: [],
  country: { },
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
};

export const timeZones = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_TIME_ZONES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_TIME_ZONES:
      return {
        ...state,
        timeZones: action.timeZones,
        isFetching: false,
      };
    case REQUEST_SET_TIME_ZONES:
      return {
        ...state,
        timeZones: action.timeZones,
      };
    case RECEIVE_GET_TIME_ZONES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
