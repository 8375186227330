import React, { useCallback } from 'react';
import { Switch } from '@headlessui/react';
import classNames from 'classnames';
import { LightBulbIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { darkModeSelector } from '../../redux/selectors/currentUser';
import { updateDarkMode } from '../../redux/actions/current_user';

function DarkModeSwitch() {
  const darkMode = useSelector(darkModeSelector);
  const dispatch = useDispatch();

  const handleChange = useCallback(() => {
    dispatch(updateDarkMode(!darkMode));
  }, [dispatch, darkMode]);

  return (
    <div className="flex items-center justify-center text-inherit dark:text-slate-100">
      {darkMode}
      <Switch
        checked={darkMode}
        onChange={handleChange}
        className={classNames(
          darkMode ? 'bg-indigo-600' : 'bg-gray-200',
          'relative items-center inline-flex flex-shrink-0 h-5 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors',
          'ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        )}
      >
        <span className="sr-only">Dark Mode</span>
        <span
          aria-hidden="true"
          className={classNames(
            darkMode ? 'translate-x-5' : '-translate-x-1',
            'flex items-center justify-center text-indigo-500 pointer-events-none inline-block h-7 w-7 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        >
          {darkMode ? <MoonIcon className="flex w-5 h-5 z-50" /> : <LightBulbIcon className="flex w-5 h-5" />}
        </span>
      </Switch>
    </div>
  );
}

export default DarkModeSwitch;
