import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import {
  REQUEST_GET_COMPANIES,
  RECEIVE_GET_COMPANIES_ERROR,
  RECEIVE_GET_COMPANIES,
  REQUEST_CREATE_COMPANY,
  RECEIVE_CREATE_COMPANY,
  RECEIVE_CREATE_COMPANY_ERROR,
  RECEIVE_GET_COMPANY_ERROR,
  REQUEST_GET_COMPANY,
  RECEIVE_GET_COMPANY,
  REQUEST_CLEAR_COMPANY,
  REQUEST_UPDATE_COMPANY,
  RECEIVE_UPDATE_COMPANY,
  RECEIVE_UPDATE_COMPANY_ERROR,
  REQUEST_SET_DEFAULT_COMPANY,
  RECEIVE_SET_DEFAULT_COMPANY,
  RECEIVE_SET_DEFAULT_COMPANY_ERROR,
} from '../constants/companies';
import { SORT_OPTIONS } from '../../utils/applicationConstants';

export const defaultCompaniesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  companies: [],
  company: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const companies = (state = defaultState, action) => {
  let index;
  let newCompanies;
  let newTotalItems;
  switch (action.type) {
    case REQUEST_CREATE_COMPANY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_COMPANY:
      newTotalItems = state.totalItems + 1;
      return {
        ...state,
        companies:
          state.companies.length < defaultCompaniesFilters.size
            ? concat(state.companies, action.company)
            : state.companies,
        totalItems: newTotalItems,
        totalPages: Math.ceil(newTotalItems / defaultCompaniesFilters.size),
        isSaving: false,
      };
    case REQUEST_UPDATE_COMPANY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_COMPANY:
      index = findIndex(state.companies, ['companyId', action.company.companyId]);
      if (index >= 0) {
        newCompanies = fill([...state.companies], action.company, index, index + 1);
      }
      return {
        ...state,
        company: action.company,
        companies: newCompanies || state.companies,
        isSaving: false,
      };
    case REQUEST_SET_DEFAULT_COMPANY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_SET_DEFAULT_COMPANY:
      index = findIndex(
        [...state.companies.map((company) => ({
          ...company,
          isDefault: false,
        }))],
        ['companyId', action.company.companyId],
      );
      if (index >= 0) {
        newCompanies = fill(state.companies, action.company, index, index + 1);
      }
      return {
        ...state,
        companies: index ? newCompanies : state.companies,
        isSaving: false,
      };
    case REQUEST_GET_COMPANIES:
      return {
        ...state,
        companies: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_COMPANIES:
      return {
        ...state,
        companies: action.companies.items,
        totalPages: action.companies.totalPages,
        totalItems: action.companies.totalItems,
        hasNextPage: action.companies.hasNextPage,
        hasPreviousPage: action.companies.hasPreviousPage,
        pageNumber: action.companies.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_COMPANY:
      return {
        ...state,
        company: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_COMPANY:
      return {
        ...state,
        company: action.company,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_COMPANY:
      return {
        ...state,
        company: {},
      };
    case RECEIVE_UPDATE_COMPANY_ERROR:
    case RECEIVE_GET_COMPANY_ERROR:
    case RECEIVE_CREATE_COMPANY_ERROR:
    case RECEIVE_GET_COMPANIES_ERROR:
    case RECEIVE_SET_DEFAULT_COMPANY_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
