import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import useNotifier from '../../hooks/useNotifier';
import Dashboard from './dashboard';
import { BLANK_LAYOUT_PAGES, PLAIN_LAYOUT_PAGE } from '../../utils/applicationConstants';
import { darkModeSelector } from '../../redux/selectors/currentUser';

export default function LayoutWrapper({ children }) {
  useNotifier();
  const route = useRouter();
  const darkMode = useSelector(darkModeSelector);

  useEffect(() => {
    const systemDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (isNil(darkMode) && systemDarkMode) {
      document.documentElement.classList.add('dark');
      return;
    }

    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  if (route.pathname.match(PLAIN_LAYOUT_PAGE)) {
    return (
      <div>
        <main className="flex-1 relative z-0 overflow-y-auto overflow-x-hidden focus:outline-none bg-inherit">
          {children}
        </main>
      </div>
    );
  }

  if (route.pathname.match(BLANK_LAYOUT_PAGES)) {
    return (
      <div className="bg-gray-100 dark:bg-slate-900 h-screen">
        <main className="flex-1 relative z-0 overflow-y-auto overflow-x-hidden focus:outline-none bg-inherit">
          {children}
        </main>
      </div>
    );
  }

  return (
    <Dashboard>
      {children}
    </Dashboard>
  );
}

LayoutWrapper.propTypes = {
  children: PropTypes.array.isRequired,
};
