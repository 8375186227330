import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './styles.module.sass';

export function UserDropDownMenuItems() {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames(
          styles.popoverIndex,
          'origin-top-right absolute right-0 mt-14 sm:mt-16 w-32 rounded-md shadow-lg bg-white dark:bg-slate-800 ring-2 ring-indigo-500',
          'ring-opacity-5 divide-y divide-gray-200 dark:divide-slate-700 focus:outline-none dark:text-slate-100',
        )}
      >
        <div>
          <Menu.Item as="div">
            <Link
              href="/account/profile"
              className="block rounded-t-md px-4 py-2 text-sm hover:bg-indigo-600 hover:text-white">
              
                Account
              
            </Link>
          </Menu.Item>
        </div>
        <div>
          <Menu.Item as="div">
            <Link
              href="/api/auth/logout"
              className="block rounded-b-md px-4 py-2 hover:bg-red-600 hover:text-white text-sm cursor-pointer">
              
                Logout
              
            </Link>
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  );
}
