import React from "react";
import classNames from "classnames";
import Image from "next/image";
import PropTypes from "prop-types";
import logo from "../../../public/fv_logo.png";
import logoWhite from "../../../public/fv_logo_white.png";

export function Logo({ className = null, colored = false, clickable = true }) {
  return (
    <Image
      className={classNames(
        className,
        { "w-auto": !className },
        { "cursor-pointer": clickable },
        { "cursor-default": !clickable },
      )}
      onClick={
        clickable
          ? () => {
              window.location = "/";
              return true;
            }
          : undefined
      }
      width="375"
      height="auto"
      priority
      quality={100}
      src={colored ? logo : logoWhite}
      alt="Fundview Logo"
    />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  colored: PropTypes.bool,
  clickable: PropTypes.bool,
};
