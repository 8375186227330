import {
  RECEIVE_CREATE_DEMO_ACCESS_ERROR,
  RECEIVE_CREATE_DEMO_ACCESS,
  REQUEST_CREATE_DEMO_ACCESS,
} from '../constants/contact_form';

const defaultState = {
  isProcessing: false,
  error: null,
};

export const contactForm = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_CREATE_DEMO_ACCESS:
      return {
        ...state,
        isProcessing: true,
        error: null,
      };
    case RECEIVE_CREATE_DEMO_ACCESS:
      return {
        ...state,
        isProcessing: false,
        error: null,
      };
    case RECEIVE_CREATE_DEMO_ACCESS_ERROR:
      return {
        ...state,
        error: action.error,
        isProcessing: false,
      };
    default:
      return state;
  }
};
