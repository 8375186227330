import {
  REQUEST_OPEN_SLIDE_OVER,
  REQUEST_CLOSE_SLIDE_OVER,
} from '../constants/slide_over';

export function requestOpenSlideOver(fragment, options) {
  return {
    type: REQUEST_OPEN_SLIDE_OVER,
    fragment,
    options,
  };
}

export function requestCloseSlideOver() {
  return {
    type: REQUEST_CLOSE_SLIDE_OVER,
  };
}
