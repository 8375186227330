import {
  REQUEST_GET_LOAN_PROGRAMS,
  RECEIVE_GET_LOAN_PROGRAMS,
  RECEIVE_GET_LOAN_PROGRAMS_ERROR,
} from '../constants/parameters';

const defaultState = {
  loanPrograms: [],
  error: null,
  isFetching: false,
};

export const loanPrograms = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_LOAN_PROGRAMS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_LOAN_PROGRAMS:
      return {
        ...state,
        loanPrograms: action.loanPrograms,
        isFetching: false,
      };
    case RECEIVE_GET_LOAN_PROGRAMS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
