export const REQUEST_GET_LOAN_COLLABORATORS = 'REQUEST_GET_LOAN_COLLABORATORS';
export const RECEIVE_GET_LOAN_COLLABORATORS = 'RECEIVE_GET_LOAN_COLLABORATORS';
export const RECEIVE_GET_LOAN_COLLABORATORS_ERROR = 'RECEIVE_GET_LOAN_COLLABORATORS_ERROR';

export const REQUEST_CREATE_LOAN_COLLABORATOR = 'REQUEST_CREATE_LOAN_COLLABORATOR';
export const RECEIVE_CREATE_LOAN_COLLABORATOR = 'RECEIVE_CREATE_LOAN_COLLABORATOR';
export const RECEIVE_CREATE_LOAN_COLLABORATORS_ERROR = 'RECEIVE_CREATE_LOAN_COLLABORATORS_ERROR';

export const REQUEST_DELETE_LOAN_COLLABORATOR = 'REQUEST_DELETE_LOAN_COLLABORATOR';
export const RECEIVE_DELETE_LOAN_COLLABORATOR = 'RECEIVE_DELETE_LOAN_COLLABORATOR';
export const RECEIVE_DELETE_LOAN_COLLABORATORS_ERROR = 'RECEIVE_DELETE_LOAN_COLLABORATORS_ERROR';

export const REQUEST_CLEAR_LOAN_COLLABORATORS = 'REQUEST_CLEAR_LOAN_COLLABORATORS';
