import { fetchCountries, fetchCurrencies, fetchLoanPrograms, fetchTimeZones } from "../api/parameters";
import {
  RECEIVE_GET_COUNTRIES,
  RECEIVE_GET_CURRENCIES,
  RECEIVE_GET_GUARANTEES,
  RECEIVE_GET_LOAN_PROGRAMS,
  RECEIVE_GET_TIME_ZONES,
} from "../redux/constants/parameters";
import { RECEIVE_GET_LENDER_CALL_LOG_STATUSES } from "../redux/constants/lender_call_log_statuses";
import { fetchLenderCallLogStatuses } from "../api/lender_call_log_statuses";
import { fetchLoanTypes } from "../api/loan_types";
import { fetchLoanStatuses } from "../api/loan_statuses";
import { fetchPropertyTypes } from "../api/property_types";
import { RECEIVE_GET_LOAN_STATUSES } from "../redux/constants/loan_statuses";
import { RECEIVE_GET_LOAN_TYPES } from "../redux/constants/loan_types";
import { fetchPropertyUses } from "../api/property_uses";
import { fetchValuesBasedOn } from "../api/values_based_on";
import { RECEIVE_GET_PROPERTY_TYPES } from "../redux/constants/property_types";
import { RECEIVE_GET_PROPERTY_USES } from "../redux/constants/property_uses";
import { RECEIVE_GET_VALUES_BASED_ON } from "../redux/constants/values_based_on";
import { defaultLoanTypesFilters } from "../redux/reducers/loan_types";
import { defaultLoanStatusesFilters } from "../redux/reducers/loan_statuses";
import { defaultPropertyTypesFilters } from "../redux/reducers/property_types";
import { defaultPropertyUsesFilters } from "../redux/reducers/property_uses";
import { defaultValuesBasedOnFilters } from "../redux/reducers/values_base_on";
import { defaultLenderCallLogStatusesFilters } from "../redux/reducers/lender_call_log_statuses";
import { LENDING_PARAMETER_GUARANTEES } from "./applicationConstants";

async function loanCountries(store) {
  const response = await fetchCountries();
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_COUNTRIES, countries: response.data.items });
  }
}

async function loadCurrencies(store) {
  const response = await fetchCurrencies();
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_CURRENCIES, currencies: response.data.items });
  }
}

async function loadLoanTypes(store) {
  const response = await fetchLoanTypes(undefined, defaultLoanTypesFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_LOAN_TYPES, loanTypes: response.data.items });
  }
}

async function loadLoanStatuses(store) {
  const response = await fetchLoanStatuses(undefined, defaultLoanStatusesFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_LOAN_STATUSES, loanStatuses: response.data.items });
  }
}

async function loadPropertyTypes(store) {
  const response = await fetchPropertyTypes(undefined, defaultPropertyTypesFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_PROPERTY_TYPES, propertyTypes: response.data.items });
  }
}

async function loadPropertyUses(store) {
  const response = await fetchPropertyUses(undefined, defaultPropertyUsesFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_PROPERTY_USES, propertyUses: response.data.items });
  }
}

async function loadValuesBasedOn(store) {
  const response = await fetchValuesBasedOn(undefined, defaultValuesBasedOnFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_VALUES_BASED_ON, valuesBasedOn: response.data.items });
  }
}

async function loadLenderCallLogStatuses(store) {
  const response = await fetchLenderCallLogStatuses(undefined, defaultLenderCallLogStatusesFilters);
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_LENDER_CALL_LOG_STATUSES, lenderCallLogStatuses: response.data.items });
  }
}

async function loadTimeZones(store) {
  const response = await fetchTimeZones();
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_TIME_ZONES, timeZones: response.data.items });
  }
}

async function loadLoanPrograms(store) {
  const response = await fetchLoanPrograms();
  if (response.ok) {
    store.dispatch({ type: RECEIVE_GET_LOAN_PROGRAMS, loanPrograms: response.data.items });
  }
}

function loadGuarantees(store) {
  return new Promise(resolve => {
    const guarantees = Object.keys(LENDING_PARAMETER_GUARANTEES).map(item => ({
      value: item,
      label: LENDING_PARAMETER_GUARANTEES[item],
    }));
    store.dispatch({ type: RECEIVE_GET_GUARANTEES, guarantees });
    resolve();
  });
}

// Create function that receives the store and executes the promises
export const loadConfiguration = store =>
  Promise.all([
    loanCountries(store),
    loadCurrencies(store),
    loadTimeZones(store),
    loadLoanPrograms(store),
    loadLoanTypes(store),
    loadLoanStatuses(store),
    loadPropertyTypes(store),
    loadPropertyUses(store),
    loadValuesBasedOn(store),
    loadLenderCallLogStatuses(store),
    loadGuarantees(store),
  ]);
