import { omit } from "lodash";
import {
  MICROSOFT_CLARITY_EVENT_IDENTIFY,
  MICROSOFT_CLARITY_EVENT_SET_METADATA,
  MICROSOFT_CLARITY_METADATA_PROPERTY_COMPANY_ID,
  MICROSOFT_CLARITY_METADATA_PROPERTY_COMPANY_NAME,
  MICROSOFT_CLARITY_METADATA_PROPERTY_EMAIL,
  MICROSOFT_CLARITY_METADATA_PROPERTY_NAME,
  MICROSOFT_CLARITY_METADATA_PROPERTY_USER_ID,
} from "./clarity_events";
import { ROLES_LEVEL } from "./applicationConstants";

export const ANALYTIC_EVENTS = {
  FAQ_CLICKED: "faq_clicked",
  DEMO_REQUESTED: "demo_requested",
  PRICING_CLICKED: "pricing_clicked",
  PROFILE_UPDATED: "profile_updated",
  DOCUMENT_CREATED: "document_created",
  DOCUMENT_UPDATED: "document_updated",
  DOCUMENT_DELETED: "document_deleted",
  COMPANY_CREATED: "company_created",
  COMPANY_UPDATED: "company_updated",
  COMPANY_TIMEZONE_UPDATED: "company_timezone_updated",
  LOAN_CREATED: "loan_created",
  LOAN_UPDATED: "loan_updated",
  LOAN_DELETED: "loan_deleted",
  LOAN_PROPERTY_DETAIL_UPDATED: "loan_property_detail_updated",
  LOAN_BORROWER_ADDED: "loan_borrower_added",
  LOAN_BORROWER_DELETED: "loan_borrower_deleted",
  LOAN_REFERRER_ADDED: "loan_referrer_added",
  LOAN_REFERRER_DELETED: "loan_referrer_deleted",
  LOAN_STATUS_CHANGED: "loan_status_changed",
  LOAN_ESTIMATED_CLOSING_DATE_CHANGED: "loan_estimated_closing_date_changed",
  LOAN_NOTE_CREATED: "loan_note_created",
  LOAN_NOTE_UPDATED: "loan_note_updated",
  LOAN_NOTE_DELETED: "loan_note_deleted",
  LOAN_EMPLOYEE_CREATED: "loan_employee_created",
  LOAN_EMPLOYEE_DELETED: "loan_employee_deleted",
  LOAN_PACKAGE_DOWNLOADED: "loan_package_downloaded",
  NEEDS_LIST_BORROWER_REMINDER_SENT: "needs_list_borrower_reminder_sent",
  NEEDS_LIST_DOCUMENT_CREATED: "needs_list_document_created",
  NEEDS_LIST_DOCUMENT_UPDATED: "needs_list_document_updated",
  NEEDS_LIST_DOCUMENT_DELETED: "needs_list_document_deleted",
  NEEDS_LIST_DOCUMENT_FILE_CREATED: "needs_list_document_file_created",
  NEEDS_LIST_DOCUMENT_FILE_CREATED_BY_BORROWER: "needs_list_document_file_created_by_borrower",
  NEEDS_LIST_DOCUMENT_FILE_APPROVED: "needs_list_document_file_approved",
  NEEDS_LIST_DOCUMENT_FILE_REJECTED: "needs_list_document_file_rejected",
  NEEDS_LIST_DOCUMENT_FILE_PREVIEW: "needs_list_document_file_preview",
  NEEDS_LIST_DOCUMENT_FILE_DOWNLOAD: "needs_list_document_file_download",
  NEEDS_LIST_DOCUMENT_FILE_DELETED: "needs_list_document_file_deleted",
  NEEDS_LIST_DOCUMENT_FILE_DELETED_BY_BORROWER: "needs_list_document_file_deleted_by_borrower",
  LOAN_LENDER_CALL_LOG_ITEM_CREATED: "loan_lender_call_log_item_created",
  LOAN_LENDER_CALL_LOG_ITEM_UPDATED: "loan_lender_call_log_item_updated",
  LOAN_LENDER_CALL_LOG_ITEM_DELETED: "loan_lender_call_log_item_deleted",
  LOAN_SUMMARY_CREATED: "loan_summary_created",
  LOAN_SUMMARY_UPDATED: "loan_summary_updated",
  LOAN_SUMMARY_DELETED: "loan_summary_deleted",
  LENDER_LOAN_SUMMARY_CREATED: "lender_loan_summary_created",
  LENDER_LOAN_SUMMARY_DELETED: "lender_loan_summary_deleted",
  LENDER_LOAN_SUMMARY_RESPONDED: "lender_loan_summary_responded",
  LENDER_LOAN_SUMMARY_PACKAGE_DOWNLOADED: "lender_loan_summary_package_downloaded",
  LOAN_SUMMARY_NEEDS_LIST_FILE_CREATED: "loan_summary_needs_list_file_created",
  LOAN_SUMMARY_NEEDS_LIST_FILE_DELETED: "loan_summary_needs_list_file_deleted",
  LOAN_CALL_LOG_SMART_SORT: "loan_call_log_smart_sort",
  TEAM_MEMBER_CREATED: "team_member_created",
  TEAM_MEMBER_UPDATED: "team_member_updated",
  TEAM_MEMBER_DELETED: "team_member_deleted",
  TEAM_MEMBER_INVITED: "team_member_invited",
  CONTACT_CREATED: "contact_created",
  CONTACT_UPDATED: "contact_updated",
  CONTACT_DELETED: "contact_deleted",
  CONTACTS_IMPORTED: "contacts_imported",
  CONTACTS_REVIEWED: "contacts_reviewed",
  CONTACTS_DELETED: "contacts_deleted",
  LENDING_COMPANY_CREATED: "lending_company_created",
  LENDING_COMPANY_UPDATED: "lending_company_updated",
  LENDING_COMPANY_DELETED: "lending_company_deleted",
  LENDING_COMPANY_STATE_CREATED: "lending_company_state_created",
  LENDING_COMPANY_STATES_CREATED: "lending_company_states_created",
  LENDING_COMPANY_STATE_DELETED: "lending_company_state_deleted",
  LENDING_COMPANY_STATES_DELETED: "lending_company_states_deleted",
  LENDING_COMPANY_LOAN_TYPE_CREATED: "lending_company_loan_type_created",
  LENDING_COMPANY_LOAN_TYPES_CREATED: "lending_company_loan_types_created",
  LENDING_COMPANY_LOAN_TYPE_DELETED: "lending_company_loan_type_deleted",
  LENDING_COMPANY_LOAN_TYPES_DELETED: "lending_company_loan_types_deleted",
  LENDING_COMPANY_PROPERTY_TYPE_CREATED: "lending_company_property_type_created",
  LENDING_COMPANY_PROPERTY_TYPES_CREATED: "lending_company_property_types_created",
  LENDING_COMPANY_PROPERTY_TYPE_DELETED: "lending_company_property_type_deleted",
  LENDING_COMPANY_PROPERTY_TYPES_DELETED: "lending_company_property_types_deleted",
  LENDING_COMPANY_LOAN_PROGRAM_CREATED: "lending_company_loan_program_created",
  LENDING_COMPANY_LOAN_PROGRAMS_CREATED: "lending_company_loan_programs_created",
  LENDING_COMPANY_LOAN_PROGRAM_DELETED: "lending_company_loan_program_deleted",
  LENDING_COMPANY_LOAN_PROGRAMS_DELETED: "lending_company_loan_programs_deleted",
  NEEDS_LIST_TEMPLATE_CLONED: "needs_list_template_cloned",
  NEEDS_LIST_TEMPLATE_CREATED: "needs_list_template_created",
  NEEDS_LIST_TEMPLATE_UPDATED: "needs_list_template_updated",
  NEEDS_LIST_TEMPLATE_DELETED: "needs_list_template_deleted",
  BORROWER_NEEDS_LIST_TEMPLATE_CREATED: "borrower_needs_list_template_created",
  NEEDS_LIST_TEMPLATE_DOCUMENT_CREATED: "needs_list_template_document_created",
  NEEDS_LIST_TEMPLATE_DOCUMENTS_CREATED: "needs_list_template_documents_created",
  NEEDS_LIST_TEMPLATE_DOCUMENT_DELETED: "needs_list_template_document_deleted",
  NEEDS_LIST_TEMPLATE_DOCUMENTS_DELETED: "needs_list_template_documents_deleted",
  BUTTON_CLICKED: "button_clicked",
  LENDER_CALL_LOG_NOTE_CREATED: "lender_call_log_note_created",
  LENDER_CALL_LOG_NOTE_UPDATED: "lender_call_log_note_updated",
  LENDER_CALL_LOG_NOTE_DELETED: "lender_call_log_note_deleted",
  LENDER_CALL_LOG_NOTE_CREATED_BY_LENDER: "lender_call_log_note_created_by_lender",
  LENDER_CALL_LOG_NOTE_UPDATED_BY_LENDER: "lender_call_log_note_updated_by_lender",
  LENDER_CALL_LOG_NOTE_DELETED_BY_LENDER: "lender_call_log_note_deleted_by_lender",
  COMPANY_NOTIFICATION_SCHEDULE__UPDATED: "company_notification_updated",
  LENDERS_IMPORT_WIZARD: "lenders_import_wizard",
  LENDER_ATTRIBUTE_CREATED: "lender_attribute_created",
  LENDER_ATTRIBUTE_UPDATED: "lender_attribute_updated",
  LENDER_CRITERIA_CREATED: "lender_criteria_created",
  LENDER_CRITERIA_UPDATED: "lender_criteria_updated",
  LENDER_CRITERIA_DELETED: "lender_criteria_deleted",
  LENDER_CRITERIA_PARAMETER_CREATED: "lender_criteria_parameter_created",
  LENDER_CRITERIA_PARAMETER_DELETED: "lender_criteria_parameter_deleted",
};

export function identifyUserInPendo(user) {
  if (!window.pendo) return;

  window.pendo?.initialize({
    visitor: {
      id: user.userId,
      visitorId: user.userId,
      email: user.email,
      full_name: user.fullName,
    },
    account: {
      id: user.userId,
      accountId: user.userId,
      name: user.fullName,
      company_id: user.companyId,
      company_name: user.companyName,
      is_owner: user.employeeRoleId === ROLES_LEVEL.OWN.CODE,
      is_broker: user.employeeRoleId === ROLES_LEVEL.ADM.CODE,
      is_processor: user.employeeRoleId === ROLES_LEVEL.BRO.CODE,
      is_affiliate: user.employeeRoleId === ROLES_LEVEL.AFF.CODE,
      is_borrower: user.isBorrower || false,
      is_lender: user.isLender || false,
    },
  });
}

export function identifyUserInClarity(user) {
  if (!window.clarity) return;

  window.clarity(MICROSOFT_CLARITY_EVENT_IDENTIFY, user.userId);
  window.clarity(MICROSOFT_CLARITY_EVENT_SET_METADATA, MICROSOFT_CLARITY_METADATA_PROPERTY_USER_ID, user.userId);
  window.clarity(MICROSOFT_CLARITY_EVENT_SET_METADATA, MICROSOFT_CLARITY_METADATA_PROPERTY_EMAIL, user.email);
  window.clarity(MICROSOFT_CLARITY_EVENT_SET_METADATA, MICROSOFT_CLARITY_METADATA_PROPERTY_NAME, user.fullName);
  window.clarity(MICROSOFT_CLARITY_EVENT_SET_METADATA, MICROSOFT_CLARITY_METADATA_PROPERTY_COMPANY_ID, user.companyId);
  window.clarity(
    MICROSOFT_CLARITY_EVENT_SET_METADATA,
    MICROSOFT_CLARITY_METADATA_PROPERTY_COMPANY_NAME,
    user.companyName,
  );
}

export function identifyUserInCanny(user) {
  if (!window.Canny) return;

  window.Canny("identify", {
    appID: "6543bc368fae48e2fd95c766",
    user: {
      // Replace these values with the current user's data
      email: user.email,
      name: user.fullName,
      id: user.userId,
      companies: [
        {
          id: user.companyId,
          name: user.companyName,
          created: new Date(user.companyCreatedAt).toISOString(), // optional
          // monthlySpend: company.monthlySpend, // optional
        },
      ],
      // These fields are optional, but recommended:
      avatarURL: user.picture,
      created: new Date(user.userCreatedAt).toISOString(),
    },
  });
}

function identifyUserInRudderStack(user) {
  if (!window.rudderanalytics) return;

  window.rudderanalytics.identify(user.userId, {
    // Replace these values with the current user's data
    email: user.email,
    fullName: user.fullName,
    avatarURL: user.picture,
    created: new Date(user.userCreatedAt).toISOString(),
  });
}

export function initializeUser(userId, data) {
  if (typeof window === "undefined" || !userId) return;

  const userProfile = { ...omit(data, ["company"]), ...data.company };

  window.mixpanel?.identify(userId);
  window.mixpanel?.people.set({
    ...userProfile,
    $name: userProfile.firstName,
    $email: userProfile.email,
    Email: userProfile.email,
  });

  identifyUserInPendo({
    userId: data.userId,
    email: data.email,
    fullName: `${data.firstName} ${data.lastName}`,
    companyId: data.companyId,
    companyName: data.legalName,
    employeeRoleId: data.employeeRoleId,
    isBorrower: data.isBorrower,
    isLender: data.isLender,
  });

  identifyUserInClarity({
    userId: data.userId,
    email: data.email,
    fullName: `${data.firstName} ${data.lastName}`,
    companyId: data.companyId,
    companyName: data.legalName,
  });

  identifyUserInCanny({
    userId: data.userId,
    email: data.email,
    fullName: `${data.firstName} ${data.lastName}`,
    userCreatedAt: data.createdAt,
    companyId: data.companyId,
    companyName: data.legalName,
    companyCreatedAt: data.createdAt,
    picture: data.picture,
  });

  identifyUserInRudderStack({
    userId: data.userId,
    email: data.email,
    fullName: `${data.firstName} ${data.lastName}`,
    userCreatedAt: data.createdAt,
    picture: data.picture,
  });
}

export function sendAnalytics(eventName, data = {}) {
  if (typeof window === "undefined") return;

  // Send events to Mixpanel
  window.mixpanel?.track(eventName, { ...data });

  // Send events to RudderStack
  window.rudderanalytics?.track(eventName, { ...data });
}

export function sendCollectionToAnalytics(eventName, collection) {
  if (!collection.length) return;

  collection.forEach(item => {
    sendAnalytics(eventName, { ...item });
  });
}
