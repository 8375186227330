import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const lendingCompanyLoanTypeService = createApi({
  reducerPath: "lendingCompanyLoanTypeApi",
  tagTypes: ["LENDING_COMPANY_LOAN_TYPE"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLendingCompanyLoanTypes: builder.query({
      query: ({ lendingCompanyId, ...params }) =>
        `lending-companies/${lendingCompanyId}/loan-types?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ loanTypeId }) => ({ type: "LENDING_COMPANY_LOAN_TYPE", loanTypeId })),
              { type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" },
            ]
          : [{ type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" }],
    }),
    addLendingCompanyLoanType: builder.mutation({
      query: ({ lendingCompanyId, loanTypeId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-types/${loanTypeId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_TYPE_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.loanTypeName} has been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    addLendingCompanyLoanTypes: builder.mutation({
      query: ({ lendingCompanyId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-types`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_TYPES_CREATED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `All loan types have been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyLoanType: builder.mutation({
      query: ({ lendingCompanyId, loanTypeId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-types/${loanTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_TYPE_DELETED, { ...data });
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.loanTypeName} has been removed from the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyLoanTypes: builder.mutation({
      query: ({ lendingCompanyId }) => ({
        url: `lending-companies/${lendingCompanyId}/loan-types`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_LOAN_TYPE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_LOAN_TYPES_DELETED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All loan types have been removed from the lending company!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLendingCompanyLoanTypesQuery,
  useAddLendingCompanyLoanTypeMutation,
  useAddLendingCompanyLoanTypesMutation,
  useDeleteLendingCompanyLoanTypeMutation,
  useDeleteLendingCompanyLoanTypesMutation,
} = lendingCompanyLoanTypeService;
