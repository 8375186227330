import {
  REQUEST_CLEAR_LOAN_COLLABORATORS,
  RECEIVE_GET_LOAN_COLLABORATORS_ERROR,
  RECEIVE_GET_LOAN_COLLABORATORS,
  REQUEST_GET_LOAN_COLLABORATORS,
} from '../constants/loan_collaborators';
import { SORT_OPTIONS } from '../../utils/applicationConstants';

export const defaultLoanCollaboratorsFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  loanCollaborators: [],
  isFetchingLoanCollaborators: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  isSaving: false,
  error: null,
};

export const loanCollaborators = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_LOAN_COLLABORATORS:
      return {
        ...state,
        isFetchingLoanCollaborators: true,
      };
    case RECEIVE_GET_LOAN_COLLABORATORS:
      return {
        ...state,
        isFetchingLoanCollaborators: false,
        loanCollaborators: action.loanCollaborators.items,
        totalPages: action.loanCollaborators.totalPages,
        totalItems: action.loanCollaborators.totalItems,
        hasNextPage: action.loanCollaborators.hasNextPage,
        hasPreviousPage: action.loanCollaborators.hasPreviousPage,
        pageNumber: action.loanCollaborators.pageNumber,
      };
    case REQUEST_CLEAR_LOAN_COLLABORATORS:
      return {
        ...state,
        loanCollaborators: [],
      };
    case RECEIVE_GET_LOAN_COLLABORATORS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingLoanCollaborators: false,
        isSaving: false,
      };
    default:
      return state;
  }
};
