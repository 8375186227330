export const REQUEST_GET_NOTIFICATIONS = 'REQUEST_GET_NOTIFICATIONS';
export const RECEIVE_GET_NOTIFICATIONS = 'RECEIVE_GET_NOTIFICATIONS';
export const RECEIVE_GET_NOTIFICATIONS_ERROR = 'RECEIVE_GET_NOTIFICATIONS_ERROR';

export const REQUEST_GET_NOTIFICATION_SCHEDULES = 'REQUEST_GET_NOTIFICATION_SCHEDULES';
export const RECEIVE_GET_NOTIFICATION_SCHEDULES = 'RECEIVE_GET_NOTIFICATION_SCHEDULES';
export const RECEIVE_GET_NOTIFICATION_SCHEDULES_ERROR = 'RECEIVE_GET_NOTIFICATION_SCHEDULES_ERROR';

export const REQUEST_SET_UP_NOTIFICATION_SCHEDULE = 'REQUEST_SET_UP_NOTIFICATION_SCHEDULE';
export const RECEIVE_SET_UP_NOTIFICATION_SCHEDULE = 'RECEIVE_SET_UP_NOTIFICATION_SCHEDULE';
export const RECEIVE_SET_UP_NOTIFICATION_SCHEDULE_ERROR = 'RECEIVE_SET_UP_NOTIFICATION_SCHEDULE_ERROR';

export const REQUEST_CLEAR_NOTIFICATIONS = 'REQUEST_CLEAR_NOTIFICATIONS';
export const REQUEST_CLEAR_NOTIFICATION_SCHEDULES = 'REQUEST_CLEAR_NOTIFICATION_SCHEDULES';
