import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import verifiedIconWhite from "../../../public/images/icons/verified-badge-white.svg";
import verifiedIcon from "../../../public/images/icons/verified-badge.svg";

export function FundviewBadge({ title, useNativeColor = true, className }) {
  return (
    <Image src={useNativeColor ? verifiedIcon : verifiedIconWhite} alt="Verified" title={title} className={className} />
  );
}

FundviewBadge.propTypes = {
  title: PropTypes.string.isRequired,
  useNativeColor: PropTypes.bool,
  className: PropTypes.string,
};
