import { createSelector } from "reselect";
import Cookies from "js-cookie";
import isNil from "lodash/isNil";
import { DARK_MODE_COOKIE_NAME, ROLES_LEVEL } from "../../utils/applicationConstants";

let systemDarkMode;

if (typeof window !== "undefined") {
  systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
}

const darkModeCookie = JSON.parse(Cookies.get(DARK_MODE_COOKIE_NAME) || null);

const darkMode = state => state.currentUser.darkMode;
const companies = state => state.currentUser.userConnected.companies;
const currentUser = state => state.currentUser.userConnected;
const currentCompany = state => state.currentUser.currentCompany;

export const darkModeSelector = createSelector(darkMode, field =>
  isNil(field) ? darkModeCookie || systemDarkMode : field,
);

export const currentUserSelector = createSelector(currentUser, item => item);

export const currentCompanySelector = createSelector(currentCompany, item => item?.companyId);

export const currentCompanyObjectSelector = createSelector(currentCompany, item => item);

export const userCompaniesSelector = createSelector(companies, items => items);

export const currentUserRoleSelector = createSelector(currentUser, item => {
  if (!item) return undefined;
  return item.employeeRoleId;
});

export const currentUserRoleDescriptionSelector = createSelector(currentUser, item => {
  if (!item) return undefined;
  return ROLES_LEVEL[item.employeeRoleId].DESCRIPTION;
});

export const isOwnerSelector = createSelector(currentUserRoleSelector, role => role === ROLES_LEVEL.OWN.CODE);

export const isAdministratorSelector = createSelector(currentUserRoleSelector, role => role === ROLES_LEVEL.ADM.CODE);

export const isBrokerSelector = createSelector(currentUserRoleSelector, role => role === ROLES_LEVEL.BRO.CODE);

export const isAffiliateSelector = createSelector(currentUserRoleSelector, role => role === ROLES_LEVEL.AFF.CODE);
