import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import { SORT_OPTIONS } from '../../utils/applicationConstants';
import {
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE,
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_ERROR,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_ERROR,
  RECEIVE_GET_NEEDS_LIST_TEMPLATE,
  RECEIVE_GET_NEEDS_LIST_TEMPLATE_ERROR,
  RECEIVE_GET_NEEDS_LIST_TEMPLATES,
  RECEIVE_GET_NEEDS_LIST_TEMPLATES_ERROR,
  RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE,
  RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE_ERROR,
  REQUEST_CLEAR_NEEDS_LIST_TEMPLATE,
  REQUEST_CREATE_NEEDS_LIST_TEMPLATE,
  REQUEST_DELETE_NEEDS_LIST_TEMPLATE,
  REQUEST_GET_NEEDS_LIST_TEMPLATE,
  REQUEST_GET_NEEDS_LIST_TEMPLATES,
  REQUEST_UPDATE_NEEDS_LIST_TEMPLATE,
} from '../constants/needs_list_templates';
import {
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
} from '../constants/needs_list_template_documents';

export const defaultNeedsListTemplatesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  needsListTemplates: [],
  needsListTemplate: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const needsListTemplates = (state = defaultState, action) => {
  let index;
  let newNeedsListTemplates;

  switch (action.type) {
    case REQUEST_GET_NEEDS_LIST_TEMPLATES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_NEEDS_LIST_TEMPLATES:
      return {
        ...state,
        needsListTemplates: action.needsListTemplates.items,
        totalPages: action.needsListTemplates.totalPages,
        totalItems: action.needsListTemplates.totalItems,
        hasNextPage: action.needsListTemplates.hasNextPage,
        hasPreviousPage: action.needsListTemplates.hasPreviousPage,
        pageNumber: action.needsListTemplates.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        needsListTemplate: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        needsListTemplate: action.needsListTemplate,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        needsListTemplate: {},
      };
    case REQUEST_UPDATE_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE:
      index = findIndex(state.needsListTemplates, ['needsListTemplateId', action.needsListTemplate.needsListTemplateId]);
      if (index >= 0) {
        newNeedsListTemplates = fill([...state.needsListTemplates], {
          ...state.needsListTemplates[index],
          name: action.needsListTemplate.name,
          description: action.needsListTemplate.description,
        }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplates: newNeedsListTemplates || state.needsListTemplates,
        isSaving: false,
      };
    case REQUEST_CREATE_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        needsListTemplate: action.needsListTemplate,
        isSaving: false,
      };
    case REQUEST_DELETE_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      index = findIndex(state.needsListTemplates, ['needsListTemplateId', action.needsListTemplateDocument.needsListTemplateId]);
      if (index >= 0) {
        newNeedsListTemplates = fill([...state.needsListTemplates], { ...state.needsListTemplates[index], documents: state.needsListTemplates[index].documents + 1 }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplates: newNeedsListTemplates || state.needsListTemplates,
        isSaving: false,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      index = findIndex(state.needsListTemplates, ['needsListTemplateId', action.needsListTemplateDocument.needsListTemplateId]);
      if (index >= 0) {
        newNeedsListTemplates = fill([...state.needsListTemplates], { ...state.needsListTemplates[index], documents: state.needsListTemplates[index].documents - 1 }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplates: newNeedsListTemplates || state.needsListTemplates,
        isSaving: false,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      index = findIndex(state.needsListTemplates, ['needsListTemplateId', action.needsListTemplateDocuments.needsListTemplateId]);
      if (index >= 0) {
        newNeedsListTemplates = fill([...state.needsListTemplates], { ...state.needsListTemplates[index], documents: action.needsListTemplateDocuments.totalItems }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplates: newNeedsListTemplates || state.needsListTemplates,
        isSaving: false,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      index = findIndex(state.needsListTemplates, ['needsListTemplateId', action.needsListTemplateDocuments.needsListTemplateId]);
      if (index >= 0) {
        newNeedsListTemplates = fill([...state.needsListTemplates], { ...state.needsListTemplates[index], documents: 0 }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplates: newNeedsListTemplates || state.needsListTemplates,
        isSaving: false,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_ERROR:
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_ERROR:
    case RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE_ERROR:
    case RECEIVE_GET_NEEDS_LIST_TEMPLATE_ERROR:
    case RECEIVE_GET_NEEDS_LIST_TEMPLATES_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
