import {
  REQUEST_IMPORT_CONTACTS,
  RECEIVE_IMPORT_CONTACTS,
  RECEIVE_IMPORT_CONTACTS_ERROR,
  CLEAR_IMPORT_CONTACTS,
  REQUEST_STOP_IMPORT_CONTACTS,
  REQUEST_PENDING_CONTACTS,
  RECEIVE_PENDING_CONTACTS,
  RECEIVE_PENDING_CONTACTS_ERROR,
  CLEAR_PENDING_CONTACTS,
  REQUEST_REVIEW_CONTACTS,
  RECEIVE_REVIEW_CONTACTS,
  RECEIVE_REVIEW_CONTACTS_ERROR,
  RECEIVE_DELETE_CONTACTS_ERROR,
  RECEIVE_DELETE_CONTACTS,
  REQUEST_DELETE_CONTACTS,
} from '../constants/contacts';
import { SORT_OPTIONS } from '../../utils/applicationConstants';

export const defaultContactsFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  contacts: [],
  contact: {},
  contactsProcessed: 0,
  importedContacts: 0,
  progress: 0,
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isReviewing: false,
  isDeleting: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const contacts = (state = defaultState, action) => {
  let result;
  switch (action.type) {
    case REQUEST_IMPORT_CONTACTS:
      result = (((state.contactsProcessed + action.contactsProcessed) / action.totalContacts) * 100);
      return {
        ...state,
        isSaving: true,
        contactsProcessed: state.contactsProcessed + action.contactsProcessed,
        progress: result.toFixed(0),
      };
    case RECEIVE_IMPORT_CONTACTS:
      return {
        ...state,
        importedContacts: state.importedContacts + action.contacts.rowsAffected,
      };
    case REQUEST_STOP_IMPORT_CONTACTS:
      return {
        ...state,
        isSaving: false,
      };
    case CLEAR_IMPORT_CONTACTS:
      return {
        ...state,
        importedContacts: 0,
        contactsProcessed: 0,
        progress: 0,
      };
    case REQUEST_PENDING_CONTACTS:
      return {
        ...state,
        contacts: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_PENDING_CONTACTS:
      return {
        ...state,
        contacts: action.contacts.items,
        totalPages: action.contacts.totalPages,
        totalItems: action.contacts.totalItems,
        hasNextPage: action.contacts.hasNextPage,
        hasPreviousPage: action.contacts.hasPreviousPage,
        pageNumber: action.contacts.pageNumber,
        isFetching: false,
      };
    case CLEAR_PENDING_CONTACTS:
      return {
        ...state,
        contacts: [],
        error: null,
        isFetching: false,
        isSaving: false,
        pageNumber: 0,
        totalPages: 0,
        totalItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case REQUEST_REVIEW_CONTACTS:
      return {
        ...state,
        isReviewing: true,
      };
    case RECEIVE_REVIEW_CONTACTS:
      return {
        ...state,
        isReviewing: false,
      };
    case REQUEST_DELETE_CONTACTS:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_CONTACTS:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_REVIEW_CONTACTS_ERROR:
    case RECEIVE_IMPORT_CONTACTS_ERROR:
    case RECEIVE_PENDING_CONTACTS_ERROR:
    case RECEIVE_DELETE_CONTACTS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isReviewing: false,
        isDeleting: false,
      };
    default:
      return state;
  }
};
