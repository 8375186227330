export const REQUEST_CLONE_NEEDS_LIST_TEMPLATE = 'REQUEST_CLONE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_CLONE_NEEDS_LIST_TEMPLATE = 'RECEIVE_CLONE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_CLONE_NEEDS_LIST_TEMPLATE_ERROR = 'RECEIVE_CLONE_NEEDS_LIST_TEMPLATE_ERROR';

export const REQUEST_CREATE_NEEDS_LIST_TEMPLATE = 'REQUEST_CREATE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_ERROR = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_ERROR';

export const REQUEST_UPDATE_NEEDS_LIST_TEMPLATE = 'REQUEST_UPDATE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE = 'RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE_ERROR = 'RECEIVE_UPDATE_NEEDS_LIST_TEMPLATE_ERROR';

export const REQUEST_GET_NEEDS_LIST_TEMPLATE = 'REQUEST_GET_NEEDS_LIST_TEMPLATE';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATE = 'RECEIVE_GET_NEEDS_LIST_TEMPLATE';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATE_ERROR = 'RECEIVE_GET_NEEDS_LIST_TEMPLATE_ERROR';

export const REQUEST_GET_NEEDS_LIST_TEMPLATES = 'REQUEST_GET_NEEDS_LIST_TEMPLATES';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATES = 'RECEIVE_GET_NEEDS_LIST_TEMPLATES';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATES_ERROR = 'RECEIVE_GET_NEEDS_LIST_TEMPLATES_ERROR';

export const REQUEST_DELETE_NEEDS_LIST_TEMPLATE = 'REQUEST_DELETE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_ERROR = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_ERROR';

export const REQUEST_CLEAR_NEEDS_LIST_TEMPLATE = 'REQUEST_CLEAR_NEEDS_LIST_TEMPLATE';
export const REQUEST_CLEAR_NEEDS_LIST_TEMPLATES = 'REQUEST_CLEAR_NEEDS_LIST_TEMPLATES';
