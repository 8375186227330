export const REQUEST_GET_LENDER_CALL_LOG_STATUSES = 'REQUEST_GET_LENDER_CALL_LOG_STATUSES';
export const RECEIVE_GET_LENDER_CALL_LOG_STATUSES = 'RECEIVE_GET_LENDER_CALL_LOG_STATUSES';
export const RECEIVE_GET_LENDER_CALL_LOG_STATUSES_ERROR = 'RECEIVE_GET_LENDER_CALL_LOG_STATUSES_ERROR';

export const REQUEST_GET_LENDER_CALL_LOG_STATUS = 'REQUEST_GET_LENDER_CALL_LOG_STATUS';
export const RECEIVE_GET_LENDER_CALL_LOG_STATUS = 'RECEIVE_GET_LENDER_CALL_LOG_STATUS';
export const RECEIVE_GET_LENDER_CALL_LOG_STATUS_ERROR = 'RECEIVE_GET_LENDER_CALL_LOG_STATUS_ERROR';

export const REQUEST_UPDATE_LENDER_CALL_LOG_STATUS = 'REQUEST_UPDATE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS = 'RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS_ERROR = 'RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS_ERROR';

export const REQUEST_DELETE_LENDER_CALL_LOG_STATUS = 'REQUEST_DELETE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_DELETE_LENDER_CALL_LOG_STATUS = 'RECEIVE_DELETE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_DELETE_LENDER_CALL_LOG_STATUS_ERROR = 'RECEIVE_DELETE_LENDER_CALL_LOG_STATUS_ERROR';

export const REQUEST_CREATE_LENDER_CALL_LOG_STATUS = 'REQUEST_CREATE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_CREATE_LENDER_CALL_LOG_STATUS = 'RECEIVE_CREATE_LENDER_CALL_LOG_STATUS';
export const RECEIVE_CREATE_LENDER_CALL_LOG_STATUS_ERROR = 'RECEIVE_CREATE_LENDER_CALL_LOG_STATUS_ERROR';

export const REQUEST_CLEAR_LENDER_CALL_LOG_STATUS = 'REQUEST_CLEAR_LENDER_CALL_LOG_STATUS';
