import React from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import GlobalSearchBox from "../search_box/global_search_box";
import { UserDropDownMenuMobile } from "../user_drop_down_menu/user_drop_down_menu_mobile";
import DarkModeSwitch from "../dark_mode_switch/dark_mode_switch";

function TopBar({ setSidebarOpen }) {
  return (
    <div className="relative  flex-shrink-0 flex h-16 bg-white dark:bg-slate-800 dark:bg-opacity-50 lg:border-l dark:lg:border-slate-900 dark:border-slate-900 shadow-md">
      <button
        type="button"
        className="px-4 border-r border-gray-200 dark:border-slate-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex w-full items-center justify-between px-2">
        <GlobalSearchBox />
        <div className="flex basis-3/12 justify-end items-center space-x-3">
          {/* Dark Mode Switch */}
          <DarkModeSwitch />
          {/* Profile Dropdown */}
          <UserDropDownMenuMobile />
        </div>
      </div>
    </div>
  );
}

export default TopBar;

TopBar.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
};
