import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

function NavigationItem({ icon, path, caption }) {
  const { route } = useRouter();
  const Icon = icon;

  return (
    (<Link
      href={path}
      className={classNames(
        route === path
          ? 'bg-indigo-800 dark:bg-indigo-600 dark:text-slate-100'
          : 'dark:text-slate-300 dark:hover:text-slate-100 hover:bg-indigo-800 dark:hover:bg-indigo-600',
        'group text-indigo-100 flex items-center px-2 py-2 text-base font-medium rounded-md',
      )}>

      <Icon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
      {caption}

    </Link>)
  );
}

export default NavigationItem;

NavigationItem.propTypes = {
  icon: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};
