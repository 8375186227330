export const REQUEST_IMPORT_CONTACTS = 'REQUEST_IMPORT_CONTACTS';
export const RECEIVE_IMPORT_CONTACTS = 'RECEIVE_IMPORT_CONTACTS';
export const RECEIVE_IMPORT_CONTACTS_ERROR = 'RECEIVE_IMPORT_CONTACTS_ERROR';
export const REQUEST_STOP_IMPORT_CONTACTS = 'STOP_IMPORT_CONTACTS';
export const CLEAR_IMPORT_CONTACTS = 'CLEAR_IMPORT_CONTACTS';

export const REQUEST_PENDING_CONTACTS = 'REQUEST_PENDING_CONTACTS';
export const RECEIVE_PENDING_CONTACTS = 'RECEIVE_PENDING_CONTACTS';
export const RECEIVE_PENDING_CONTACTS_ERROR = 'RECEIVE_PENDING_CONTACTS_ERROR';
export const CLEAR_PENDING_CONTACTS = 'CLEAR_PENDING_CONTACTS';

export const REQUEST_REVIEW_CONTACTS = 'REQUEST_REVIEW_CONTACTS';
export const RECEIVE_REVIEW_CONTACTS = 'RECEIVE_REVIEW_CONTACTS';
export const RECEIVE_REVIEW_CONTACTS_ERROR = 'RECEIVE_REVIEW_CONTACTS_ERROR';

export const REQUEST_DELETE_CONTACTS = 'REQUEST_DELETE_CONTACTS';
export const RECEIVE_DELETE_CONTACTS = 'RECEIVE_DELETE_CONTACTS';
export const RECEIVE_DELETE_CONTACTS_ERROR = 'RECEIVE_DELETE_CONTACTS_ERROR';
