import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  AdjustmentsVerticalIcon,
  DocumentTextIcon,
  HomeIcon,
  UsersIcon,
  UserGroupIcon,
  CogIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import {
  isAdministratorSelector,
  isAffiliateSelector,
  isBrokerSelector,
  isOwnerSelector,
} from "../../redux/selectors/currentUser";
import NavigationItem from "./navigation_item";
import NavigationSubItem from "./navigation_subitem";

export function Navigation() {
  const isOwner = useSelector(isOwnerSelector);
  const isAdmin = useSelector(isAdministratorSelector);
  const isBroker = useSelector(isBrokerSelector);
  const isAffiliate = useSelector(isAffiliateSelector);

  return (
    <>
      <div className="space-y-1 mx-2 mt-5">
        <NavigationItem icon={HomeIcon} path="/dashboard" caption="Dashboard" />
        {(isOwner || isAdmin) && <NavigationItem icon={UserGroupIcon} path="/team" caption="Team" />}
        {(isOwner || isAdmin || isBroker || isAffiliate) && (
          <NavigationItem icon={DocumentTextIcon} path="/loans" caption="Loans" />
        )}
        {(isOwner || isAdmin || isBroker) && <NavigationItem icon={UsersIcon} path="/contacts" caption="Contacts" />}
        {(isOwner || isAdmin) && (
          <Disclosure as="div" className="space-y-1 flex flex-col hidden">
            {({ open }) => (
              <>
                <Disclosure.Button
                  className={classNames(
                    "text-indigo-100 dark:text-slate-300 hover:bg-indigo-800",
                    "group flex items-center px-2 py-2 text-base font-medium rounded-md",
                  )}
                >
                  <AdjustmentsVerticalIcon className="mr-3 flex-shrink-0 h-6 w-6" aria-hidden="true" />
                  <span className="flex-grow text-left">Lending Parameters</span>
                  <svg
                    className={classNames(
                      open && "rotate-90",
                      "text-indigo-100 dark:text-slate-100 ml-3 flex-shrink-0 h-5 w-5 transform",
                      "transition-colors ease-in-out duration-150",
                    )}
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="space-y-1">
                  <NavigationSubItem path="/loan-types" caption="Loan Types" />
                  <NavigationSubItem path="/loan-statuses" caption="Loan Statuses" />
                  <NavigationSubItem path="/property-types" caption="Property Types" />
                  <NavigationSubItem path="/property-uses" caption="Property Uses" />
                  <NavigationSubItem path="/values-based-on" caption="Values Based On" />
                  <NavigationSubItem path="/documents" caption="Documents" />
                  <NavigationSubItem path="/lender-call-log-statuses" caption="Lender Call Logs Statuses" />
                  <NavigationSubItem path="/lending-companies" caption="Lending Companies" />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </div>
      <div className="mt-auto  mx-2 border-t border-indigo-700 dark:border-slate-700 py-4">
        <div className="mt-1 space-y-1" role="group" aria-labelledby="company-selector-headline">
          {(isOwner || isAdmin) && <NavigationItem icon={CogIcon} path="/configuration" caption="Configuration" />}
          <NavigationItem icon={QuestionMarkCircleIcon} path="/feedback" caption="Help" />
        </div>
      </div>
    </>
  );
}
