export const REQUEST_CREATE_LOAN = 'REQUEST_CREATE_LOAN';
export const RECEIVE_CREATE_LOAN = 'RECEIVE_CREATE_LOAN';
export const RECEIVE_CREATE_LOAN_ERROR = 'RECEIVE_CREATE_LOAN_ERROR';

export const REQUEST_UPDATE_LOAN = 'REQUEST_UPDATE_LOAN';
export const RECEIVE_UPDATE_LOAN = 'RECEIVE_UPDATE_LOAN';
export const RECEIVE_UPDATE_LOAN_ERROR = 'RECEIVE_UPDATE_LOAN_ERROR';

export const REQUEST_PATCH_LOAN = 'REQUEST_PATCH_LOAN';
export const RECEIVE_PATCH_LOAN = 'RECEIVE_PATCH_LOAN';
export const RECEIVE_PATCH_LOAN_ERROR = 'RECEIVE_PATCH_LOAN_ERROR';

export const REQUEST_GET_LOAN = 'REQUEST_GET_LOAN';
export const RECEIVE_GET_LOAN = 'RECEIVE_GET_LOAN';
export const RECEIVE_GET_LOAN_ERROR = 'RECEIVE_GET_LOAN_ERROR';

export const REQUEST_GET_LOANS = 'REQUEST_GET_LOANS';
export const RECEIVE_GET_LOANS = 'RECEIVE_GET_LOANS';
export const RECEIVE_GET_LOANS_ERROR = 'RECEIVE_GET_LOANS_ERROR';

export const REQUEST_CREATE_LOAN_NOTE = 'REQUEST_CREATE_LOAN_NOTE';
export const RECEIVE_CREATE_LOAN_NOTE = 'RECEIVE_CREATE_LOAN_NOTE';
export const RECEIVE_CREATE_LOAN_NOTE_ERROR = 'RECEIVE_CREATE_LOAN_NOTE_ERROR';

export const REQUEST_DELETE_LOAN = 'REQUEST_DELETE_LOAN';
export const RECEIVE_DELETE_LOAN = 'RECEIVE_DELETE_LOAN';
export const RECEIVE_DELETE_LOAN_ERROR = 'RECEIVE_DELETE_LOAN_ERROR';

export const REQUEST_GET_LOAN_FILTERS = 'REQUEST_GET_LOAN_FILTERS';
export const RECEIVE_GET_LOAN_FILTERS = 'RECEIVE_GET_LOAN_FILTERS';
export const RECEIVE_GET_LOAN_FILTERS_ERROR = 'RECEIVE_GET_LOAN_FILTERS_ERROR';

export const REQUEST_CLEAR_LOAN = 'REQUEST_CLEAR_LOAN';
export const REQUEST_CLEAR_LOANS = 'REQUEST_CLEAR_LOANS';
