import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import {
  REQUEST_GET_LOANS,
  RECEIVE_GET_LOANS_ERROR,
  RECEIVE_GET_LOANS,
  REQUEST_CREATE_LOAN,
  RECEIVE_CREATE_LOAN,
  RECEIVE_CREATE_LOAN_ERROR,
  RECEIVE_GET_LOAN_ERROR,
  REQUEST_GET_LOAN,
  RECEIVE_GET_LOAN,
  REQUEST_CLEAR_LOAN,
  REQUEST_UPDATE_LOAN,
  RECEIVE_UPDATE_LOAN,
  RECEIVE_UPDATE_LOAN_ERROR,
  REQUEST_CLEAR_LOANS,
  REQUEST_CREATE_LOAN_NOTE,
  RECEIVE_CREATE_LOAN_NOTE,
  RECEIVE_CREATE_LOAN_NOTE_ERROR,
  REQUEST_DELETE_LOAN,
  RECEIVE_DELETE_LOAN,
  RECEIVE_DELETE_LOAN_ERROR,
  REQUEST_GET_LOAN_FILTERS,
  RECEIVE_GET_LOAN_FILTERS,
  RECEIVE_GET_LOAN_FILTERS_ERROR,
  RECEIVE_PATCH_LOAN,
} from '../constants/loans';
import { SORT_OPTIONS } from '../../utils/applicationConstants';

export const defaultLoansFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
  loanTypes: [],
  loanStatuses: [],
  propertyTypes: [],
  loanPrograms: [],
  showActive: true,
};

const defaultState = {
  loanFilters: [],
  loans: [],
  loan: {},
  loanName: null,
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isFetchingFilters: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  isPostingNote: false,
};

export const loans = (state = defaultState, action) => {
  let index;
  let newLoans;
  switch (action.type) {
    case REQUEST_CREATE_LOAN:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LOAN:
      return {
        ...state,
        loan: action.loan,
        isSaving: false,
      };
    case REQUEST_CREATE_LOAN_NOTE:
      return {
        ...state,
        isPostingNote: true,
      };
    case RECEIVE_CREATE_LOAN_NOTE:
      return {
        ...state,
        loan: {
          ...state.loan,
          notes: concat(state.loan.notes, action.note),
        },
        isPostingNote: false,
      };
    case REQUEST_UPDATE_LOAN:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LOAN:
      return {
        ...state,
        loan: action.loan,
        isSaving: false,
      };
    case RECEIVE_PATCH_LOAN:
      index = findIndex(state.loans, ['loanId', action.loan.loanId]);
      if (index >= 0) {
        newLoans = fill([...state.loans], { ...state.loans[index], ...action.loan }, index, index + 1);
      }
      return {
        ...state,
        loans: newLoans || state.loans,
        isSaving: false,
      };
    case REQUEST_DELETE_LOAN:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LOAN:
      return {
        ...state,
        loan: action.loan,
        isDeleting: false,
      };
    case REQUEST_GET_LOANS:
      return {
        ...state,
        loans: [],
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        isFetching: true,
      };
    case RECEIVE_GET_LOANS:
      return {
        ...state,
        loans: action.loans.items,
        totalPages: action.loans.totalPages,
        totalItems: action.loans.totalItems,
        hasNextPage: action.loans.hasNextPage,
        hasPreviousPage: action.loans.hasPreviousPage,
        pageNumber: action.loans.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LOAN:
      return {
        ...state,
        loan: {},
        loanName: null,
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LOAN:
      return {
        ...state,
        loan: action.loan,
        loanName: action.loan.loan.name,
        isFetchingSingle: false,
      };
    case REQUEST_GET_LOAN_FILTERS:
      return {
        ...state,
        loanFilters: [],
        isFetchingFilters: true,
      };
    case RECEIVE_GET_LOAN_FILTERS:
      return {
        ...state,
        loanFilters: action.loanFilters,
        isFetchingFilters: false,
      };
    case REQUEST_CLEAR_LOAN:
      return {
        ...state,
        loan: {},
      };
    case REQUEST_CLEAR_LOANS:
      return {
        ...state,
        loans: [],
      };
    case RECEIVE_UPDATE_LOAN_ERROR:
    case RECEIVE_GET_LOAN_ERROR:
    case RECEIVE_CREATE_LOAN_ERROR:
    case RECEIVE_GET_LOANS_ERROR:
    case RECEIVE_CREATE_LOAN_NOTE_ERROR:
    case RECEIVE_DELETE_LOAN_ERROR:
    case RECEIVE_GET_LOAN_FILTERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isFetchingSingle: false,
        isFetchingFilters: false,
        pageNumber: 0,
        totalPages: 0,
        totalItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        isPostingNote: false,
      };
    default:
      return state;
  }
};
