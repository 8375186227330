export const REQUEST_GET_VALUES_BASED_ON = 'REQUEST_GET_VALUES_BASED_ON';
export const RECEIVE_GET_VALUES_BASED_ON = 'RECEIVE_GET_VALUES_BASED_ON';
export const RECEIVE_GET_VALUES_BASED_ON_ERROR = 'RECEIVE_GET_VALUES_BASED_ON_ERROR';

export const REQUEST_GET_VALUE_BASED_ON = 'REQUEST_GET_VALUE_BASED_ON';
export const RECEIVE_GET_VALUE_BASED_ON = 'RECEIVE_GET_VALUE_BASED_ON';
export const RECEIVE_GET_VALUE_BASED_ON_ERROR = 'RECEIVE_GET_VALUE_BASED_ON_ERROR';

export const REQUEST_UPDATE_VALUE_BASED_ON = 'REQUEST_UPDATE_VALUE_BASED_ON';
export const RECEIVE_UPDATE_VALUE_BASED_ON = 'RECEIVE_UPDATE_VALUE_BASED_ON';
export const RECEIVE_UPDATE_VALUE_BASED_ON_ERROR = 'RECEIVE_UPDATE_VALUE_BASED_ON_ERROR';

export const REQUEST_DELETE_VALUE_BASED_ON = 'REQUEST_DELETE_VALUE_BASED_ON';
export const RECEIVE_DELETE_VALUE_BASED_ON = 'RECEIVE_DELETE_VALUE_BASED_ON';
export const RECEIVE_DELETE_VALUE_BASED_ON_ERROR = 'RECEIVE_DELETE_VALUE_BASED_ON_ERROR';

export const REQUEST_CREATE_VALUE_BASED_ON = 'REQUEST_CREATE_VALUE_BASED_ON';
export const RECEIVE_CREATE_VALUE_BASED_ON = 'RECEIVE_CREATE_VALUE_BASED_ON';
export const RECEIVE_CREATE_VALUE_BASED_ON_ERROR = 'RECEIVE_CREATE_VALUE_BASED_ON_ERROR';

export const REQUEST_CLEAR_VALUE_BASED_ON = 'REQUEST_CLEAR_VALUE_BASED_ON';
