import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "../../utils/applicationConstants";
import { needsListTemplateService } from "./needsListTemplateService";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const defaultNeedsListTemplatesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
};

export const needsListTemplateDocumentService = createApi({
  reducerPath: "needsListTemplateDocumentApi",
  tagTypes: ["NeedsListTemplateDocument"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getNeedsListTemplateDocuments: builder.query({
      query: ({ needsListTemplateId, ...params }) =>
        `needs-list-templates/${needsListTemplateId}/documents?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ documentId }) => ({ type: "NeedsListTemplateDocument", documentId })),
              { type: "NeedsListTemplateDocument", id: "LIST" },
            ]
          : [{ type: "NeedsListTemplateDocument", id: "LIST" }],
    }),
    addNeedsListTemplateDocument: builder.mutation({
      query: ({ needsListTemplateId, documentId }) => ({
        url: `needs-list-templates/${needsListTemplateId}/documents/${documentId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "NeedsListTemplateDocument", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_TEMPLATE_DOCUMENT_CREATED, { ...data });
          dispatch(needsListTemplateService.util.invalidateTags(["NeedsListTemplate"]));
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `Document has been added to the template!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    addNeedsListTemplateDocuments: builder.mutation({
      query: ({ needsListTemplateId }) => ({
        url: `needs-list-templates/${needsListTemplateId}/documents`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "NeedsListTemplateDocument", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_TEMPLATE_DOCUMENTS_CREATED);
          dispatch(needsListTemplateService.util.invalidateTags(["NeedsListTemplate"]));
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `All documents has been added to the template!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteNeedsListTemplateDocument: builder.mutation({
      query({ needsListTemplateId, documentId }) {
        return {
          url: `needs-list-templates/${needsListTemplateId}/documents/${documentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "NeedsListTemplateDocument", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_TEMPLATE_DOCUMENT_DELETED, { ...data });
          dispatch(needsListTemplateService.util.invalidateTags(["NeedsListTemplate"]));
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Document has been removed from the template!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteNeedsListTemplateDocuments: builder.mutation({
      query({ needsListTemplateId }) {
        return {
          url: `needs-list-templates/${needsListTemplateId}/documents`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "NeedsListTemplateDocument", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.NEEDS_LIST_TEMPLATE_DOCUMENTS_DELETED);
          dispatch(needsListTemplateService.util.invalidateTags(["NeedsListTemplate"]));
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All documents has been removed from the template!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetNeedsListTemplateDocumentsQuery,
  useAddNeedsListTemplateDocumentMutation,
  useDeleteNeedsListTemplateDocumentMutation,
  useAddNeedsListTemplateDocumentsMutation,
  useDeleteNeedsListTemplateDocumentsMutation,
} = needsListTemplateDocumentService;
