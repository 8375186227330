import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "../utils/httpRequest";

export function fetchValuesBasedOn(companyId, params) {
  return fetchWithHeaders(`/parameters/values-based-on?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchValueBasedOn(companyId, path) {
  return fetchWithHeaders(`/values-based-on/${path.valueBasedOnId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updateValueBasedOn(companyId, path, payload = {}) {
  return fetchWithHeaders(`/values-based-on/${path.valueBasedOnId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createValueBasedOn(companyId, payload = {}) {
  return fetchWithHeaders("/values-based-on", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deleteValueBasedOn(companyId, path) {
  return fetchWithHeaders(`/values-based-on/${path.valueBasedOnId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
