import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";

export const accountService = createApi({
  reducerPath: "accountService",
  tagTypes: ["Account"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    removeConnection: builder.mutation({
      query({ provider, ...body }) {
        return {
          url: `auth/remove-identity?provider=google-oauth2`,
          method: "DELETE",
          body,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Connection has been removed!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );

          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } catch (err) {
          /* empty */
        }
      },
    }),
    disconnectAccount: builder.mutation({
      query({ primaryUserId: userId, ...body }) {
        return {
          url: `account/${userId}/connections`,
          method: "DELETE",
          body,
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Connection has been removed!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const { useRemoveConnectionMutation, useDisconnectAccountMutation } = accountService;
