import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import { SORT_OPTIONS } from '../../utils/applicationConstants';
import {
  RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE,
  RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR,
  RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE,
  RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR,
  RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES,
  RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES_ERROR,
  REQUEST_CLEAR_LOAN_SUMMARY_NEEDS_LIST_FILES,
  REQUEST_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE,
  REQUEST_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE,
  REQUEST_GET_LOAN_SUMMARY_NEEDS_LIST_FILES,
} from '../constants/loan_summary_needs_list_files';

export const defaultLoanSummaryNeedsListFilesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  loanSummaryNeedsListFiles: [],
  needsListFileId: null,
  isFetching: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  isSaving: false,
  error: null,
};

export const loanSummaryNeedsListFiles = (state = defaultState, action) => {
  let index;
  let newList;
  switch (action.type) {
    case REQUEST_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE:
      return {
        ...state,
        needsListFileId: action.needsListFileId,
        isSaving: true,
      };
    case RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE:
      index = findIndex(
        state.loanSummaryNeedsListFiles,
        ['needsListFileId', action.loanSummaryNeedsListFile.needsListFileId],
      );
      if (index >= 0) {
        newList = fill([...state.loanSummaryNeedsListFiles], action.loanSummaryNeedsListFile, index, index + 1);
      }
      return {
        ...state,
        loanSummaryNeedsListFiles: newList || state.loanSummaryNeedsListFiles,
        needsListFileId: null,
        isSaving: false,
      };
    case REQUEST_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE:
      return {
        ...state,
        isSaving: true,
        needsListFileId: action.needsListFileId,
      };
    case RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE:
      index = findIndex(
        state.loanSummaryNeedsListFiles,
        ['needsListFileId', action.loanSummaryNeedsListFile.needsListFileId],
      );
      if (index >= 0) {
        newList = fill([...state.loanSummaryNeedsListFiles], action.loanSummaryNeedsListFile, index, index + 1);
      }
      return {
        ...state,
        needsListFileId: null,
        isSaving: false,
        loanSummaryNeedsListFiles: newList || state.loanSummaryNeedsListFiles,
      };
    case REQUEST_GET_LOAN_SUMMARY_NEEDS_LIST_FILES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES:
      return {
        ...state,
        isFetching: false,
        loanSummaryNeedsListFiles: action.loanSummaryNeedsListFiles,
      };
    case REQUEST_CLEAR_LOAN_SUMMARY_NEEDS_LIST_FILES:
      return {
        ...state,
        loanSummaryNeedsListFiles: [],
      };
    case RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES_ERROR:
    case RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR:
    case RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        needsListFileId: null,
      };
    default:
      return state;
  }
};
