import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "../../utils/applicationConstants";
import { requestCloseSlideOver } from "../actions/slide_over";
import { requestCloseModal } from "../actions/modal";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";
import { setProcessingState } from "../reducers/modalConfirmation";

export const defaultEmployeesFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: "",
  roles: [],
};

export const employeeService = createApi({
  reducerPath: "employeeApi",
  tagTypes: ["Employee"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getEmployees: builder.query({
      query: params => `employees?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ employeeId }) => ({ type: "Employee", employeeId })),
              { type: "Employee", id: "LIST" },
            ]
          : [{ type: "Employee", id: "LIST" }],
    }),
    getEmployee: builder.query({
      query: id => `employees/${id}`,
      providesTags: (result, error, employeeId) => [{ type: "Employee", employeeId }],
    }),
    sendEmployeeInvite: builder.mutation({
      query: id => ({
        url: `employees/${id}/invite`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Employee", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.TEAM_MEMBER_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Team member has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseModal());
        } catch (err) {
          /* empty */
        }
      },
    }),
    addEmployee: builder.mutation({
      query: body => ({
        url: "employees",
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Employee", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.TEAM_MEMBER_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Team member has been created!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    updateEmployee: builder.mutation({
      query: ({ employeeId: id, ...patch }) => ({
        url: `employees/${id}`,
        method: "PUT",
        body: patch,
      }),
      invalidatesTags: (result, error, { employeeId }) => [{ type: "Employee", employeeId }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.TEAM_MEMBER_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Team member has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteEmployee: builder.mutation({
      query(id) {
        return {
          url: `employees/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Employee", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.TEAM_MEMBER_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Team member has been deleted!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(requestCloseSlideOver());
          dispatch(requestCloseModal());
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const {
  useGetEmployeesQuery,
  useGetEmployeeQuery,
  useSendEmployeeInviteMutation,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
} = employeeService;
