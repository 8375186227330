import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";

export const dashboardService = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getCompanySummary: builder.query({
      query: () => "companies/summary",
    }),
    getCompanyFilesActivity: builder.query({
      query: () => "companies/needs-list/files-activity",
    }),
    getCompanyOutstandingNeedsListToComplete: builder.query({
      query: params => `companies/needs-list/outstanding?${getQueryParams(params)}`,
    }),
    getCompanyOutstandingNeedsListToReview: builder.query({
      query: params => `companies/needs-list/review?${getQueryParams(params)}`,
    }),
  }),
});

export const {
  useGetCompanySummaryQuery,
  useGetCompanyFilesActivityQuery,
  useGetCompanyOutstandingNeedsListToCompleteQuery,
  useGetCompanyOutstandingNeedsListToReviewQuery,
} = dashboardService;
