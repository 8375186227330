import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const loanSummaryNeedsListService = createApi({
  reducerPath: "loanSummaryNeedsListApi",
  tagTypes: ["LoanSummaryNeedsList"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanSummaryNeedsList: builder.query({
      query: ({ loanId, loanSummaryId, searchTerm }) =>
        `loans/${loanId}/summaries/${loanSummaryId}/needs-list?searchTerm=${searchTerm}`,
      providesTags: result =>
        result
          ? [
              // ...result.map(({ needsListFileId }) => ({ type: "LoanSummary", needsListFileId })),
              { type: "LoanSummaryNeedsList", id: "LIST" },
            ]
          : [{ type: "LoanSummaryNeedsList", id: "LIST" }],
      transformResponse: items =>
        items.reduce((item, file) => {
          const records = { ...item };
          if (!item[file.borrowerId]) {
            records[file.borrowerId] = {
              borrowerName: file.borrowerName,
              documents: {
                [file.documentId]: {
                  documentName: file.documentName,
                  files: [],
                },
              },
            };
          }

          if (!records[file.borrowerId].documents[file.documentId]) {
            records[file.borrowerId].documents[file.documentId] = {
              documentName: file.documentName,
              files: [],
            };
          }

          records[file.borrowerId].documents[file.documentId].files.push({
            loanId: file.loanId,
            loanSummaryId: file.loanSummaryId,
            borrowerId: file.borrowerId,
            needsListId: file.needsListId,
            needsListFileId: file.needsListFileId,
            needsListFileName: file.needsListFileName,
            isActive: file.isActive,
          });

          return records;
        }, {}),
    }),
    addLoanSummaryNeedsList: builder.mutation({
      query: ({ loanId, loanSummaryId, borrowerId, needsListId, needsListFileId }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/borrowers/${borrowerId}/needs-list/${needsListId}/files/${needsListFileId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LoanSummaryNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_NEEDS_LIST_FILE_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File added to loan summary!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoanSummaryNeedsList: builder.mutation({
      query: ({ loanId, loanSummaryId, borrowerId, needsListId, needsListFileId }) => ({
        url: `loans/${loanId}/summaries/${loanSummaryId}/borrowers/${borrowerId}/needs-list/${needsListId}/files/${needsListFileId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LoanSummaryNeedsList", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_SUMMARY_NEEDS_LIST_FILE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "File delete from loan summary!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLoanSummaryNeedsListQuery,
  useAddLoanSummaryNeedsListMutation,
  useDeleteLoanSummaryNeedsListMutation,
} = loanSummaryNeedsListService;
