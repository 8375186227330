export const REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT = 'REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR';

export const REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR = 'RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR';

export const REQUEST_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'REQUEST_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR = 'RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR';

export const REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT = 'REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR';

export const REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS';
export const RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR = 'RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR';

export const REQUEST_CLEAR_NEEDS_LIST_TEMPLATE_DOCUMENTS = 'REQUEST_CLEAR_NEEDS_LIST_TEMPLATE_DOCUMENTS';
