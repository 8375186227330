import {
  RECEIVE_GET_COUNTRIES_ERROR,
  REQUEST_GET_COUNTRIES,
  RECEIVE_GET_COUNTRIES,
} from '../constants/parameters';

const defaultState = {
  countries: [],
  country: { },
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
};

export const countries = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_COUNTRIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_COUNTRIES:
      return {
        ...state,
        countries: action.countries,
        isFetching: false,
      };
    case RECEIVE_GET_COUNTRIES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
