export const REQUEST_GET_LOAN_TYPES = 'REQUEST_GET_LOAN_TYPES';
export const RECEIVE_GET_LOAN_TYPES = 'RECEIVE_GET_LOAN_TYPES';
export const RECEIVE_GET_LOAN_TYPES_ERROR = 'RECEIVE_GET_LOAN_TYPES_ERROR';

export const REQUEST_GET_LOAN_TYPE = 'REQUEST_GET_LOAN_TYPE';
export const RECEIVE_GET_LOAN_TYPE = 'RECEIVE_GET_LOAN_TYPE';
export const RECEIVE_GET_LOAN_TYPE_ERROR = 'RECEIVE_GET_LOAN_TYPE_ERROR';

export const REQUEST_UPDATE_LOAN_TYPE = 'REQUEST_UPDATE_LOAN_TYPE';
export const RECEIVE_UPDATE_LOAN_TYPE = 'RECEIVE_UPDATE_LOAN_TYPE';
export const RECEIVE_UPDATE_LOAN_TYPE_ERROR = 'RECEIVE_UPDATE_LOAN_TYPE_ERROR';

export const REQUEST_DELETE_LOAN_TYPE = 'REQUEST_DELETE_LOAN_TYPE';
export const RECEIVE_DELETE_LOAN_TYPE = 'RECEIVE_DELETE_LOAN_TYPE';
export const RECEIVE_DELETE_LOAN_TYPE_ERROR = 'RECEIVE_DELETE_LOAN_TYPE_ERROR';

export const REQUEST_CREATE_LOAN_TYPE = 'REQUEST_CREATE_LOAN_TYPE';
export const RECEIVE_CREATE_LOAN_TYPE = 'RECEIVE_CREATE_LOAN_TYPE';
export const RECEIVE_CREATE_LOAN_TYPE_ERROR = 'RECEIVE_CREATE_LOAN_TYPE_ERROR';

export const REQUEST_CLEAR_LOAN_TYPE = 'REQUEST_CLEAR_LOAN_TYPE';
