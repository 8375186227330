import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../constants/notification';

const defaultState = {
  notifications: [],
};

export const notification = (state = defaultState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map((message) => (
          (action.dismissAll || message.key === action.key)
            ? { ...message, dismissed: true }
            : { ...message }
        )),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (message) => message.key !== action.key,
        ),
      };

    default:
      return state;
  }
};
