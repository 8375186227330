import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/24/outline';

export function EmptyState({
  title,
  subtitle = null,
  handleButtonClick = null,
  buttonText = null,
  containerClass = null,
  icon = PlusIcon,
  titleClass = null,
  subtitleClass = null,
}) {
  const Icon = icon;
  return (
    <div className={classNames('text-center', containerClass)}>
      <div className="flex justify-center">
        <ExclamationCircleIcon className="w-10 h-10 text-green-500" />
      </div>
      <h3 className={classNames('mt-2 text-sm font-medium text-gray-900 dark:text-slate-100', titleClass)}>{title}</h3>
      <p className={classNames('mt-1 text-sm text-gray-500 dark:text-slate-300', subtitleClass)}>{subtitle}</p>
      <div className="mt-6">
        {handleButtonClick && (
          <button
            type="button"
            onClick={handleButtonClick}
            className={classNames(
              'inline-flex items-center px-4 py-2 border border-transparent',
              'shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700',
              'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
            )}
          >
            <Icon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  handleButtonClick: PropTypes.func,
  containerClass: PropTypes.string,
  icon: PropTypes.any,
  titleClass: PropTypes.string,
  subtitleClass: PropTypes.string,
};
