import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import {
  RECEIVE_GET_LOAN_TYPE,
  RECEIVE_GET_LOAN_TYPE_ERROR,
  REQUEST_GET_LOAN_TYPE,
  RECEIVE_GET_LOAN_TYPES_ERROR,
  REQUEST_GET_LOAN_TYPES,
  RECEIVE_GET_LOAN_TYPES,
  REQUEST_CLEAR_LOAN_TYPE,
  RECEIVE_UPDATE_LOAN_TYPE_ERROR,
  RECEIVE_UPDATE_LOAN_TYPE,
  REQUEST_UPDATE_LOAN_TYPE,
  RECEIVE_CREATE_LOAN_TYPE_ERROR,
  RECEIVE_CREATE_LOAN_TYPE,
  REQUEST_CREATE_LOAN_TYPE,
  REQUEST_DELETE_LOAN_TYPE,
  RECEIVE_DELETE_LOAN_TYPE,
  RECEIVE_DELETE_LOAN_TYPE_ERROR,
} from "../constants/loan_types";
import { SORT_OPTIONS } from "../../utils/applicationConstants";

export const defaultLoanTypesFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: "",
};

const defaultState = {
  loanTypes: [],
  loanType: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const loanTypes = (state = defaultState, action) => {
  let index;
  let newLoanTypes;

  switch (action.type) {
    case REQUEST_GET_LOAN_TYPES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LOAN_TYPES:
      return {
        ...state,
        loanTypes: action.loanTypes,
        totalPages: action.loanTypes.totalPages,
        totalItems: action.loanTypes.totalItems,
        hasNextPage: action.loanTypes.hasNextPage,
        hasPreviousPage: action.loanTypes.hasPreviousPage,
        pageNumber: action.loanTypes.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LOAN_TYPE:
      return {
        ...state,
        loanType: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LOAN_TYPE:
      return {
        ...state,
        loanType: action.loanType,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_LOAN_TYPE:
      return {
        ...state,
        loanType: {},
      };
    case REQUEST_UPDATE_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LOAN_TYPE:
      index = findIndex(state.loanTypes, ["loanTypeId", action.loanType.loanTypeId]);
      if (index >= 0) {
        newLoanTypes = fill([...state.loanTypes], action.loanType, index, index + 1);
      }
      return {
        ...state,
        loanType: action.loanType,
        loanTypes: newLoanTypes || state.loanTypes,
        isSaving: false,
      };
    case REQUEST_CREATE_LOAN_TYPE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LOAN_TYPE:
      return {
        ...state,
        loanType: action.loanType,
        isSaving: false,
      };
    case REQUEST_DELETE_LOAN_TYPE:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LOAN_TYPE:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_LOAN_TYPE_ERROR:
    case RECEIVE_CREATE_LOAN_TYPE_ERROR:
    case RECEIVE_UPDATE_LOAN_TYPE_ERROR:
    case RECEIVE_GET_LOAN_TYPE_ERROR:
    case RECEIVE_GET_LOAN_TYPES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
        isDeleting: false,
      };
    default:
      return state;
  }
};
