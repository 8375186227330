export const REQUEST_CREATE_NEED_LIST = 'REQUEST_CREATE_NEED_LIST';
export const RECEIVE_CREATE_NEED_LIST = 'RECEIVE_CREATE_NEED_LIST';
export const RECEIVE_CREATE_NEED_LIST_ERROR = 'RECEIVE_CREATE_NEED_LIST_ERROR';

export const REQUEST_GENERATE_NEED_LIST_PACKAGE = 'REQUEST_GENERATE_NEED_LIST_PACKAGE';
export const RECEIVE_GENERATE_NEED_LIST_PACKAGE = 'RECEIVE_GENERATE_NEED_LIST_PACKAGE';
export const RECEIVE_GENERATE_NEED_LIST_PACKAGE_ERROR = 'RECEIVE_GENERATE_NEED_LIST_PACKAGE_ERROR';

export const REQUEST_NOTIFY_OUTSTANDING_NEED_LIST = 'REQUEST_NOTIFY_OUTSTANDING_NEED_LIST';
export const RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST = 'RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST';
export const RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST_ERROR = 'RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST_ERROR';

export const REQUEST_CREATE_NEED_LIST_FILE = 'REQUEST_CREATE_NEED_LIST_FILE';
export const RECEIVE_CREATE_NEED_LIST_FILE = 'RECEIVE_CREATE_NEED_LIST_FILE';
export const RECEIVE_CREATE_NEED_LIST_FILE_ERROR = 'RECEIVE_CREATE_NEED_LIST_FILE_ERROR';

export const REQUEST_CREATE_MY_NEED_LIST_FILE = 'REQUEST_CREATE_MY_NEED_LIST_FILE';
export const RECEIVE_CREATE_MY_NEED_LIST_FILE = 'RECEIVE_CREATE_MY_NEED_LIST_FILE';
export const RECEIVE_CREATE_MY_NEED_LIST_FILE_ERROR = 'RECEIVE_CREATE_MY_NEED_LIST_FILE_ERROR';

export const REQUEST_DOWNLOAD_NEED_LIST_FILE = 'REQUEST_DOWNLOAD_NEED_LIST_FILE';
export const RECEIVE_DOWNLOAD_NEED_LIST_FILE = 'RECEIVE_DOWNLOAD_NEED_LIST_FILE';
export const RECEIVE_DOWNLOAD_NEED_LIST_FILE_ERROR = 'RECEIVE_DOWNLOAD_NEED_LIST_FILE_ERROR';

export const REQUEST_PREVIEW_NEED_LIST_FILE = 'REQUEST_PREVIEW_NEED_LIST_FILE';
export const RECEIVE_PREVIEW_NEED_LIST_FILE = 'RECEIVE_PREVIEW_NEED_LIST_FILE';
export const RECEIVE_PREVIEW_NEED_LIST_FILE_ERROR = 'RECEIVE_PREVIEW_NEED_LIST_FILE_ERROR';

export const REQUEST_UPDATE_NEED_LIST_STATUS = 'REQUEST_UPDATE_NEED_LIST_STATUS';
export const RECEIVE_UPDATE_NEED_LIST_STATUS = 'RECEIVE_UPDATE_NEED_LIST_STATUS';
export const RECEIVE_UPDATE_NEED_LIST_STATUS_ERROR = 'RECEIVE_UPDATE_NEED_LIST_STATUS_ERROR';

export const REQUEST_GET_NEED_LISTS = 'REQUEST_GET_NEED_LISTS';
export const RECEIVE_GET_NEED_LISTS = 'RECEIVE_GET_NEED_LISTS';
export const RECEIVE_GET_NEED_LISTS_ERROR = 'RECEIVE_GET_NEED_LISTS_ERROR';

export const REQUEST_GET_NEED_LIST_FILES = 'REQUEST_GET_NEED_LIST_FILES';
export const RECEIVE_GET_NEED_LIST_FILES = 'RECEIVE_GET_NEED_LIST_FILES';
export const RECEIVE_GET_NEED_LIST_FILES_ERROR = 'RECEIVE_GET_NEED_LIST_FILES_ERROR';

export const REQUEST_GET_MY_NEED_LIST_FILES = 'REQUEST_GET_MY_NEED_LIST_FILES';
export const RECEIVE_GET_MY_NEED_LIST_FILES = 'RECEIVE_GET_MY_NEED_LIST_FILES';
export const RECEIVE_GET_MY_NEED_LIST_FILES_ERROR = 'RECEIVE_GET_MY_NEED_LIST_FILES_ERROR';

export const REQUEST_GET_NEED_LIST = 'REQUEST_GET_NEED_LIST';
export const RECEIVE_GET_NEED_LIST = 'RECEIVE_GET_NEED_LIST';
export const RECEIVE_GET_NEED_LIST_ERROR = 'RECEIVE_GET_NEED_LIST_ERROR';

export const REQUEST_DELETE_NEED_LIST = 'REQUEST_DELETE_NEED_LIST';
export const RECEIVE_DELETE_NEED_LIST = 'RECEIVE_DELETE_NEED_LIST';
export const RECEIVE_DELETE_NEED_LIST_ERROR = 'RECEIVE_DELETE_NEED_LIST_ERROR';

export const REQUEST_DELETE_NEED_LIST_FILE = 'REQUEST_DELETE_NEED_LIST_FILE';
export const RECEIVE_DELETE_NEED_LIST_FILE = 'RECEIVE_DELETE_NEED_LIST_FILE';
export const RECEIVE_DELETE_NEED_LIST_FILE_ERROR = 'RECEIVE_DELETE_NEED_LIST_FILE_ERROR';

export const REQUEST_DELETE_MY_NEED_LIST_FILE = 'REQUEST_DELETE_MY_NEED_LIST_FILE';
export const RECEIVE_DELETE_MY_NEED_LIST_FILE = 'RECEIVE_DELETE_MY_NEED_LIST_FILE';
export const RECEIVE_DELETE_MY_NEED_LIST_FILE_ERROR = 'RECEIVE_DELETE_MY_NEED_LIST_FILE_ERROR';

export const REQUEST_APPROVE_NEED_LIST_FILE = 'REQUEST_APPROVE_NEED_LIST_FILE';
export const RECEIVE_APPROVE_NEED_LIST_FILE = 'RECEIVE_APPROVE_NEED_LIST_FILE';
export const RECEIVE_APPROVE_NEED_LIST_FILE_ERROR = 'RECEIVE_APPROVE_NEED_LIST_FILE_ERROR';

export const REQUEST_REJECT_NEED_LIST_FILE = 'REQUEST_REJECT_NEED_LIST_FILE';
export const RECEIVE_REJECT_NEED_LIST_FILE = 'RECEIVE_REJECT_NEED_LIST_FILE';
export const RECEIVE_REJECT_NEED_LIST_FILE_ERROR = 'RECEIVE_REJECT_NEED_LIST_FILE_ERROR';

export const REQUEST_SORT_BORROWERS = 'REQUEST_SORT_BORROWERS';
export const RECEIVE_SORT_BORROWERS = 'RECEIVE_SORT_BORROWERS';
export const RECEIVE_SORT_BORROWERS_ERROR = 'RECEIVE_SORT_BORROWERS_ERROR';

export const REQUEST_SORT_NEEDS_LIST = 'REQUEST_SORT_NEEDS_LIST';
export const RECEIVE_SORT_NEEDS_LIST = 'RECEIVE_SORT_NEEDS_LIST';
export const RECEIVE_SORT_NEEDS_LIST_ERROR = 'RECEIVE_SORT_NEEDS_LIST_ERROR';

export const REQUEST_AUTH_NEED_LIST = 'REQUEST_AUTH_NEED_LIST';
export const RECEIVE_AUTH_NEED_LIST = 'RECEIVE_AUTH_NEED_LIST';
export const RECEIVE_AUTH_NEED_LIST_ERROR = 'RECEIVE_AUTH_NEED_LIST_ERROR';

export const REQUEST_CLEAR_NEED_LIST = 'REQUEST_CLEAR_NEED_LIST';
export const REQUEST_CLEAR_NEED_LISTS = 'REQUEST_CLEAR_NEED_LISTS';
