export const REQUEST_CREATE_COMPANY = 'REQUEST_CREATE_COMPANY';
export const RECEIVE_CREATE_COMPANY = 'RECEIVE_CREATE_COMPANY';
export const RECEIVE_CREATE_COMPANY_ERROR = 'RECEIVE_CREATE_COMPANY_ERROR';

export const REQUEST_UPDATE_COMPANY = 'REQUEST_UPDATE_COMPANY';
export const RECEIVE_UPDATE_COMPANY = 'RECEIVE_UPDATE_COMPANY';
export const RECEIVE_UPDATE_COMPANY_ERROR = 'RECEIVE_UPDATE_COMPANY_ERROR';

export const REQUEST_UPDATE_COMPANY_TIMEZONE = 'REQUEST_UPDATE_COMPANY_TIMEZONE';
export const RECEIVE_UPDATE_COMPANY_TIMEZONE = 'RECEIVE_UPDATE_COMPANY_TIMEZONE';
export const RECEIVE_UPDATE_COMPANY_TIMEZONE_ERROR = 'RECEIVE_UPDATE_COMPANY_TIMEZONE_ERROR';

export const REQUEST_SET_DEFAULT_COMPANY = 'REQUEST_SET_DEFAULT_COMPANY';
export const RECEIVE_SET_DEFAULT_COMPANY = 'RECEIVE_SET_DEFAULT_COMPANY';
export const RECEIVE_SET_DEFAULT_COMPANY_ERROR = 'RECEIVE_SET_DEFAULT_COMPANY_ERROR';

export const REQUEST_GET_COMPANY = 'REQUEST_GET_COMPANY';
export const RECEIVE_GET_COMPANY = 'RECEIVE_GET_COMPANY';
export const RECEIVE_GET_COMPANY_ERROR = 'RECEIVE_GET_COMPANY_ERROR';

export const REQUEST_GET_COMPANIES = 'REQUEST_GET_COMPANIES';
export const RECEIVE_GET_COMPANIES = 'RECEIVE_GET_COMPANIES';
export const RECEIVE_GET_COMPANIES_ERROR = 'RECEIVE_GET_COMPANIES_ERROR';

export const REQUEST_CLEAR_COMPANY = 'REQUEST_CLEAR_COMPANY';
