import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import {
  RECEIVE_DELETE_LENDER_CALL_LOG_ERROR,
  RECEIVE_UPDATE_LENDER_CALL_LOG_ERROR,
  RECEIVE_CREATE_LENDER_CALL_LOG_ERROR,
  RECEIVE_DELETE_LENDER_CALL_LOG,
  RECEIVE_GET_LENDER_CALL_LOG,
  RECEIVE_GET_LENDER_CALL_LOG_ERROR,
  RECEIVE_GET_LENDER_CALL_LOGS,
  RECEIVE_GET_LENDER_CALL_LOGS_ERROR,
  RECEIVE_UPDATE_LENDER_CALL_LOG,
  REQUEST_CLEAR_LENDER_CALL_LOG,
  REQUEST_CLEAR_LENDER_CALL_LOGS,
  REQUEST_CREATE_LENDER_CALL_LOG,
  REQUEST_DELETE_LENDER_CALL_LOG,
  REQUEST_GET_LENDER_CALL_LOG,
  REQUEST_GET_LENDER_CALL_LOGS,
  REQUEST_UPDATE_LENDER_CALL_LOG,
  RECEIVE_CREATE_LENDER_CALL_LOG,
  REQUEST_REFRESH_LENDER_CALL_LOGS,
  RECEIVE_REFRESH_LENDER_CALL_LOGS,
  RECEIVE_REFRESH_LENDER_CALL_LOGS_ERROR,
} from '../constants/lender_call_logs';
import {
  RECEIVE_DELETE_LENDING_COMPANY,
  RECEIVE_UPDATE_LENDING_COMPANY,
} from '../constants/lending_companies';
import { RECEIVE_UPDATE_USER } from '../constants/users';
import { SORT_OPTIONS } from '../../utils/applicationConstants';
import {
  RECEIVE_CREATE_LENDER_LOAN_SUMMARY,
  RECEIVE_DELETE_LENDER_LOAN_SUMMARY,
} from '../constants/lender_loan_summaries';
import { RECEIVE_DELETE_LOAN_SUMMARY, RECEIVE_UPDATE_LOAN_SUMMARY } from '../constants/loan_summaries';

export const defaultLoanCallLogFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const updateLendingCompany = (lenderCallLogs, lendingCompany) => lenderCallLogs.map((val) => {
  if (val.lendingCompanyId === lendingCompany.lendingCompanyId) {
    return {
      ...val,
      lendingCompanyName: lendingCompany.name,
    };
  }

  return val;
});

const deleteLendingCompany = (lenderCallLogs, lendingCompany) => lenderCallLogs.map((val) => {
  if (val.lendingCompanyId === lendingCompany.lendingCompanyId) {
    return {
      ...val,
      lendingCompanyName: null,
      lendingCompanyId: null,
    };
  }

  return val;
});

const refreshLendersCallLog = (state, loanSummariesSent) => {
  if (!loanSummariesSent.length) return state;
  const lendersCallLogs = [...state];

  loanSummariesSent.forEach((item) => {
    const index = findIndex(lendersCallLogs, ['lenderId', item.lenderId]);
    const lenderCallLogItem = lendersCallLogs[index];
    lendersCallLogs[index] = {
      ...lenderCallLogItem,
      loanSummaryId: item.loanSummaryId,
      loanSummaryDetail: item.loanSummaryDetail,
      lenderLoanSummaryId: item.lenderLoanSummaryId,
      lenderLoanSummaryStatus: item.status,
      isChecked: false,
    };
  });
  return lendersCallLogs;
};

const updateLoanSummary = (state, loanSummary) => {
  const lendersCallLogs = [...state];

  const index = findIndex(lendersCallLogs, ['loanSummaryId', loanSummary.loanSummaryId]);
  const lenderCallLogItem = lendersCallLogs[index];

  lendersCallLogs[index] = {
    ...lenderCallLogItem,
    loanSummaryDetail: loanSummary.detail,
  };

  return lendersCallLogs;
};

const cleanLoanSummary = (state, loanSummaryId) => state.map((item) => {
  if (item.loanSummaryId === loanSummaryId) {
    return {
      ...item,
      loanSummaryId: null,
      lenderLoanSummaryId: null,
      lenderLoanSummaryStatus: null,
    };
  }
  return item;
});

const defaultState = {
  lenderCallLogs: [],
  lenderCallLog: {},
  loan: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isDeleting: false,
  isSaving: false,
  isRefreshing: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lenderCallLogs = (state = defaultState, action) => {
  let index;
  let newLenderCallLogs;
  switch (action.type) {
    case REQUEST_CREATE_LENDER_CALL_LOG:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDER_CALL_LOG:
      return {
        ...state,
        isSaving: false,
      };
    case REQUEST_UPDATE_LENDER_CALL_LOG:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LENDER_CALL_LOG:
      index = findIndex(state.lenderCallLogs, ['lenderCallLogId', action.lenderCallLog.lenderCallLogId]);
      if (index >= 0) {
        newLenderCallLogs = fill([...state.lenderCallLogs], action.lenderCallLog, index, index + 1);
      }
      return {
        ...state,
        lenderCallLog: action.lenderCallLog,
        lenderCallLogs: newLenderCallLogs || state.lenderCallLogs,
        isSaving: false,
      };
    case RECEIVE_UPDATE_USER:
      index = findIndex(state.lenderCallLogs, ['lenderId', action.user.companyUserId]);
      if (index >= 0) {
        newLenderCallLogs = fill(
          [...state.lenderCallLogs],
          {
            ...state.lenderCallLogs[index],
            lenderFullName: `${action.user.firstName} ${action.user.lastName}`,
            lendingCompanyId: action.user.lendingCompanyId,
            lendingCompanyName: action.user.lendingCompanyName,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        lenderCallLogs: newLenderCallLogs || state.lenderCallLogs,
      };
    case RECEIVE_UPDATE_LOAN_SUMMARY:
      return {
        ...state,
        lenderCallLogs: updateLoanSummary(state.lenderCallLogs, action.loanSummary),
      };
    case RECEIVE_CREATE_LENDER_LOAN_SUMMARY:
      return {
        ...state,
        lenderCallLogs: refreshLendersCallLog(state.lenderCallLogs, action.lenderLoanSummaries),
      };
    case RECEIVE_DELETE_LOAN_SUMMARY:
      return {
        ...state,
        lenderCallLogs: cleanLoanSummary(state.lenderCallLogs, action.loanSummary.loanSummaryId),
      };
    case RECEIVE_UPDATE_LENDING_COMPANY:
      return {
        ...state,
        lenderCallLogs: updateLendingCompany(state.lenderCallLogs, action.lendingCompany),
      };
    case RECEIVE_DELETE_LENDING_COMPANY:
      return {
        ...state,
        lenderCallLogs: deleteLendingCompany(state.lenderCallLogs, action.lendingCompany),
      };
    case RECEIVE_DELETE_LENDER_LOAN_SUMMARY:
      index = findIndex(state.lenderCallLogs, ['lenderId', action.lenderLoanSummary.lenderId]);
      if (index >= 0) {
        newLenderCallLogs = fill(
          [...state.lenderCallLogs],
          {
            ...state.lenderCallLogs[index],
            loanSummaryId: null,
            lenderLoanSummaryId: null,
            lenderLoanSummaryStatus: null,
            lenderLoanSummaryNotes: null,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        lenderCallLogs: newLenderCallLogs || state.lenderCallLogs,
      };
    case REQUEST_GET_LENDER_CALL_LOGS:
      return {
        ...state,
        lenderCallLogs: [],
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        isFetching: true,
      };
    case RECEIVE_GET_LENDER_CALL_LOGS:
      return {
        ...state,
        lenderCallLogs: action.lenderCallLogs.items,
        totalPages: action.lenderCallLogs.totalPages,
        totalItems: action.lenderCallLogs.totalItems,
        hasNextPage: action.lenderCallLogs.hasNextPage,
        hasPreviousPage: action.lenderCallLogs.hasPreviousPage,
        pageNumber: action.lenderCallLogs.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LENDER_CALL_LOG:
      return {
        ...state,
        lenderCallLog: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LENDER_CALL_LOG:
      return {
        ...state,
        lenderCallLog: action.lenderCallLog,
        isFetchingSingle: false,
      };
    case REQUEST_DELETE_LENDER_CALL_LOG:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LENDER_CALL_LOG:
      return {
        ...state,
        isDeleting: false,
      };
    case REQUEST_REFRESH_LENDER_CALL_LOGS:
      return {
        ...state,
        isRefreshing: true,
      };
    case RECEIVE_REFRESH_LENDER_CALL_LOGS:
      return {
        ...state,
        isRefreshing: false,
      };
    case REQUEST_CLEAR_LENDER_CALL_LOG:
      return {
        ...state,
        lenderCallLog: {},
      };
    case REQUEST_CLEAR_LENDER_CALL_LOGS:
      return {
        ...state,
        lenderCallLogs: [],
      };
    case RECEIVE_UPDATE_LENDER_CALL_LOG_ERROR:
    case RECEIVE_GET_LENDER_CALL_LOG_ERROR:
    case RECEIVE_CREATE_LENDER_CALL_LOG_ERROR:
    case RECEIVE_GET_LENDER_CALL_LOGS_ERROR:
    case RECEIVE_DELETE_LENDER_CALL_LOG_ERROR:
    case RECEIVE_REFRESH_LENDER_CALL_LOGS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
        isDeleting: false,
        isRefreshing: false,
      };
    default:
      return state;
  }
};
