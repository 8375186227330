import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import {
  RECEIVE_GET_LENDER_CALL_LOG_STATUS_ERROR,
  RECEIVE_CREATE_LENDER_CALL_LOG_STATUS_ERROR,
  RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS_ERROR,
  REQUEST_GET_LENDER_CALL_LOG_STATUSES,
  RECEIVE_GET_LENDER_CALL_LOG_STATUS,
  RECEIVE_CREATE_LENDER_CALL_LOG_STATUS,
  RECEIVE_GET_LENDER_CALL_LOG_STATUSES,
  REQUEST_GET_LENDER_CALL_LOG_STATUS,
  RECEIVE_GET_LENDER_CALL_LOG_STATUSES_ERROR,
  REQUEST_CLEAR_LENDER_CALL_LOG_STATUS,
  REQUEST_CREATE_LENDER_CALL_LOG_STATUS,
  REQUEST_UPDATE_LENDER_CALL_LOG_STATUS,
  RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS,
  RECEIVE_DELETE_LENDER_CALL_LOG_STATUS_ERROR,
  RECEIVE_DELETE_LENDER_CALL_LOG_STATUS,
  REQUEST_DELETE_LENDER_CALL_LOG_STATUS,
} from "../constants/lender_call_log_statuses";
import { SORT_OPTIONS } from "../../utils/applicationConstants";

export const defaultLenderCallLogStatusesFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: "",
};

const defaultState = {
  lenderCallLogStatuses: [],
  lenderCallLogStatus: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lenderCallLogStatuses = (state = defaultState, action) => {
  let index;
  let newLenderCallLogStatuses;

  switch (action.type) {
    case REQUEST_GET_LENDER_CALL_LOG_STATUSES:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDER_CALL_LOG_STATUSES:
      return {
        ...state,
        lenderCallLogStatuses: action.lenderCallLogStatuses,
        totalPages: action.lenderCallLogStatuses.totalPages,
        totalItems: action.lenderCallLogStatuses.totalItems,
        hasNextPage: action.lenderCallLogStatuses.hasNextPage,
        hasPreviousPage: action.lenderCallLogStatuses.hasPreviousPage,
        pageNumber: action.lenderCallLogStatuses.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        lenderCallLogStatus: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        lenderCallLogStatus: action.lenderCallLogStatus,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        lenderCallLogStatus: {},
      };
    case REQUEST_UPDATE_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS:
      index = findIndex(state.lenderCallLogStatuses, [
        "lenderCallLogStatusId",
        action.lenderCallLogStatus.lenderCallLogStatusId,
      ]);
      if (index >= 0) {
        newLenderCallLogStatuses = fill([...state.lenderCallLogStatuses], action.lenderCallLogStatus, index, index + 1);
      }
      return {
        ...state,
        lenderCallLogStatus: action.lenderCallLogStatus,
        lenderCallLogStatuses: index ? newLenderCallLogStatuses : state.lenderCallLogStatuses,
        isSaving: false,
      };
    case REQUEST_CREATE_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        lenderCallLogStatus: action.lenderCallLogStatus,
        isSaving: false,
      };
    case REQUEST_DELETE_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_LENDER_CALL_LOG_STATUS:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_LENDER_CALL_LOG_STATUS_ERROR:
    case RECEIVE_CREATE_LENDER_CALL_LOG_STATUS_ERROR:
    case RECEIVE_GET_LENDER_CALL_LOG_STATUS_ERROR:
    case RECEIVE_UPDATE_LENDER_CALL_LOG_STATUS_ERROR:
    case RECEIVE_GET_LENDER_CALL_LOG_STATUSES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isDeleting: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
