import { createSlice } from "@reduxjs/toolkit";

const initialState = { isProcessing: false };

const modalConfirmation = createSlice({
  name: "modalConfirmation",
  initialState,
  reducers: {
    setProcessingState: (state, action) => ({ ...state, isProcessing: action.payload }),
  },
});

export const { setProcessingState } = modalConfirmation.actions;
export default modalConfirmation.reducer;
