export const REQUEST_CREATE_LENDER_LOAN_SUMMARY = 'REQUEST_CREATE_LENDER_LOAN_SUMMARY';
export const RECEIVE_CREATE_LENDER_LOAN_SUMMARY = 'RECEIVE_CREATE_LENDER_LOAN_SUMMARY';
export const RECEIVE_CREATE_LENDER_LOAN_SUMMARY_ERROR = 'RECEIVE_CREATE_LENDER_LOAN_SUMMARY_ERROR';

export const REQUEST_UPDATE_LENDER_LOAN_SUMMARY = 'REQUEST_UPDATE_LENDER_LOAN_SUMMARY';
export const RECEIVE_UPDATE_LENDER_LOAN_SUMMARY = 'RECEIVE_UPDATE_LENDER_LOAN_SUMMARY';
export const RECEIVE_UPDATE_LENDER_LOAN_SUMMARY_ERROR = 'RECEIVE_UPDATE_LENDER_LOAN_SUMMARY_ERROR';

export const REQUEST_GET_LENDER_LOAN_SUMMARY = 'REQUEST_GET_LENDER_LOAN_SUMMARY';
export const RECEIVE_GET_LENDER_LOAN_SUMMARY = 'RECEIVE_GET_LENDER_LOAN_SUMMARY';
export const RECEIVE_GET_LENDER_LOAN_SUMMARY_ERROR = 'RECEIVE_GET_LENDER_LOAN_SUMMARY_ERROR';

export const REQUEST_RESEND_LENDER_LOAN_SUMMARY = 'REQUEST_RESEND_LENDER_LOAN_SUMMARY';
export const RECEIVE_RESEND_LENDER_LOAN_SUMMARY = 'RECEIVE_RESEND_LENDER_LOAN_SUMMARY';
export const RECEIVE_RESEND_LENDER_LOAN_SUMMARY_ERROR = 'RECEIVE_RESEND_LENDER_LOAN_SUMMARY_ERROR';

export const REQUEST_DELETE_LENDER_LOAN_SUMMARY = 'REQUEST_DELETE_LENDER_LOAN_SUMMARY';
export const RECEIVE_DELETE_LENDER_LOAN_SUMMARY = 'RECEIVE_DELETE_LENDER_LOAN_SUMMARY';
export const RECEIVE_DELETE_LENDER_LOAN_SUMMARY_ERROR = 'RECEIVE_DELETE_LENDER_LOAN_SUMMARY_ERROR';

export const REQUEST_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE = 'REQUEST_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE';
export const RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE = 'RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE';
export const RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE_ERROR = 'RECEIVE_GENERATE_LENDER_LOAN_SUMMARY_PACKAGE_ERROR';
