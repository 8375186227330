import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, identifyUserInPendo, sendAnalytics } from "../../utils/analytics";
import { lenderCallLogService } from "./lenderCallLogService";
import { setProcessingState } from "../reducers/modalConfirmation";

export const lenderSummaryService = createApi({
  reducerPath: "lenderSummaryApi",
  tagTypes: ["LenderSummary"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderSummary: builder.query({
      query: token => `lenders/${token}/summaries`,
      providesTags: (result, error, id) => [{ type: "LenderSummary", id }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { lenderLoanSummary: contact },
          } = await queryFulfilled;

          // identify the borrower in Pendo.io
          identifyUserInPendo({
            userId: contact.lenderId,
            email: contact.lenderEmail,
            fullName: contact.lenderFullName,
            companyId: contact.companyId,
            companyName: contact.companyName,
            employeeRoleId: null,
            isLender: true,
          });
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    updateLenderSummary: builder.mutation({
      query: ({ token, ...body }) => ({
        url: `lenders/${token}/summaries`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, { token }) => [{ type: "LenderSummary", token }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDER_LOAN_SUMMARY_RESPONDED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Response has been sent to the broker!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
          dispatch(lenderCallLogService.util.invalidateTags(["LenderCallLog"]));
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
  }),
});

export const { useGetLenderSummaryQuery, useUpdateLenderSummaryMutation } = lenderSummaryService;
