export const REQUEST_GET_LENDING_STATES = 'REQUEST_GET_LENDING_STATES';
export const RECEIVE_GET_LENDING_STATES = 'RECEIVE_GET_LENDING_STATES';
export const RECEIVE_GET_LENDING_STATES_ERROR = 'RECEIVE_GET_LENDING_STATES_ERROR';

export const REQUEST_CREATE_LENDING_STATE = 'REQUEST_CREATE_LENDING_STATE';
export const RECEIVE_CREATE_LENDING_STATE = 'RECEIVE_CREATE_LENDING_STATE';
export const RECEIVE_CREATE_LENDING_STATE_ERROR = 'RECEIVE_CREATE_LENDING_STATE_ERROR';

export const REQUEST_CREATE_ALL_LENDING_STATE = 'REQUEST_CREATE_ALL_LENDING_STATE';
export const RECEIVE_CREATE_ALL_LENDING_STATE = 'RECEIVE_CREATE_ALL_LENDING_STATE';
export const RECEIVE_CREATE_ALL_LENDING_STATE_ERROR = 'RECEIVE_CREATE_ALL_LENDING_STATE_ERROR';

export const REQUEST_DELETE_LENDING_STATE = 'REQUEST_DELETE_LENDING_STATE';
export const RECEIVE_DELETE_LENDING_STATE = 'RECEIVE_DELETE_LENDING_STATE';
export const RECEIVE_DELETE_LENDING_STATE_ERROR = 'RECEIVE_DELETE_LENDING_STATE_ERROR';

export const REQUEST_DELETE_ALL_LENDING_STATE = 'REQUEST_DELETE_ALL_LENDING_STATE';
export const RECEIVE_DELETE_ALL_LENDING_STATE = 'RECEIVE_DELETE_ALL_LENDING_STATE';
export const RECEIVE_DELETE_ALL_LENDING_STATE_ERROR = 'RECEIVE_DELETE_ALL_LENDING_STATE_ERROR';

export const REQUEST_GET_LENDING_LOAN_TYPES = 'REQUEST_GET_LENDING_LOAN_TYPES';
export const RECEIVE_GET_LENDING_LOAN_TYPES = 'RECEIVE_GET_LENDING_LOAN_TYPES';
export const RECEIVE_GET_LENDING_LOAN_TYPES_ERROR = 'RECEIVE_GET_LENDING_LOAN_TYPES_ERROR';

export const REQUEST_CREATE_LENDING_LOAN_TYPE = 'REQUEST_CREATE_LENDING_LOAN_TYPE';
export const RECEIVE_CREATE_LENDING_LOAN_TYPE = 'RECEIVE_CREATE_LENDING_LOAN_TYPE';
export const RECEIVE_CREATE_LENDING_LOAN_TYPE_ERROR = 'RECEIVE_CREATE_LENDING_LOAN_TYPE_ERROR';

export const REQUEST_CREATE_ALL_LENDING_LOAN_TYPE = 'REQUEST_CREATE_ALL_LENDING_LOAN_TYPE';
export const RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE = 'RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE';
export const RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE_ERROR = 'RECEIVE_CREATE_ALL_LENDING_LOAN_TYPE_ERROR';

export const REQUEST_DELETE_LENDING_LOAN_TYPE = 'REQUEST_DELETE_LENDING_LOAN_TYPE';
export const RECEIVE_DELETE_LENDING_LOAN_TYPE = 'RECEIVE_DELETE_LENDING_LOAN_TYPE';
export const RECEIVE_DELETE_LENDING_LOAN_TYPE_ERROR = 'RECEIVE_DELETE_LENDING_LOAN_TYPE_ERROR';

export const REQUEST_DELETE_ALL_LENDING_LOAN_TYPE = 'REQUEST_DELETE_ALL_LENDING_LOAN_TYPE';
export const RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE = 'RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE';
export const RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE_ERROR = 'RECEIVE_DELETE_ALL_LENDING_LOAN_TYPE_ERROR';

export const REQUEST_GET_LENDING_PROPERTY_TYPES = 'REQUEST_GET_LENDING_PROPERTY_TYPES';
export const RECEIVE_GET_LENDING_PROPERTY_TYPES = 'RECEIVE_GET_LENDING_PROPERTY_TYPES';
export const RECEIVE_GET_LENDING_PROPERTY_TYPES_ERROR = 'RECEIVE_GET_LENDING_PROPERTY_TYPES_ERROR';

export const REQUEST_CREATE_LENDING_PROPERTY_TYPE = 'REQUEST_CREATE_LENDING_PROPERTY_TYPE';
export const RECEIVE_CREATE_LENDING_PROPERTY_TYPE = 'RECEIVE_CREATE_LENDING_PROPERTY_TYPE';
export const RECEIVE_CREATE_LENDING_PROPERTY_TYPE_ERROR = 'RECEIVE_CREATE_LENDING_PROPERTY_TYPE_ERROR';

export const REQUEST_CREATE_ALL_LENDING_PROPERTY_TYPE = 'REQUEST_CREATE_ALL_LENDING_PROPERTY_TYPE';
export const RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE = 'RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE';
export const RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE_ERROR = 'RECEIVE_CREATE_ALL_LENDING_PROPERTY_TYPE_ERROR';

export const REQUEST_DELETE_LENDING_PROPERTY_TYPE = 'REQUEST_DELETE_LENDING_PROPERTY_TYPE';
export const RECEIVE_DELETE_LENDING_PROPERTY_TYPE = 'RECEIVE_DELETE_LENDING_PROPERTY_TYPE';
export const RECEIVE_DELETE_LENDING_PROPERTY_TYPE_ERROR = 'RECEIVE_DELETE_LENDING_PROPERTY_TYPE_ERROR';

export const REQUEST_DELETE_ALL_LENDING_PROPERTY_TYPE = 'REQUEST_DELETE_ALL_LENDING_PROPERTY_TYPE';
export const RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE = 'RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE';
export const RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE_ERROR = 'RECEIVE_DELETE_ALL_LENDING_PROPERTY_TYPE_ERROR';

export const REQUEST_CLEAR_LENDING_STATES = 'REQUEST_CLEAR_LENDING_STATES';
export const REQUEST_CLEAR_LENDING_LOAN_TYPES = 'REQUEST_CLEAR_LENDING_LOAN_TYPES';
export const REQUEST_CLEAR_LENDING_PROPERTY_TYPES = 'REQUEST_CLEAR_LENDING_PROPERTY_TYPES';
