export const REQUEST_CREATE_EMPLOYEE = 'REQUEST_CREATE_EMPLOYEE';
export const RECEIVE_CREATE_EMPLOYEE = 'RECEIVE_CREATE_EMPLOYEE';
export const RECEIVE_CREATE_EMPLOYEE_ERROR = 'RECEIVE_CREATE_EMPLOYEE_ERROR';

export const REQUEST_UPDATE_EMPLOYEE = 'REQUEST_UPDATE_EMPLOYEE';
export const RECEIVE_UPDATE_EMPLOYEE = 'RECEIVE_UPDATE_EMPLOYEE';
export const RECEIVE_UPDATE_EMPLOYEE_ERROR = 'RECEIVE_UPDATE_EMPLOYEE_ERROR';

export const REQUEST_SEND_EMPLOYEE_INVITATION = 'REQUEST_SEND_EMPLOYEE_INVITATION';
export const RECEIVE_SEND_EMPLOYEE_INVITATION = 'RECEIVE_SEND_EMPLOYEE_INVITATION';
export const RECEIVE_SEND_EMPLOYEE_INVITATION_ERROR = 'RECEIVE_SEND_EMPLOYEE_INVITATION_ERROR';

export const REQUEST_GET_EMPLOYEE = 'REQUEST_GET_EMPLOYEE';
export const RECEIVE_GET_EMPLOYEE = 'RECEIVE_GET_EMPLOYEE';
export const RECEIVE_GET_EMPLOYEE_ERROR = 'RECEIVE_GET_EMPLOYEE_ERROR';

export const REQUEST_GET_EMPLOYEES = 'REQUEST_GET_EMPLOYEES';
export const RECEIVE_GET_EMPLOYEES = 'RECEIVE_GET_EMPLOYEES';
export const RECEIVE_GET_EMPLOYEES_ERROR = 'RECEIVE_GET_EMPLOYEES_ERROR';

export const REQUEST_CLEAR_EMPLOYEE = 'REQUEST_CLEAR_EMPLOYEE';
export const REQUEST_CLEAR_EMPLOYEES = 'REQUEST_CLEAR_EMPLOYEES';
