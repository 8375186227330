export const REQUEST_GET_PROPERTY_TYPES = 'REQUEST_GET_PROPERTY_TYPES';
export const RECEIVE_GET_PROPERTY_TYPES = 'RECEIVE_GET_PROPERTY_TYPES';
export const RECEIVE_GET_PROPERTY_TYPES_ERROR = 'RECEIVE_GET_PROPERTY_TYPES_ERROR';

export const REQUEST_GET_PROPERTY_TYPE = 'REQUEST_GET_PROPERTY_TYPE';
export const RECEIVE_GET_PROPERTY_TYPE = 'RECEIVE_GET_PROPERTY_TYPE';
export const RECEIVE_GET_PROPERTY_TYPE_ERROR = 'RECEIVE_GET_PROPERTY_TYPE_ERROR';

export const REQUEST_UPDATE_PROPERTY_TYPE = 'REQUEST_UPDATE_PROPERTY_TYPE';
export const RECEIVE_UPDATE_PROPERTY_TYPE = 'RECEIVE_UPDATE_PROPERTY_TYPE';
export const RECEIVE_UPDATE_PROPERTY_TYPE_ERROR = 'RECEIVE_UPDATE_PROPERTY_TYPE_ERROR';

export const REQUEST_DELETE_PROPERTY_TYPE = 'REQUEST_DELETE_PROPERTY_TYPE';
export const RECEIVE_DELETE_PROPERTY_TYPE = 'RECEIVE_DELETE_PROPERTY_TYPE';
export const RECEIVE_DELETE_PROPERTY_TYPE_ERROR = 'RECEIVE_DELETE_PROPERTY_TYPE_ERROR';

export const REQUEST_CREATE_PROPERTY_TYPE = 'REQUEST_CREATE_PROPERTY_TYPE';
export const RECEIVE_CREATE_PROPERTY_TYPE = 'RECEIVE_CREATE_PROPERTY_TYPE';
export const RECEIVE_CREATE_PROPERTY_TYPE_ERROR = 'RECEIVE_CREATE_PROPERTY_TYPE_ERROR';

export const REQUEST_CLEAR_PROPERTY_TYPE = 'REQUEST_CLEAR_PROPERTY_TYPE';
