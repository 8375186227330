import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "../utils/httpRequest";

export function fetchLoanTypes(companyId, params) {
  return fetchWithHeaders(`/parameters/loan-types?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchLoanType(companyId, path) {
  return fetchWithHeaders(`/loan-types/${path.loanTypeId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updateLoanType(companyId, path, payload = {}) {
  return fetchWithHeaders(`/loan-types/${path.loanTypeId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createLoanType(companyId, payload = {}) {
  return fetchWithHeaders("/loan-types", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deleteLoanType(companyId, path) {
  return fetchWithHeaders(`/loan-types/${path.loanTypeId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
