export const REQUEST_GET_LOAN_SUMMARY_NEEDS_LIST_FILES = 'REQUEST_GET_LOAN_SUMMARY_NEEDS_LIST_FILES';
export const RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES = 'RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES';
export const RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES_ERROR = 'RECEIVE_GET_LOAN_SUMMARY_NEEDS_LIST_FILES_ERROR';

export const REQUEST_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE = 'REQUEST_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE = 'RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR = 'RECEIVE_DELETE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR';

export const REQUEST_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE = 'REQUEST_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE = 'RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR = 'RECEIVE_CREATE_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR';

export const REQUEST_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE = 'REQUEST_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE = 'RECEIVE_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE';
export const RECEIVE_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR = 'RECEIVE_DOWNLOAD_LOAN_SUMMARY_NEEDS_LIST_FILE_ERROR';

export const REQUEST_CLEAR_LOAN_SUMMARY_NEEDS_LIST_FILES = 'REQUEST_CLEAR_LOAN_SUMMARY_NEEDS_LIST_FILES';
