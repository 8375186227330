import { RECEIVE_GET_EMPLOYEE_ROLES } from '../constants/parameters';

const defaultState = {
  employeeRoles: [],
  isFetching: false,
};

export const employeeRoles = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_GET_EMPLOYEE_ROLES:
      return {
        ...state,
        employeeRoles: action.employeeRoles,
        isFetching: false,
      };
    default:
      return state;
  }
};
