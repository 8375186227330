export const REQUEST_CREATE_LOAN_SUMMARY = 'REQUEST_CREATE_LOAN_SUMMARY';
export const RECEIVE_CREATE_LOAN_SUMMARY = 'RECEIVE_CREATE_LOAN_SUMMARY';
export const RECEIVE_CREATE_LOAN_SUMMARY_ERROR = 'RECEIVE_CREATE_LOAN_SUMMARY_ERROR';

export const REQUEST_UPDATE_LOAN_SUMMARY = 'REQUEST_UPDATE_LOAN_SUMMARY';
export const RECEIVE_UPDATE_LOAN_SUMMARY = 'RECEIVE_UPDATE_LOAN_SUMMARY';
export const RECEIVE_UPDATE_LOAN_SUMMARY_ERROR = 'RECEIVE_UPDATE_LOAN_SUMMARY_ERROR';

export const REQUEST_GET_LOAN_SUMMARY = 'REQUEST_GET_LOAN_SUMMARY';
export const RECEIVE_GET_LOAN_SUMMARY = 'RECEIVE_GET_LOAN_SUMMARY';
export const RECEIVE_GET_LOAN_SUMMARY_ERROR = 'RECEIVE_GET_LOAN_SUMMARY_ERROR';

export const REQUEST_GET_LOAN_SUMMARIES = 'REQUEST_GET_LOAN_SUMMARIES';
export const RECEIVE_GET_LOAN_SUMMARIES = 'RECEIVE_GET_LOAN_SUMMARIES';
export const RECEIVE_GET_LOAN_SUMMARIES_ERROR = 'RECEIVE_GET_LOAN_SUMMARIES_ERROR';

export const REQUEST_DELETE_LOAN_SUMMARY = 'REQUEST_DELETE_LOAN_SUMMARY';
export const RECEIVE_DELETE_LOAN_SUMMARY = 'RECEIVE_DELETE_LOAN_SUMMARY';
export const RECEIVE_DELETE_LOAN_SUMMARY_ERROR = 'RECEIVE_DELETE_LOAN_SUMMARY_ERROR';

export const REQUEST_CREATE_LOAN_SUMMARY_DRAFT = 'REQUEST_CREATE_LOAN_SUMMARY_DRAFT';
export const RECEIVE_CREATE_LOAN_SUMMARY_DRAFT = 'RECEIVE_CREATE_LOAN_SUMMARY_DRAFT';
export const RECEIVE_CREATE_LOAN_SUMMARY_DRAFT_ERROR = 'RECEIVE_CREATE_LOAN_SUMMARY_DRAFT_ERROR';

export const REQUEST_GET_LOAN_SUMMARY_DRAFT = 'REQUEST_GET_LOAN_SUMMARY_DRAFT';
export const RECEIVE_GET_LOAN_SUMMARY_DRAFT = 'RECEIVE_GET_LOAN_SUMMARY_DRAFT';
export const RECEIVE_GET_LOAN_SUMMARY_DRAFT_ERROR = 'RECEIVE_GET_LOAN_SUMMARY_DRAFT_ERROR';

export const REQUEST_CLEAR_LOAN_SUMMARY = 'REQUEST_CLEAR_LOAN_SUMMARY';
export const REQUEST_CLEAR_LOAN_SUMMARIES = 'REQUEST_CLEAR_LOAN_SUMMARIES';
