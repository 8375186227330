export const REQUEST_GET_LENDING_COMPANIES = 'REQUEST_GET_LENDING_COMPANIES';
export const RECEIVE_GET_LENDING_COMPANIES = 'RECEIVE_GET_LENDING_COMPANIES';
export const RECEIVE_GET_LENDING_COMPANIES_ERROR = 'RECEIVE_GET_LENDING_COMPANIES_ERROR';

export const REQUEST_GET_LENDING_COMPANIES_LOOKUP = 'REQUEST_GET_LENDING_COMPANIES_LOOKUP';
export const RECEIVE_GET_LENDING_COMPANIES_LOOKUP = 'RECEIVE_GET_LENDING_COMPANIES_LOOKUP';
export const RECEIVE_GET_LENDING_COMPANIES_LOOKUP_ERROR = 'RECEIVE_GET_LENDING_COMPANIES_LOOKUP_ERROR';

export const REQUEST_GET_LENDING_COMPANY = 'REQUEST_GET_LENDING_COMPANY';
export const RECEIVE_GET_LENDING_COMPANY = 'RECEIVE_GET_LENDING_COMPANY';
export const RECEIVE_GET_LENDING_COMPANY_ERROR = 'RECEIVE_GET_LENDING_COMPANY_ERROR';

export const REQUEST_UPDATE_LENDING_COMPANY = 'REQUEST_UPDATE_LENDING_COMPANY';
export const RECEIVE_UPDATE_LENDING_COMPANY = 'RECEIVE_UPDATE_LENDING_COMPANY';
export const RECEIVE_UPDATE_LENDING_COMPANY_ERROR = 'RECEIVE_UPDATE_LENDING_COMPANY_ERROR';

export const REQUEST_CREATE_LENDING_COMPANY = 'REQUEST_CREATE_LENDING_COMPANY';
export const RECEIVE_CREATE_LENDING_COMPANY = 'RECEIVE_CREATE_LENDING_COMPANY';
export const RECEIVE_CREATE_LENDING_COMPANY_ERROR = 'RECEIVE_CREATE_LENDING_COMPANY_ERROR';

export const REQUEST_DELETE_LENDING_COMPANY = 'REQUEST_DELETE_LENDING_COMPANY';
export const RECEIVE_DELETE_LENDING_COMPANY = 'RECEIVE_DELETE_LENDING_COMPANY';
export const RECEIVE_DELETE_LENDING_COMPANY_ERROR = 'RECEIVE_DELETE_LENDING_COMPANY_ERROR';

export const REQUEST_GET_LENDING_COMPANY_FILTERS = 'REQUEST_GET_LENDING_COMPANY_FILTERS';
export const RECEIVE_GET_LENDING_COMPANY_FILTERS = 'RECEIVE_GET_LENDING_COMPANY_FILTERS';
export const RECEIVE_GET_LENDING_COMPANY_FILTERS_ERROR = 'RECEIVE_GET_LENDING_COMPANY_FILTERS_ERROR';

export const REQUEST_CLEAR_LENDING_COMPANY = 'REQUEST_CLEAR_LENDING_COMPANY';
