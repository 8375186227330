import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics, sendCollectionToAnalytics } from "../../utils/analytics";
import { setProcessingState } from "../reducers/modalConfirmation";

export const lenderCallLogService = createApi({
  reducerPath: "lenderCallLogApi",
  tagTypes: ["LenderCallLog"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLenderCallLogs: builder.query({
      query: ({ loanId, ...params }) => `loans/${loanId}/lender-call-logs?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ lenderCallLogId }) => ({ type: "LenderCallLog", lenderCallLogId })),
              { type: "LenderCallLog", id: "LIST" },
            ]
          : [{ type: "LenderCallLog", id: "LIST" }],
    }),
    addLenderCallLog: builder.mutation({
      query: ({ loanId, ...body }) => ({
        url: `loans/${loanId}/lender-call-logs`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "LenderCallLog", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.length) {
            sendCollectionToAnalytics(ANALYTIC_EVENTS.LOAN_LENDER_CALL_LOG_ITEM_CREATED, data);
            dispatch(
              showNotification({
                ...DEFAULT_NOTIFICATION_PAYLOAD,
                message: {
                  ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                  description: `${data.length} added to the call log!`,
                  options: {
                    variant: NOTIFICATION_VARIANTS.SUCCESS,
                  },
                },
              }),
            );
          } else {
            dispatch(
              showNotification({
                ...DEFAULT_NOTIFICATION_PAYLOAD,
                message: {
                  ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                  description: "Lender(s) already in the call log!",
                  options: {
                    variant: NOTIFICATION_VARIANTS.SUCCESS,
                  },
                },
              }),
            );
          }
        } catch (err) {
          /* empty */
        }
      },
    }),
    patchLenderCallLog: builder.mutation({
      query: ({ loanId, ...patch }) => ({
        url: `loans/${loanId}/lender-call-logs/${patch.lenderCallLogId}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "LenderCallLog", id }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_LENDER_CALL_LOG_ITEM_UPDATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "Call log has been updated!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLenderCallLog: builder.mutation({
      query({ loanId, lenderCallLogId }) {
        return {
          url: `loans/${loanId}/lender-call-logs/${lenderCallLogId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LenderCallLog", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          dispatch(setProcessingState(true));
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_LENDER_CALL_LOG_ITEM_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.lenderFullName} has been deleted from call log!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          dispatch(setProcessingState(false));
        }
      },
    }),
    refreshLenderCallLog: builder.mutation({
      query(id) {
        return {
          url: `loans/${id}/lender-call-logs/smart-sort`,
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "LenderCallLog", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.length) {
            sendCollectionToAnalytics(ANALYTIC_EVENTS.LOAN_CALL_LOG_SMART_SORT, data);
            dispatch(
              showNotification({
                ...DEFAULT_NOTIFICATION_PAYLOAD,
                message: {
                  ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                  description: `${data.length} lender(s) added to the call log!`,
                  options: {
                    variant: NOTIFICATION_VARIANTS.SUCCESS,
                  },
                },
              }),
            );
          } else {
            dispatch(
              showNotification({
                ...DEFAULT_NOTIFICATION_PAYLOAD,
                message: {
                  ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                  description: "No more lenders found based on the loan parameters!",
                  options: {
                    variant: NOTIFICATION_VARIANTS.SUCCESS,
                  },
                },
              }),
            );
          }
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLenderCallLogsQuery,
  useAddLenderCallLogMutation,
  usePatchLenderCallLogMutation,
  useDeleteLenderCallLogMutation,
  useRefreshLenderCallLogMutation,
} = lenderCallLogService;
