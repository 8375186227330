import {
  RECEIVE_GET_NOTIFICATION_SCHEDULES,
  RECEIVE_GET_NOTIFICATION_SCHEDULES_ERROR,
  RECEIVE_GET_NOTIFICATIONS,
  RECEIVE_GET_NOTIFICATIONS_ERROR,
  RECEIVE_SET_UP_NOTIFICATION_SCHEDULE,
  RECEIVE_SET_UP_NOTIFICATION_SCHEDULE_ERROR,
  REQUEST_CLEAR_NOTIFICATION_SCHEDULES,
  REQUEST_GET_NOTIFICATION_SCHEDULES,
  REQUEST_GET_NOTIFICATIONS,
  REQUEST_SET_UP_NOTIFICATION_SCHEDULE,
} from '../constants/notifications';

const defaultState = {
  notificationSchedules: [],
  notifications: {},
  error: null,
  isFetchingNotifications: false,
  isFetchingNotificationSchedules: false,
  isSaving: false,
};

export const notifications = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_NOTIFICATIONS:
      return {
        ...state,
        isFetchingNotifications: true,
      };
    case RECEIVE_GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        isFetchingNotifications: false,
      };
    case REQUEST_GET_NOTIFICATION_SCHEDULES:
      return {
        ...state,
        notificationSchedules: [],
        isFetchingNotificationSchedules: true,
      };
    case RECEIVE_GET_NOTIFICATION_SCHEDULES:
      return {
        ...state,
        notificationSchedules: action.notificationSchedules,
        isFetchingNotificationSchedules: false,
      };
    case REQUEST_SET_UP_NOTIFICATION_SCHEDULE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_SET_UP_NOTIFICATION_SCHEDULE:
      return {
        ...state,
        isSaving: false,
      };
    case REQUEST_CLEAR_NOTIFICATION_SCHEDULES:
      return {
        ...state,
        notificationSchedules: [],
      };
    case RECEIVE_GET_NOTIFICATIONS_ERROR:
    case RECEIVE_GET_NOTIFICATION_SCHEDULES_ERROR:
    case RECEIVE_SET_UP_NOTIFICATION_SCHEDULE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetchingNotifications: false,
        isFetchingNotificationSchedules: false,
        isSaving: false,
      };
    default:
      return state;
  }
};
