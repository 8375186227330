export const REQUEST_GET_LOAN_PROGRAMS = 'REQUEST_GET_LOAN_PROGRAMS';
export const RECEIVE_GET_LOAN_PROGRAMS = 'RECEIVE_GET_LOAN_PROGRAMS';
export const RECEIVE_GET_LOAN_PROGRAMS_ERROR = 'RECEIVE_GET_LOAN_PROGRAMS_ERROR';

export const REQUEST_GET_COUNTRIES = 'REQUEST_GET_COUNTRIES';
export const RECEIVE_GET_COUNTRIES = 'RECEIVE_GET_COUNTRIES';
export const RECEIVE_GET_COUNTRIES_ERROR = 'RECEIVE_GET_COUNTRIES_ERROR';

export const REQUEST_GET_STATES = 'REQUEST_GET_STATES';
export const RECEIVE_GET_STATES = 'RECEIVE_GET_STATES';
export const RECEIVE_GET_STATES_ERROR = 'RECEIVE_GET_STATES_ERROR';

export const REQUEST_GET_CURRENCIES = 'REQUEST_GET_CURRENCIES';
export const RECEIVE_GET_CURRENCIES = 'RECEIVE_GET_CURRENCIES';
export const RECEIVE_GET_CURRENCIES_ERROR = 'RECEIVE_GET_CURRENCIES_ERROR';

export const REQUEST_GET_TIME_ZONES = 'REQUEST_GET_TIME_ZONES';
export const RECEIVE_GET_TIME_ZONES = 'RECEIVE_GET_TIME_ZONES';
export const RECEIVE_GET_TIME_ZONES_ERROR = 'RECEIVE_GET_TIME_ZONES_ERROR';

export const REQUEST_SET_TIME_ZONES = 'REQUEST_SET_TIME_ZONES';

export const RECEIVE_GET_GUARANTEES = 'RECEIVE_GET_GUARANTEES';

export const RECEIVE_GET_EMPLOYEE_ROLES = 'RECEIVE_GET_EMPLOYEE_ROLES';
