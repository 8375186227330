import React from "react";
import Image from "next/image";
import PropTypes from "prop-types";

export function ProfilePicture({ pictureUrl }) {
  return (
    <Image
      className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
      src={pictureUrl}
      width="40"
      height="40"
      alt="Profile Pic"
    />
  );
}

ProfilePicture.propTypes = {
  pictureUrl: PropTypes.string.isRequired,
};
