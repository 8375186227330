import {
  REQUEST_CLOSE_MODAL,
  REQUEST_OPEN_MODAL,
} from '../constants/modal';

export function requestOpenModal(fragment, options) {
  return {
    type: REQUEST_OPEN_MODAL,
    fragment,
    options,
  };
}

export function requestCloseModal() {
  return {
    type: REQUEST_CLOSE_MODAL,
  };
}
