import { fetchWithHeaders, getQueryParams, HTTP_METHODS, parseErrorResponse, responseData } from "../utils/httpRequest";

export function fetchLoanStatuses(companyId, params) {
  return fetchWithHeaders(`/parameters/loan-statuses?${getQueryParams(params)}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function fetchLoanStatus(companyId, path) {
  return fetchWithHeaders(`/loan-statuses/${path.loanStatusId}`, {
    method: HTTP_METHODS.GET,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function updateLoanStatus(companyId, path, payload = {}) {
  return fetchWithHeaders(`/loan-statuses/${path.loanStatusId}`, {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function createLoanStatus(companyId, payload = {}) {
  return fetchWithHeaders("/loan-statuses", {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(payload),
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}

export function deleteLoanStatus(companyId, path) {
  return fetchWithHeaders(`/loan-statuses/${path.loanStatusId}`, {
    method: HTTP_METHODS.DELETE,
    companyId,
  })
    .then(responseData)
    .catch(parseErrorResponse);
}
