import {
  REQUEST_OPEN_SLIDE_OVER,
  REQUEST_CLOSE_SLIDE_OVER,
} from '../constants/slide_over';

const defaultState = {
  isOpen: false,
  fragment: null,
  options: {},
};

export const slideOver = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_OPEN_SLIDE_OVER:
      return {
        ...state,
        isOpen: true,
        fragment: action.fragment,
        options: action.options,
      };
    case REQUEST_CLOSE_SLIDE_OVER:
      return {
        ...state,
        fragment: null,
        isOpen: false,
        options: {},
      };
    default:
      return state;
  }
};
