import {
  RECEIVE_GET_BORROWERS_ERROR,
  REQUEST_GET_BORROWERS,
  RECEIVE_GET_BORROWERS,
} from '../constants/borrowers';

const defaultState = {
  borrowers: [],
  error: null,
  isFetching: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const borrowers = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_BORROWERS:
      return {
        ...state,
        borrowers: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_BORROWERS:
      return {
        ...state,
        borrowers: action.borrowers.items,
        totalPages: action.borrowers.totalPages,
        totalItems: action.borrowers.totalItems,
        hasNextPage: action.borrowers.hasNextPage,
        hasPreviousPage: action.borrowers.hasPreviousPage,
        pageNumber: action.borrowers.pageNumber,
        isFetching: false,
      };
    case RECEIVE_GET_BORROWERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
