import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.sass';

export function Loading({
  isFullHeight = true,
  spinnerSize = 32,
  pathSize = 8,
  padding = 8,
  transparent = true,
  progress = 0,
  showProgress = false,
}) {
  return (
    <div className={classNames(
      `flex justify-center items-center p-${padding}`,
      { 'h-screen': isFullHeight },
      { 'bg-transparent': transparent },
      { 'dark:bg-slate-900': !transparent },
    )}
    >
      <div
        className={classNames(
          styles.loader,
          `ease-linear rounded-full border-${pathSize} border-t-${pathSize}`,
          `border-gray-100 h-${spinnerSize} w-${spinnerSize}`,
          { 'w-32 h-32': !spinnerSize },
          { 'border-8 border-t-8': !spinnerSize },
        )}
      />
      {showProgress && (
        <span className="absolute">
          {progress}
          %
        </span>
      )}
    </div>
  );
}

Loading.propTypes = {
  isFullHeight: PropTypes.bool,
  spinnerSize: PropTypes.number,
  pathSize: PropTypes.number,
  padding: PropTypes.number,
  transparent: PropTypes.bool,
  progress: PropTypes.number,
  showProgress: PropTypes.bool,
};
