export const REQUEST_GET_PROPERTY_USES = 'REQUEST_GET_PROPERTY_USES';
export const RECEIVE_GET_PROPERTY_USES = 'RECEIVE_GET_PROPERTY_USES';
export const RECEIVE_GET_PROPERTY_USES_ERROR = 'RECEIVE_GET_PROPERTY_USES_ERROR';

export const REQUEST_GET_PROPERTY_USE = 'REQUEST_GET_PROPERTY_USE';
export const RECEIVE_GET_PROPERTY_USE = 'RECEIVE_GET_PROPERTY_USE';
export const RECEIVE_GET_PROPERTY_USE_ERROR = 'RECEIVE_GET_PROPERTY_USE_ERROR';

export const REQUEST_UPDATE_PROPERTY_USE = 'REQUEST_UPDATE_PROPERTY_USE';
export const RECEIVE_UPDATE_PROPERTY_USE = 'RECEIVE_UPDATE_PROPERTY_USE';
export const RECEIVE_UPDATE_PROPERTY_USE_ERROR = 'RECEIVE_UPDATE_PROPERTY_USE_ERROR';

export const REQUEST_DELETE_PROPERTY_USE = 'REQUEST_DELETE_PROPERTY_USE';
export const RECEIVE_DELETE_PROPERTY_USE = 'RECEIVE_DELETE_PROPERTY_USE';
export const RECEIVE_DELETE_PROPERTY_USE_ERROR = 'RECEIVE_DELETE_PROPERTY_USE_ERROR';

export const REQUEST_CREATE_PROPERTY_USE = 'REQUEST_CREATE_PROPERTY_USE';
export const RECEIVE_CREATE_PROPERTY_USE = 'RECEIVE_CREATE_PROPERTY_USE';
export const RECEIVE_CREATE_PROPERTY_USE_ERROR = 'RECEIVE_CREATE_PROPERTY_USE_ERROR';

export const REQUEST_CLEAR_PROPERTY_USE = 'REQUEST_CLEAR_PROPERTY_USE';
