import findIndex from "lodash/findIndex";
import fill from "lodash/fill";
import {
  RECEIVE_GET_VALUES_BASED_ON,
  REQUEST_GET_VALUES_BASED_ON,
  RECEIVE_GET_VALUES_BASED_ON_ERROR,
  RECEIVE_GET_VALUE_BASED_ON,
  RECEIVE_GET_VALUE_BASED_ON_ERROR,
  REQUEST_GET_VALUE_BASED_ON,
  RECEIVE_CREATE_VALUE_BASED_ON,
  RECEIVE_CREATE_VALUE_BASED_ON_ERROR,
  RECEIVE_UPDATE_VALUE_BASED_ON,
  RECEIVE_UPDATE_VALUE_BASED_ON_ERROR,
  REQUEST_CREATE_VALUE_BASED_ON,
  REQUEST_UPDATE_VALUE_BASED_ON,
  REQUEST_CLEAR_VALUE_BASED_ON,
  RECEIVE_DELETE_VALUE_BASED_ON_ERROR,
  RECEIVE_DELETE_VALUE_BASED_ON,
  REQUEST_DELETE_VALUE_BASED_ON,
} from "../constants/values_based_on";
import { SORT_OPTIONS } from "../../utils/applicationConstants";

export const defaultValuesBasedOnFilters = {
  page: 1,
  size: 30,
  sortBy: SORT_OPTIONS[0].id,
  searchTerm: "",
};

const defaultState = {
  valuesBasedOn: [],
  valueBasedOn: {},
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const valuesBasedOn = (state = defaultState, action) => {
  let index;
  let newValuesBasedOn;

  switch (action.type) {
    case REQUEST_GET_VALUES_BASED_ON:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_VALUES_BASED_ON:
      return {
        ...state,
        valuesBasedOn: action.valuesBasedOn,
        totalPages: action.valuesBasedOn.totalPages,
        totalItems: action.valuesBasedOn.totalItems,
        hasNextPage: action.valuesBasedOn.hasNextPage,
        hasPreviousPage: action.valuesBasedOn.hasPreviousPage,
        pageNumber: action.valuesBasedOn.pageNumber,
        isFetching: false,
      };
    case REQUEST_GET_VALUE_BASED_ON:
      return {
        ...state,
        valueBasedOn: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_VALUE_BASED_ON:
      return {
        ...state,
        valueBasedOn: action.valueBasedOn,
        isFetchingSingle: false,
      };
    case REQUEST_CLEAR_VALUE_BASED_ON:
      return {
        ...state,
        valueBasedOn: {},
      };
    case REQUEST_UPDATE_VALUE_BASED_ON:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_VALUE_BASED_ON:
      index = findIndex(state.valuesBasedOn, ["valueBasedOnId", action.valueBasedOn.valueBasedOnId]);
      if (index >= 0) {
        newValuesBasedOn = fill([...state.valuesBasedOn], action.valueBasedOn, index, index + 1);
      }
      return {
        ...state,
        valueBasedOn: action.valueBasedOn,
        valuesBasedOn: newValuesBasedOn || state.valuesBasedOn,
        isSaving: false,
      };
    case REQUEST_CREATE_VALUE_BASED_ON:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_VALUE_BASED_ON:
      return {
        ...state,
        valueBasedOn: action.valueBasedOn,
        isSaving: false,
      };
    case REQUEST_DELETE_VALUE_BASED_ON:
      return {
        ...state,
        isDeleting: true,
      };
    case RECEIVE_DELETE_VALUE_BASED_ON:
      return {
        ...state,
        isDeleting: false,
      };
    case RECEIVE_DELETE_VALUE_BASED_ON_ERROR:
    case RECEIVE_CREATE_VALUE_BASED_ON_ERROR:
    case RECEIVE_UPDATE_VALUE_BASED_ON_ERROR:
    case RECEIVE_GET_VALUE_BASED_ON_ERROR:
    case RECEIVE_GET_VALUES_BASED_ON_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
