import {
  REQUEST_GET_LENDING_COMPANIES_LOOKUP,
  RECEIVE_GET_LENDING_COMPANIES_LOOKUP,
  RECEIVE_GET_LENDING_COMPANIES_LOOKUP_ERROR,
  REQUEST_CREATE_LENDING_COMPANY,
  RECEIVE_CREATE_LENDING_COMPANY,
  RECEIVE_CREATE_LENDING_COMPANY_ERROR,
} from '../constants/lending_companies';

const defaultState = {
  lendingCompanies: [],
  error: null,
  isFetching: false,
  isSaving: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lendingCompaniesLookup = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_LENDING_COMPANIES_LOOKUP:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDING_COMPANIES_LOOKUP:
      return {
        ...state,
        lendingCompanies: action.lendingCompanies.items,
        totalPages: action.lendingCompanies.totalPages,
        totalItems: action.lendingCompanies.totalItems,
        hasNextPage: action.lendingCompanies.hasNextPage,
        hasPreviousPage: action.lendingCompanies.hasPreviousPage,
        pageNumber: action.lendingCompanies.pageNumber,
        isFetching: false,
      };
    case REQUEST_CREATE_LENDING_COMPANY:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_LENDING_COMPANY:
      return {
        ...state,
        isSaving: false,
      };
    case RECEIVE_GET_LENDING_COMPANIES_LOOKUP_ERROR:
    case RECEIVE_CREATE_LENDING_COMPANY_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
      };
    default:
      return state;
  }
};
