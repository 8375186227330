import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { setupListeners } from "@reduxjs/toolkit/query";
import reducers from "./reducers";
import { serviceMiddleware } from "./services";

let globalStore;

function initStore(initialState) {
  const config = configureStore({
    reducer: reducers,
    devTools: {
      trace: process.env.NODE_ENV !== "production",
      traceLimit: 25,
    },
    initialState,
    enhancers: [applyMiddleware(thunkMiddleware)],
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(serviceMiddleware),
  });

  setupListeners(config.dispatch);

  return config;
}

export const initializeStore = preloadedState => {
  let initialStore = globalStore ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && globalStore) {
    initialStore = initStore({
      ...globalStore.getState(),
      ...preloadedState,
    });
    // Reset the current store
    globalStore = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === "undefined") return initialStore;
  // Create the store once in the client
  if (!globalStore) globalStore = initialStore;

  return initialStore;
};
