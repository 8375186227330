import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS, SORT_OPTIONS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const defaultLendingParametersFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[2].id,
  searchTerm: "",
};

export const lendingCompanyStateService = createApi({
  reducerPath: "lendingCompanyStateApi",
  tagTypes: ["LENDING_COMPANY_STATE"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLendingCompanyStates: builder.query({
      query: ({ lendingCompanyId, countryId, ...params }) =>
        `lending-companies/${lendingCompanyId}/countries/${countryId}/states?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ stateId }) => ({ type: "LENDING_COMPANY_STATE", stateId })),
              { type: "LENDING_COMPANY_STATE", id: "LIST" },
            ]
          : [{ type: "LENDING_COMPANY_STATE", id: "LIST" }],
    }),
    addLendingCompanyState: builder.mutation({
      query: ({ lendingCompanyId, stateId }) => ({
        url: `lending-companies/${lendingCompanyId}/states/${stateId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_STATE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_STATE_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.stateName} has been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    addLendingCompanyStates: builder.mutation({
      query: ({ lendingCompanyId, countryId }) => ({
        url: `lending-companies/${lendingCompanyId}/countries/${countryId}/states`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_STATE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_STATES_CREATED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `All states have been added to the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyState: builder.mutation({
      query: ({ lendingCompanyId, stateId }) => ({
        url: `lending-companies/${lendingCompanyId}/states/${stateId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_STATE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_STATE_DELETED, { ...data });
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.stateName} has been removed from the lending company!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLendingCompanyStates: builder.mutation({
      query: ({ lendingCompanyId, countryId }) => ({
        url: `lending-companies/${lendingCompanyId}/countries/${countryId}/states`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "LENDING_COMPANY_STATE", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LENDING_COMPANY_STATES_DELETED);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: "All states have been removed from the lending company!",
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const {
  useGetLendingCompanyStatesQuery,
  useAddLendingCompanyStateMutation,
  useAddLendingCompanyStatesMutation,
  useDeleteLendingCompanyStateMutation,
  useDeleteLendingCompanyStatesMutation,
} = lendingCompanyStateService;
