import {
  RECEIVE_GET_REFERRERS_ERROR,
  REQUEST_GET_REFERRERS,
  RECEIVE_GET_REFERRERS,
} from '../constants/referrers';

const defaultState = {
  referrers: [],
  error: null,
  isFetching: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const referrers = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_REFERRERS:
      return {
        ...state,
        referrers: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_REFERRERS:
      return {
        ...state,
        referrers: action.referrers.items,
        totalPages: action.referrers.totalPages,
        totalItems: action.referrers.totalItems,
        hasNextPage: action.referrers.hasNextPage,
        hasPreviousPage: action.referrers.hasPreviousPage,
        pageNumber: action.referrers.pageNumber,
        isFetching: false,
      };
    case RECEIVE_GET_REFERRERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
