import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

function NavigationSubItem({ path, caption, hidden = true }) {
  const { route } = useRouter();

  return (
    (<Link
      href={path}
      className={classNames(
        route.includes(path)
          ? 'bg-indigo-800 dark:text-slate-100'
          : 'dark:text-slate-300 dark:hover:text-slate-100 hover:bg-indigo-800',
        'text-indigo-100 group flex items-center py-2 text-sm pl-12 font-medium rounded-md',
        { hidden },
      )}>

      <span className="truncate">{caption}</span>

    </Link>)
  );
}

export default NavigationSubItem;

NavigationSubItem.propTypes = {
  path: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};
