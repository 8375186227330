import {
  RECEIVE_GET_LENDERS_ERROR,
  REQUEST_GET_LENDERS,
  RECEIVE_GET_LENDERS,
} from '../constants/lenders';

const defaultState = {
  lenders: [],
  error: null,
  isFetching: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const lenders = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_LENDERS:
      return {
        ...state,
        lenders: [],
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_LENDERS:
      return {
        ...state,
        lenders: action.lenders.items,
        totalPages: action.lenders.totalPages,
        totalItems: action.lenders.totalItems,
        hasNextPage: action.lenders.hasNextPage,
        hasPreviousPage: action.lenders.hasPreviousPage,
        pageNumber: action.lenders.pageNumber,
        isFetching: false,
      };
    case RECEIVE_GET_LENDERS_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    default:
      return state;
  }
};
