import {
  fetchWithHeaders,
  HTTP_METHODS,
  parseErrorResponse,
  responseData,
} from '../utils/httpRequest';

export function fetchCurrentUser() {
  return fetchWithHeaders('/account', {
    method: HTTP_METHODS.GET,
  }).then(responseData)
    .catch(parseErrorResponse);
}

export function sendEmailVerification() {
  return fetchWithHeaders('/account/email/verification', {
    method: HTTP_METHODS.POST,
  }).then(responseData)
    .catch(parseErrorResponse);
}

export function getVerificationCode() {
  return fetchWithHeaders('/account/phone/code', {
    method: HTTP_METHODS.POST,
  }).then(responseData)
    .catch(parseErrorResponse);
}

export function validateVerificationCode(opts = {}) {
  return fetchWithHeaders('/account/phone/code/verification', {
    method: HTTP_METHODS.POST,
    body: JSON.stringify(opts),
  }).then(responseData)
    .catch(parseErrorResponse);
}

export function updateCurrentUser(opts = {}) {
  return fetchWithHeaders('/account', {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(opts),
  }).then(responseData)
    .catch(parseErrorResponse);
}

export function updateDarkMode(opts = {}) {
  return fetchWithHeaders('/account', {
    method: HTTP_METHODS.PUT,
    body: JSON.stringify(opts),
  }).then(responseData)
    .catch(parseErrorResponse);
}
