export const REQUEST_SELECT_COMPANY = 'REQUEST_SELECT_COMPANY';

export const REQUEST_CURRENT_USER = 'REQUEST_CURRENT_USER';
export const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
export const RECEIVE_CURRENT_USER_ERROR = 'RECEIVE_CURRENT_USER_ERROR';

export const REQUEST_UPDATE_CURRENT_USER = 'REQUEST_UPDATE_CURRENT_USER';
export const RECEIVE_UPDATE_CURRENT_USER = 'RECEIVE_UPDATE_CURRENT_USER';
export const RECEIVE_UPDATE_CURRENT_USER_ERROR = 'RECEIVE_UPDATE_CURRENT_USER_ERROR';

export const REQUEST_UPDATE_DARK_MODE = 'REQUEST_UPDATE_DARK_MODE';
export const RECEIVE_UPDATE_DARK_MODE = 'RECEIVE_UPDATE_DARK_MODE';
export const RECEIVE_UPDATE_DARK_MODE_ERROR = 'RECEIVE_UPDATE_DARK_MODE_ERROR';

export const REQUEST_MOBILE_VERIFICATION_CODE = 'REQUEST_MOBILE_VERIFICATION_CODE';
export const RECEIVE_MOBILE_VERIFICATION_CODE = 'RECEIVE_MOBILE_VERIFICATION_CODE';
export const RECEIVE_MOBILE_VERIFICATION_CODE_ERROR = 'RECEIVE_MOBILE_VERIFICATION_CODE_ERROR';

export const REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE = 'REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE';
export const RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE = 'RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE';
export const RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR = 'RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR';

export const REQUEST_EMAIL_VERIFICATION = 'REQUEST_EMAIL_VERIFICATION';
export const RECEIVE_EMAIL_VERIFICATION = 'RECEIVE_EMAIL_VERIFICATION';
export const RECEIVE_EMAIL_VERIFICATION_ERROR = 'RECEIVE_EMAIL_VERIFICATION_ERROR';
