export const REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAM = 'REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAM';
export const RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM = 'RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM';
export const RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM_ERROR = 'RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAM_ERROR';

export const REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAMS = 'REQUEST_CREATE_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS = 'RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR = 'RECEIVE_CREATE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR';

export const REQUEST_GET_LENDING_COMPANY_LOAN_PROGRAMS = 'REQUEST_GET_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS = 'RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS_ERROR = 'RECEIVE_GET_LENDING_COMPANY_LOAN_PROGRAMS_ERROR';

export const REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAM = 'REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAM';
export const RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM = 'RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM';
export const RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM_ERROR = 'RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAM_ERROR';

export const REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAMS = 'REQUEST_DELETE_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS = 'RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS';
export const RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR = 'RECEIVE_DELETE_LENDING_COMPANY_LOAN_PROGRAMS_ERROR';

export const REQUEST_CLEAR_LENDING_COMPANY_LOAN_PROGRAMS = 'REQUEST_CLEAR_LENDING_COMPANY_LOAN_PROGRAMS';
