import {
  RECEIVE_GET_CURRENCIES_ERROR,
  RECEIVE_GET_CURRENCIES,
  REQUEST_GET_CURRENCIES,
} from '../constants/parameters';

const defaultState = {
  currencies: [],
  currency: { },
  error: null,
  isFetching: false,
  isFetchingSingle: false,
  isSaving: false,
};

export const currencies = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_GET_CURRENCIES:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        isFetching: false,
      };
    case RECEIVE_GET_CURRENCIES_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
