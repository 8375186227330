import find from "lodash/find";
import {
  RECEIVE_CURRENT_USER,
  RECEIVE_CURRENT_USER_ERROR,
  RECEIVE_EMAIL_VERIFICATION,
  RECEIVE_EMAIL_VERIFICATION_ERROR,
  RECEIVE_MOBILE_VERIFICATION_CODE,
  RECEIVE_MOBILE_VERIFICATION_CODE_ERROR,
  RECEIVE_UPDATE_CURRENT_USER,
  RECEIVE_UPDATE_CURRENT_USER_ERROR,
  RECEIVE_UPDATE_DARK_MODE,
  RECEIVE_UPDATE_DARK_MODE_ERROR,
  RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE,
  RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR,
  REQUEST_CURRENT_USER,
  REQUEST_EMAIL_VERIFICATION,
  REQUEST_MOBILE_VERIFICATION_CODE,
  REQUEST_SELECT_COMPANY,
  REQUEST_UPDATE_CURRENT_USER,
  REQUEST_UPDATE_DARK_MODE,
  REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE,
} from "../constants/current_user";
import { RECEIVE_CREATE_COMPANY, RECEIVE_UPDATE_COMPANY } from "../constants/companies";

const defaultState = {
  userConnected: null,
  currentCompany: {},
  darkMode: null,
  isFetching: false,
  isSaving: false,
  isRefreshing: false,
  isProcessingCode: false,
  isValidatingCode: false,
  isProcessingEmailVerification: false,
};

export const currentUser = (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_CURRENT_USER:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_CURRENT_USER:
      return {
        ...state,
        userConnected: action.userConnected.account,
        currentCompany: action.userConnected.company,
        darkMode: action.userConnected.account.darkMode,
        isFetching: false,
      };
    case REQUEST_UPDATE_CURRENT_USER:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_UPDATE_CURRENT_USER:
      return {
        ...state,
        userConnected: action.userConnected.account,
        darkMode: action.userConnected.account.darkMode,
        isSaving: false,
      };
    case REQUEST_UPDATE_DARK_MODE:
      return {
        ...state,
        isRefreshing: true,
      };
    case RECEIVE_UPDATE_DARK_MODE:
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          darkMode: action.darkMode,
        },
        darkMode: action.darkMode,
        isRefreshing: false,
      };
    case REQUEST_SELECT_COMPANY:
      return {
        ...state,
        selectedCompany: find(state.userConnected.companies, ["companyId", action.companyId]) || null,
      };
    case RECEIVE_CREATE_COMPANY:
      return {
        ...state,
        currentCompany: action.company,
        userConnected: { ...state.userConnected, company: action.company, employeeId: action.company.employeeId },
        isSaving: false,
      };
    case RECEIVE_UPDATE_COMPANY:
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          company: { ...action.company, employeeId: state.currentCompany.employeeId },
        },
        currentCompany: { ...action.company, employeeId: state.currentCompany.employeeId },
        isSaving: false,
      };
    case REQUEST_MOBILE_VERIFICATION_CODE:
      return {
        ...state,
        isProcessingCode: true,
      };
    case RECEIVE_MOBILE_VERIFICATION_CODE:
      return {
        ...state,
        isProcessingCode: false,
      };
    case REQUEST_VALIDATE_MOBILE_VERIFICATION_CODE:
      return {
        ...state,
        isValidatingCode: true,
      };
    case RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE:
      return {
        ...state,
        userConnected: {
          ...state.userConnected,
          isMobileNumberVerified: true,
        },
        isValidatingCode: false,
      };
    case REQUEST_EMAIL_VERIFICATION:
      return {
        ...state,
        isProcessingEmailVerification: true,
      };
    case RECEIVE_EMAIL_VERIFICATION:
      return {
        ...state,
        isProcessingEmailVerification: false,
      };
    case RECEIVE_EMAIL_VERIFICATION_ERROR:
    case RECEIVE_VALIDATE_MOBILE_VERIFICATION_CODE_ERROR:
    case RECEIVE_MOBILE_VERIFICATION_CODE_ERROR:
    case RECEIVE_UPDATE_CURRENT_USER_ERROR:
    case RECEIVE_CURRENT_USER_ERROR:
    case RECEIVE_UPDATE_DARK_MODE_ERROR:
      return {
        ...state,
        isFetching: false,
        isSaving: false,
        isRefreshing: false,
        isValidatingCode: false,
        isProcessingCode: false,
        isProcessingEmailVerification: false,
      };
    default:
      return state;
  }
};
