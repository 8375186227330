import React from "react";
import { Menu } from "@headlessui/react";
import { useSelector } from "react-redux";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { UserDropDownMenuItems } from "./user_drop_down_menu_items";
import { ProfilePicture } from "../profile_picture/profile_picture";
import { currentUserRoleDescriptionSelector } from "../../redux/selectors/currentUser";

export function UserDropDownMenuMobile() {
  const { userConnected, isFetching } = useSelector(state => state.currentUser);
  const userRole = useSelector(currentUserRoleDescriptionSelector);

  if (!userConnected || isFetching) return null;

  return (
    <Menu as="div" className="flex flex-shrink justify-end relative">
      <Menu.Button
        className={classNames(
          "flex items-center bg-inherit rounded-full text-sm focus:outline-none focus:ring-2",
          "focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-slate-900 lg:p-2 lg:rounded-md lg:hover:bg-gray-50 dark:lg:hover:bg-slate-700",
        )}
      >
        <span className="sr-only">Open user menu</span>
        <ProfilePicture pictureUrl={userConnected.pictureUrl} />
        <div className="flex-col justify-center items-center hidden ml-3 text-gray-700 dark:text-slate-100 text-sm font-medium lg:block">
          <span className="sr-only">Open user menu for </span>
          <span className="flex">{userConnected.firstName}</span>
          <span className="inline-flex items-center rounded-full bg-indigo-500 px-1.5 py-0.5 text-xs font-medium text-white">
            {userRole}
          </span>
        </div>
        <ChevronDownIcon
          className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 dark:text-slate-100 lg:block"
          aria-hidden="true"
        />
      </Menu.Button>
      <UserDropDownMenuItems />
    </Menu>
  );
}
