export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
export const RECEIVE_CREATE_USER = 'RECEIVE_CREATE_USER';
export const RECEIVE_CREATE_USER_ERROR = 'RECEIVE_CREATE_USER_ERROR';

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const RECEIVE_UPDATE_USER = 'RECEIVE_UPDATE_USER';
export const RECEIVE_UPDATE_USER_ERROR = 'RECEIVE_UPDATE_USER_ERROR';

export const REQUEST_GET_USER = 'REQUEST_GET_USER';
export const RECEIVE_GET_USER = 'RECEIVE_GET_USER';
export const RECEIVE_GET_USER_ERROR = 'RECEIVE_GET_USER_ERROR';

export const REQUEST_GET_USERS = 'REQUEST_GET_USERS';
export const RECEIVE_GET_USERS = 'RECEIVE_GET_USERS';
export const RECEIVE_GET_USERS_ERROR = 'RECEIVE_GET_USERS_ERROR';

export const REQUEST_CLEAR_USER = 'REQUEST_CLEAR_USER';
