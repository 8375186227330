import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryParams } from "../../utils/httpRequest";
import { baseUrl, onPrepareHeaders } from "./helpers";
import { showNotification } from "../actions/notification";
import { DEFAULT_NOTIFICATION_PAYLOAD, NOTIFICATION_VARIANTS } from "../../utils/applicationConstants";
import { ANALYTIC_EVENTS, sendAnalytics } from "../../utils/analytics";

export const loanEmployeeService = createApi({
  reducerPath: "loanEmployeeApi",
  tagTypes: ["LoanEmployee"],
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: onPrepareHeaders,
  }),
  endpoints: builder => ({
    getLoanEmployees: builder.query({
      query: ({ loanId: id, ...params }) => `loans/${id}/employees?${getQueryParams(params)}`,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ employeeId }) => ({ type: "LoanEmployee", employeeId })),
              { type: "LoanEmployee", id: "LIST" },
            ]
          : [{ type: "LoanEmployee", id: "LIST" }],
    }),
    addLoanEmployee: builder.mutation({
      query: ({ loanId, employeeId }) => ({
        url: `loans/${loanId}/employees/${employeeId}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "LoanEmployee", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_EMPLOYEE_CREATED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.fullName} has been added to the loan!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
    deleteLoanEmployee: builder.mutation({
      query({ loanId, employeeId }) {
        return {
          url: `loans/${loanId}/employees/${employeeId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "LoanEmployee", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          sendAnalytics(ANALYTIC_EVENTS.LOAN_EMPLOYEE_DELETED, data);
          dispatch(
            showNotification({
              ...DEFAULT_NOTIFICATION_PAYLOAD,
              message: {
                ...DEFAULT_NOTIFICATION_PAYLOAD.message,
                description: `${data.fullName} has been removed from the loan!`,
                options: {
                  variant: NOTIFICATION_VARIANTS.SUCCESS,
                },
              },
            }),
          );
        } catch (err) {
          /* empty */
        }
      },
    }),
  }),
});

export const { useGetLoanEmployeesQuery, useAddLoanEmployeeMutation, useDeleteLoanEmployeeMutation } =
  loanEmployeeService;
