import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import { SORT_OPTIONS } from '../../utils/applicationConstants';
import {
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR,
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR,
  RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR,
  REQUEST_CLEAR_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT,
  REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS,
  REQUEST_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS,
} from '../constants/needs_list_template_documents';

export const defaultNeedsListTemplateDocumentsFilters = {
  page: 1,
  size: 10,
  sortBy: SORT_OPTIONS[1].id,
  searchTerm: '',
};

const defaultState = {
  needsListTemplateDocuments: [],
  error: null,
  isFetching: false,
  isSaving: false,
  isDeleting: false,
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  totalActiveItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

export const needsListTemplateDocuments = (state = defaultState, action) => {
  let index;
  let newNeedsListTemplateDocuments;
  switch (action.type) {
    case REQUEST_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        isFetching: true,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        totalActiveItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
      };
    case RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        needsListTemplateDocuments: action.needsListTemplateDocuments.items,
        totalPages: action.needsListTemplateDocuments.totalPages,
        totalItems: action.needsListTemplateDocuments.totalItems,
        totalActiveItems: action.needsListTemplateDocuments.totalActiveItems,
        hasNextPage: action.needsListTemplateDocuments.hasNextPage,
        hasPreviousPage: action.needsListTemplateDocuments.hasPreviousPage,
        pageNumber: action.needsListTemplateDocuments.pageNumber,
        isFetching: false,
      };
    case REQUEST_CLEAR_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        totalPages: 0,
        totalItems: 0,
        pageNumber: 0,
        totalActiveItems: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        needsListTemplateDocuments: [],
      };
    case REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      index = findIndex(state.needsListTemplateDocuments, ['documentId', action.needsListTemplateDocument.documentId]);
      if (index >= 0) {
        newNeedsListTemplateDocuments = fill([...state.needsListTemplateDocuments], action.needsListTemplateDocument, index, index + 1);
      }
      return {
        ...state,
        needsListTemplateDocuments: newNeedsListTemplateDocuments || state.needsListTemplateDocuments,
        totalActiveItems: state.totalActiveItems + 1,
        isSaving: false,
      };
    case REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT:
      index = findIndex(state.needsListTemplateDocuments, ['documentId', action.needsListTemplateDocument.documentId]);
      if (index >= 0) {
        newNeedsListTemplateDocuments = fill([...state.needsListTemplateDocuments], { ...state.needsListTemplateDocuments[index], isActive: false }, index, index + 1);
      }
      return {
        ...state,
        needsListTemplateDocuments: newNeedsListTemplateDocuments || state.needsListTemplateDocuments,
        totalActiveItems: state.totalActiveItems - 1,
        isSaving: false,
      };
    case REQUEST_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        needsListTemplateDocuments: state.needsListTemplateDocuments.map((item) => ({
          ...item,
          isActive: true,
        })),
        totalActiveItems: state.totalItems,
        isSaving: false,
      };
    case REQUEST_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS:
      return {
        ...state,
        needsListTemplateDocuments: state.needsListTemplateDocuments.map((item) => ({
          ...item,
          isActive: false,
        })),
        totalActiveItems: 0,
        isSaving: false,
      };
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR:
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR:
    case RECEIVE_CREATE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR:
    case RECEIVE_DELETE_NEEDS_LIST_TEMPLATE_DOCUMENT_ERROR:
    case RECEIVE_GET_NEEDS_LIST_TEMPLATE_DOCUMENTS_ERROR:
      return {
        ...state,
        error: action.error,
        isDeleting: false,
        isFetching: false,
        isSaving: false,
        isFetchingSingle: false,
      };
    default:
      return state;
  }
};
