import { HEADERS } from "./applicationConstants";

const portParams = ["none"];

export const CONTENT_TYPES = {
  JSON: "application/json",
};

export const HTTP_METHODS = {
  GET: "GET",
  PUT: "PUT",
  POST: "POST",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const HTTP_STATUS_CODES = {
  NOT_AUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  EXCEPTION: 500,
};

export const getQueryParams = params => {
  let actualKey;
  return Object.keys(params)
    .filter(key => params[key])
    .map(key => {
      if (portParams[key]) {
        actualKey = portParams[key];
      } else {
        actualKey = key;
      }

      if (typeof params[key] === "string") {
        return `${actualKey}=${encodeURIComponent(params[key].trim())}`;
      }
      if (typeof params[key] === "boolean") {
        return `${actualKey}=${encodeURIComponent(params[key] ? "true" : "false")}`;
      }
      return `${actualKey}=${encodeURIComponent(params[key])}`;
    })
    .join("&");
};

export const getQueryParamsUrl = params => {
  let actualKey;
  return (
    Object.keys(params)
      // .filter(key => params[key])
      .map(key => {
        if (portParams[key]) {
          actualKey = portParams[key];
        } else {
          actualKey = key;
        }

        if (typeof params[key] === "string") {
          return `${actualKey}=${encodeURIComponent(params[key].trim())}`;
        }
        if (typeof params[key] === "boolean") {
          return `${actualKey}=${encodeURIComponent(params[key] ? 1 : 0)}`;
        }
        return `${actualKey}=${encodeURIComponent(params[key])}`;
      })
      .join("&")
  );
};

export function parseErrorResponse(response, content) {
  if (response.status === HTTP_STATUS_CODES.NOT_AUTHORIZED) window.location = "/api/auth/login";
  const userDescription = content?.userDescription || "Unable to process your request. Please try again later";

  return {
    status: response.status,
    statusText: response.statusText,
    statusCode: response.statusCode,
    ok: response.ok,
    userDescription,
  };
}

export const parseResponse = async response => {
  if (response.status === HTTP_STATUS_CODES.NOT_AUTHORIZED) window.location = "/api/auth/login";
  try {
    const contentType = response.headers.get("Content-Type");
    const contentJson = contentType && contentType.includes("application/json");

    const content = await (contentJson ? response.json() : response);
    return {
      data: content,
      fileName: !contentJson ? response.headers.get("content-disposition").split("filename=")[1].split(";")[0] : null,
      status: response.status,
      statusText: response.statusText,
      statusCode: response.statusCode,
      ok: response.ok,
    };
  } catch (ex) {
    throw parseErrorResponse(response, ex);
  }
};

export const responseData = response => parseResponse(response).then(res => res);

export async function fetchWithHeaders(route, requestOptions) {
  const headers = new Headers();

  if (!requestOptions.includeFile) {
    headers.append("Content-Type", "application/json");
  }

  headers.append(HEADERS.TENANT_ID, requestOptions.companyId);
  headers.append(HEADERS.APP_ID, process.env.NEXT_PUBLIC_APP_ID);
  headers.append(HEADERS.VERSION_ID, process.env.NEXT_PUBLIC_APP_VERSION);
  if (process.env.NODE_ENV === "development" && process.env.NEXT_PUBLIC_CLIENT_IP) {
    headers.append("x-forwarded-for", process.env.NEXT_PUBLIC_CLIENT_IP);
  }
  // eslint-disable-next-line no-param-reassign
  requestOptions.headers = headers;
  const fullUrl = `/api${route}`;
  return fetch(fullUrl, requestOptions);
}
