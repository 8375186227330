export const REQUEST_GET_LENDER_CALL_LOG_NOTES = 'REQUEST_GET_LENDER_CALL_LOG_NOTES';
export const RECEIVE_GET_LENDER_CALL_LOG_NOTES = 'RECEIVE_GET_LENDER_CALL_LOG_NOTES';
export const RECEIVE_GET_LENDER_CALL_LOG_NOTES_ERROR = 'RECEIVE_GET_LENDER_CALL_LOG_NOTES_ERROR';

export const REQUEST_UPDATE_LENDER_CALL_LOG_NOTE = 'REQUEST_UPDATE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE = 'RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE_ERROR = 'RECEIVE_UPDATE_LENDER_CALL_LOG_NOTE_ERROR';

export const REQUEST_DELETE_LENDER_CALL_LOG_NOTE = 'REQUEST_DELETE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_DELETE_LENDER_CALL_LOG_NOTE = 'RECEIVE_DELETE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_DELETE_LENDER_CALL_LOG_NOTE_ERROR = 'RECEIVE_DELETE_LENDER_CALL_LOG_NOTE_ERROR';

export const REQUEST_CREATE_LENDER_CALL_LOG_NOTE = 'REQUEST_CREATE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_CREATE_LENDER_CALL_LOG_NOTE = 'RECEIVE_CREATE_LENDER_CALL_LOG_NOTE';
export const RECEIVE_CREATE_LENDER_CALL_LOG_NOTE_ERROR = 'RECEIVE_CREATE_LENDER_CALL_LOG_NOTE_ERROR';

export const REQUEST_CLEAR_LENDER_CALL_LOG_NOTES = 'REQUEST_CLEAR_LENDER_CALL_LOG_NOTES';
