import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import fill from 'lodash/fill';
import filter from 'lodash/filter';
import {
  REQUEST_GET_NEED_LISTS,
  RECEIVE_GET_NEED_LISTS_ERROR,
  RECEIVE_GET_NEED_LISTS,
  REQUEST_CREATE_NEED_LIST,
  RECEIVE_CREATE_NEED_LIST,
  RECEIVE_CREATE_NEED_LIST_ERROR,
  RECEIVE_GET_NEED_LIST_ERROR,
  REQUEST_GET_NEED_LIST,
  RECEIVE_GET_NEED_LIST,
  REQUEST_CLEAR_NEED_LIST,
  REQUEST_CLEAR_NEED_LISTS,
  REQUEST_UPDATE_NEED_LIST_STATUS,
  RECEIVE_UPDATE_NEED_LIST_STATUS,
  RECEIVE_UPDATE_NEED_LIST_STATUS_ERROR,
  REQUEST_DELETE_NEED_LIST,
  RECEIVE_DELETE_NEED_LIST,
  RECEIVE_DELETE_NEED_LIST_ERROR,
  REQUEST_CREATE_NEED_LIST_FILE,
  RECEIVE_CREATE_NEED_LIST_FILE,
  RECEIVE_GET_NEED_LIST_FILES_ERROR,
  REQUEST_GET_NEED_LIST_FILES,
  RECEIVE_GET_NEED_LIST_FILES,
  REQUEST_DELETE_NEED_LIST_FILE,
  RECEIVE_DELETE_NEED_LIST_FILE,
  RECEIVE_DELETE_NEED_LIST_FILE_ERROR,
  REQUEST_APPROVE_NEED_LIST_FILE,
  RECEIVE_APPROVE_NEED_LIST_FILE,
  RECEIVE_APPROVE_NEED_LIST_FILE_ERROR,
  RECEIVE_REJECT_NEED_LIST_FILE_ERROR,
  REQUEST_REJECT_NEED_LIST_FILE,
  RECEIVE_REJECT_NEED_LIST_FILE,
  RECEIVE_CREATE_NEED_LIST_FILE_ERROR,
  REQUEST_NOTIFY_OUTSTANDING_NEED_LIST,
  RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST,
  RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST_ERROR,
  REQUEST_AUTH_NEED_LIST,
  RECEIVE_AUTH_NEED_LIST,
  RECEIVE_AUTH_NEED_LIST_ERROR,
  REQUEST_CREATE_MY_NEED_LIST_FILE,
  RECEIVE_CREATE_MY_NEED_LIST_FILE,
  RECEIVE_CREATE_MY_NEED_LIST_FILE_ERROR,
  REQUEST_GET_MY_NEED_LIST_FILES,
  RECEIVE_GET_MY_NEED_LIST_FILES,
  RECEIVE_GET_MY_NEED_LIST_FILES_ERROR,
  REQUEST_DELETE_MY_NEED_LIST_FILE,
  RECEIVE_DELETE_MY_NEED_LIST_FILE,
  RECEIVE_DELETE_MY_NEED_LIST_FILE_ERROR,
  REQUEST_GENERATE_NEED_LIST_PACKAGE,
  RECEIVE_GENERATE_NEED_LIST_PACKAGE,
  RECEIVE_GENERATE_NEED_LIST_PACKAGE_ERROR,
} from '../constants/need_lists';
import { RECEIVE_UPDATE_USER } from '../constants/users';

export const defaultNeedListFilesPagination = {
  page: 1,
  size: 5,
};

const defaultState = {
  loan: {},
  needListFiles: [],
  needLists: [],
  borrowers: [],
  needList: {},
  error: null,
  isNotifying: false,
  isFetching: false,
  isFetchingFiles: false,
  isFetchingSingle: false,
  isGeneratingPackage: false,
  isSaving: false,
  isDeletingDocument: false,
  isDeletingFile: false,
  isApprovingFile: false,
  isRejectingFile: false,
  authLinkSent: false,
  needListIdProcessing: null,
  isUpdatingStatus: false,
  magicLinkResponse: {},
  pageNumber: 0,
  totalPages: 0,
  totalItems: 0,
  hasNextPage: false,
  hasPreviousPage: false,
};

function setNotificationDate(state, notifiedNeedsList) {
  return state.needLists.map((needList) => {
    const index = findIndex(notifiedNeedsList, ['needListId', needList.needListId]);
    if (index >= 0) {
      return {
        ...needList,
        lastNotificationSentAt: new Date(),
      };
    }
    return needList;
  });
}

export const needLists = (state = defaultState, action) => {
  let index;
  let indexApprovedNeedList;
  let newNeedLists;
  let newNeedListFiles;
  let newTotalItems;
  let newBorrowers;
  switch (action.type) {
    case RECEIVE_UPDATE_USER:
      index = findIndex(state.borrowers, ['borrowerId', action.user.companyUserId]);
      if (index >= 0) {
        newBorrowers = fill(
          [...state.borrowers],
          {
            ...state.borrowers[index],
            borrowerFullName: `${action.user.firstName} ${action.user.lastName}`,
            email: action.user.email,
            allowEmailNotification: action.user.allowEmailNotification,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        borrowers: newBorrowers || state.borrowers,
      };
    case REQUEST_CREATE_NEED_LIST:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_NEED_LIST:
      return {
        ...state,
        needLists: concat(state.needLists, action.needList),
        isSaving: false,
      };
    case REQUEST_NOTIFY_OUTSTANDING_NEED_LIST:
      return {
        ...state,
        isNotifying: true,
      };
    case RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST:
      return {
        ...state,
        needLists: setNotificationDate(state, action.needLists),
        isNotifying: false,
      };
    case REQUEST_CREATE_NEED_LIST_FILE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_NEED_LIST_FILE:
      index = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      if (index >= 0) {
        newNeedLists = fill(
          [...state.needLists],
          {
            ...state.needLists[index],
            filesQuantity: state.needLists[index].filesQuantity + action.needListFile.filesProcessed,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        needLists: index >= 0 ? newNeedLists : state.needLists,
        isSaving: false,
      };
    case REQUEST_CREATE_MY_NEED_LIST_FILE:
      return {
        ...state,
        isSaving: true,
      };
    case RECEIVE_CREATE_MY_NEED_LIST_FILE:
      index = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      if (index >= 0) {
        newNeedLists = fill(
          [...state.needLists],
          {
            ...state.needLists[index],
            filesQuantity: state.needLists[index].filesQuantity + action.needListFile.filesProcessed,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        needLists: index >= 0 ? newNeedLists : state.needLists,
        isSaving: false,
      };
    case REQUEST_UPDATE_NEED_LIST_STATUS:
      return {
        ...state,
        isUpdatingStatus: true,
        needListIdProcessing: action.needListId,
      };
    case RECEIVE_UPDATE_NEED_LIST_STATUS:
      return {
        ...state,
        isUpdatingStatus: false,
        needListIdProcessing: null,
      };
    case REQUEST_GET_NEED_LISTS:
      return {
        ...state,
        loan: {},
        needLists: [],
        borrowers: [],
        isFetching: true,
      };
    case RECEIVE_GET_NEED_LISTS:
      return {
        ...state,
        loan: action.needLists.loan,
        needLists: action.needLists.needListsDetail,
        borrowers: action.needLists.borrowers,
        isFetching: false,
      };
    case REQUEST_GET_NEED_LIST_FILES:
      return {
        ...state,
        needListFiles: [],
        isFetchingFiles: true,
      };
    case RECEIVE_GET_NEED_LIST_FILES:
      return {
        ...state,
        needListFiles: action.needListFiles.items,
        totalPages: action.needListFiles.totalPages,
        totalItems: action.needListFiles.totalItems,
        hasNextPage: action.needListFiles.hasNextPage,
        hasPreviousPage: action.needListFiles.hasPreviousPage,
        pageNumber: action.needListFiles.pageNumber,
        isFetchingFiles: false,
      };
    case REQUEST_GET_MY_NEED_LIST_FILES:
      return {
        ...state,
        needListFiles: [],
        isFetchingFiles: true,
      };
    case RECEIVE_GET_MY_NEED_LIST_FILES:
      return {
        ...state,
        needListFiles: action.needListFiles.items,
        totalPages: action.needListFiles.totalPages,
        totalItems: action.needListFiles.totalItems,
        hasNextPage: action.needListFiles.hasNextPage,
        hasPreviousPage: action.needListFiles.hasPreviousPage,
        pageNumber: action.needListFiles.pageNumber,
        isFetchingFiles: false,
      };
    case REQUEST_GET_NEED_LIST:
      return {
        ...state,
        needList: {},
        isFetchingSingle: true,
      };
    case RECEIVE_GET_NEED_LIST:
      return {
        ...state,
        needList: action.needList,
        isFetchingSingle: false,
      };
    case REQUEST_DELETE_NEED_LIST:
      return {
        ...state,
        isDeletingDocument: true,
      };
    case RECEIVE_DELETE_NEED_LIST:
      return {
        ...state,
        needLists: filter(state.needLists, (d) => d.needListId !== action.id),
        isDeletingDocument: false,
      };
    case REQUEST_DELETE_NEED_LIST_FILE:
      return {
        ...state,
        isDeletingFile: true,
      };
    case RECEIVE_DELETE_NEED_LIST_FILE:
      newTotalItems = state.totalItems - 1;
      index = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      if (index >= 0) {
        newNeedLists = fill(
          [...state.needLists],
          {
            ...state.needLists[index],
            filesQuantity: state.needLists[index].filesQuantity - 1,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        needListFiles:
          filter(
            state.needListFiles,
            (d) => d.needListFileId !== action.needListFile.needListFileId,
          ),
        needLists: index >= 0 ? newNeedLists : state.needLists,
        totalItems: newTotalItems,
        totalPages: Math.ceil(newTotalItems / defaultNeedListFilesPagination.size),
        isDeletingFile: false,
      };
    case REQUEST_DELETE_MY_NEED_LIST_FILE:
      return {
        ...state,
        isDeletingFile: true,
      };
    case RECEIVE_DELETE_MY_NEED_LIST_FILE:
      newTotalItems = state.totalItems - 1;
      index = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      if (index >= 0) {
        newNeedLists = fill(
          [...state.needLists],
          {
            ...state.needLists[index],
            filesQuantity: state.needLists[index].filesQuantity - 1,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        needListFiles:
          filter(
            state.needListFiles,
            (d) => d.needListFileId !== action.needListFile.needListFileId,
          ),
        needLists: index >= 0 ? newNeedLists : state.needLists,
        totalItems: newTotalItems,
        totalPages: Math.ceil(newTotalItems / defaultNeedListFilesPagination.size),
        isDeletingFile: false,
      };
    case REQUEST_APPROVE_NEED_LIST_FILE:
      return {
        ...state,
        isApprovingFile: true,
      };
    case RECEIVE_APPROVE_NEED_LIST_FILE:
      indexApprovedNeedList = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      index = findIndex(state.needListFiles, ['needListFileId', action.needListFile.needListFileId]);
      if (index >= 0) {
        newNeedListFiles = fill([...state.needListFiles], action.needListFile, index, index + 1);
      }
      newNeedLists = fill(
        [...state.needLists],
        {
          ...state.needLists[indexApprovedNeedList],
          filesApproved: state.needLists[indexApprovedNeedList].filesApproved + 1,
        },
        indexApprovedNeedList,
        indexApprovedNeedList + 1,
      );
      return {
        ...state,
        needLists: newNeedLists || state.needLists,
        needListFiles: newNeedListFiles || state.needListFiles,
        isApprovingFile: false,
      };
    case REQUEST_REJECT_NEED_LIST_FILE:
      return {
        ...state,
        isRejectingFile: true,
      };
    case RECEIVE_REJECT_NEED_LIST_FILE:
      newTotalItems = state.totalItems - 1;
      index = findIndex(state.needLists, ['needListId', action.needListFile.needListId]);
      if (index >= 0) {
        newNeedLists = fill(
          [...state.needLists],
          {
            ...state.needLists[index],
            filesQuantity: state.needLists[index].filesQuantity - 1,
          },
          index,
          index + 1,
        );
      }
      return {
        ...state,
        needListFiles:
          filter(
            state.needListFiles,
            (d) => d.needListFileId !== action.needListFile.needListFileId,
          ),
        needLists: index >= 0 ? newNeedLists : state.needLists,
        totalItems: newTotalItems,
        totalPages: Math.ceil(newTotalItems / defaultNeedListFilesPagination.size),
        isRejectingFile: false,
      };
    case REQUEST_CLEAR_NEED_LIST:
      return {
        ...state,
        needList: {},
      };
    case REQUEST_CLEAR_NEED_LISTS:
      return {
        ...state,
        needLists: [],
      };
    case REQUEST_AUTH_NEED_LIST:
      return {
        ...state,
        isNotifying: true,
        authLinkSent: false,
        magicLinkResponse: {},
      };
    case RECEIVE_AUTH_NEED_LIST:
      return {
        ...state,
        isNotifying: false,
        authLinkSent: true,
        magicLinkResponse: action.magicLinkPayload,
      };
    case REQUEST_GENERATE_NEED_LIST_PACKAGE:
      return {
        ...state,
        isGeneratingPackage: true,
      };
    case RECEIVE_GENERATE_NEED_LIST_PACKAGE:
      return {
        ...state,
        isGeneratingPackage: false,
      };
    case RECEIVE_GENERATE_NEED_LIST_PACKAGE_ERROR:
    case RECEIVE_UPDATE_NEED_LIST_STATUS_ERROR:
    case RECEIVE_GET_NEED_LISTS_ERROR:
    case RECEIVE_CREATE_NEED_LIST_ERROR:
    case RECEIVE_GET_NEED_LIST_ERROR:
    case RECEIVE_DELETE_NEED_LIST_ERROR:
    case RECEIVE_CREATE_NEED_LIST_FILE_ERROR:
    case RECEIVE_DELETE_NEED_LIST_FILE_ERROR:
    case RECEIVE_APPROVE_NEED_LIST_FILE_ERROR:
    case RECEIVE_REJECT_NEED_LIST_FILE_ERROR:
    case RECEIVE_GET_NEED_LIST_FILES_ERROR:
    case RECEIVE_NOTIFY_OUTSTANDING_NEED_LIST_ERROR:
    case RECEIVE_AUTH_NEED_LIST_ERROR:
    case RECEIVE_CREATE_MY_NEED_LIST_FILE_ERROR:
    case RECEIVE_GET_MY_NEED_LIST_FILES_ERROR:
    case RECEIVE_DELETE_MY_NEED_LIST_FILE_ERROR:
      return {
        ...state,
        error: action.error,
        isFetching: false,
        isFetchingFiles: false,
        isSaving: false,
        isFetchingSingle: false,
        isDeletingDocument: false,
        isDeletingFile: false,
        isApprovingFile: false,
        isRejectingFile: false,
        isNotifying: false,
        authLinkSent: false,
        isUpdatingStatus: false,
        needListIdProcessing: null,
        isGeneratingPackage: false,
      };
    default:
      return state;
  }
};
