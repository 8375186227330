export const REQUEST_GET_LOAN_STATUSES = 'REQUEST_GET_LOAN_STATUSES';
export const RECEIVE_GET_LOAN_STATUSES = 'RECEIVE_GET_LOAN_STATUSES';
export const RECEIVE_GET_LOAN_STATUSES_ERROR = 'RECEIVE_GET_LOAN_STATUSES_ERROR';

export const REQUEST_GET_LOAN_STATUS = 'REQUEST_GET_LOAN_STATUS';
export const RECEIVE_GET_LOAN_STATUS = 'RECEIVE_GET_LOAN_STATUS';
export const RECEIVE_GET_LOAN_STATUS_ERROR = 'RECEIVE_GET_LOAN_STATUS_ERROR';

export const REQUEST_UPDATE_LOAN_STATUS = 'REQUEST_UPDATE_LOAN_STATUS';
export const RECEIVE_UPDATE_LOAN_STATUS = 'RECEIVE_UPDATE_LOAN_STATUS';
export const RECEIVE_UPDATE_LOAN_STATUS_ERROR = 'RECEIVE_UPDATE_LOAN_STATUS_ERROR';

export const REQUEST_DELETE_LOAN_STATUS = 'REQUEST_DELETE_LOAN_STATUS';
export const RECEIVE_DELETE_LOAN_STATUS = 'RECEIVE_DELETE_LOAN_STATUS';
export const RECEIVE_DELETE_LOAN_STATUS_ERROR = 'RECEIVE_DELETE_LOAN_STATUS_ERROR';

export const REQUEST_CREATE_LOAN_STATUS = 'REQUEST_CREATE_LOAN_STATUS';
export const RECEIVE_CREATE_LOAN_STATUS = 'RECEIVE_CREATE_LOAN_STATUS';
export const RECEIVE_CREATE_LOAN_STATUS_ERROR = 'RECEIVE_CREATE_LOAN_STATUS_ERROR';

export const REQUEST_CLEAR_LOAN_STATUS = 'REQUEST_CLEAR_LOAN_STATUS';
